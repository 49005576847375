import { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface ModalModel {
	modal?: {
		visible?: boolean;
		component?: string;
		title?: string;
		canBeClosed?: boolean;
		doOverflow?: boolean;
	};
	setModal: Action<
		ModalModel,
		{
			visible?: boolean;
			component?: string;
			title?: string;
			canBeClosed?: boolean;
			doOverflow?: boolean;
		}
	>;
}

const modalModel: ModalModel = {
	modal: {
		visible: false,
		canBeClosed: true,
		doOverflow: true,
		component: '',
		title: '',
	},
	setModal: action((state, payload) => {
		state.modal.visible = payload.visible;
		state.modal.component = payload.component;
		state.modal.title = payload.title;
		state.modal.canBeClosed = payload.canBeClosed;
		state.modal.doOverflow = payload.doOverflow;
	}),
};

export default modalModel;
