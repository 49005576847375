import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
import { useSetHeader } from '../../hooks/useSetHeader';
import MainWrapper from '../../components/MainWrapper/MainWrapper';
import Flex from '../../components/Flex/Flex';
import { t } from '../../translations/en';
import Input from '../../components/Input/Input';
import BasicLink from '../../components/BasicLink/BasicLink';
import { routes } from '../../routes/routes';
import CustomButton from '../../components/Button/Button';
import Footer from '../../components/Footer/Footer';
import FormContainer from '../../components/FormContainer/FormContainer';
import PasswordRemindTitle from '../../components/PasswordRemindTitle/PasswordRemindTitle';
import PasswordRemindSubtitle from '../../components/PasswordRemindSubtitle/PasswordRemindSubtitle';
import { FunderService } from '../../services/FunderService';
import { useHistory } from 'react-router';
import WithPagination from '../../components/WithPagination/WithPagination';

const CenteredWrapper = styled.div`
	text-align: center;
`;

const StyledInput = styled(Input)`
	width: 100%;
`;

const PasswordRemind = () => {
	useSetHeader({ hasHeader: false });
	const [emailField, setEmailField] = useState<string>('');
	const [isFormValid, setFormValidity] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

	const validateForm = () => setFormValidity(emailPattern.test(emailField));

	const history = useHistory();
	const { addToast } = useToasts();

	const submitForm = (e: React.FormEvent<Element>) => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);
			FunderService.postPasswordRemind(emailField)
				.then(response => {
					const data = JSON.parse(response);
					addToast(t.passwordRemindPage.alertSuccessRemindPassword, {
						appearance: 'success',
						autoDismiss: true,
					});
					setLoading(false);
					history.push(`${routes.signIn}`);
				})
				.catch(error => {
					setLoading(false);
					if (error.response.status === 400) {
						addToast(error.response.data.email[0], {
							appearance: 'error',
							autoDismiss: true,
						});
					} else if (error.response.status === 404) {
						addToast(
							`${emailField} ${t.passwordRemindPage.alertEmailNotFound}`,
							{
								appearance: 'error',
								autoDismiss: true,
							}
						);
					} else {
						addToast(t.passwordRemindPage.alertErrorRemindPassword, {
							appearance: 'error',
							autoDismiss: true,
						});
					}

					console.log(error);
				});
		}
	};

	const updateEmailField = (e: React.FormEvent<Element>) => {
		const target = e.target as HTMLTextAreaElement;
		const { value } = target;
		setEmailField(value);
	};

	useEffect(() => {
		validateForm();
	}, [emailField]);

	return (
		<WithPagination isLoading={isLoading}>
			<MainWrapper>
				<MainContainer>
					<CenteredWrapper>
						<div>
							<PasswordRemindTitle>
								{t.passwordRemindPage.title}
							</PasswordRemindTitle>
							<PasswordRemindSubtitle>
								{t.passwordRemindPage.subtitle.step1}
							</PasswordRemindSubtitle>
						</div>
						<Flex>
							<FormContainer onSubmit={submitForm}>
								<StyledInput
									placeholder={t.email}
									type='email'
									onChange={e => updateEmailField(e)}
								/>
								<Flex justify='flex-end' marginTop='2.2rem'>
									<BasicLink colorTheme='light' to={routes.signIn}>
										{t.passwordRemindPage.back}
									</BasicLink>
								</Flex>
								<Flex marginTop='1.2rem'>
									<CustomButton
										content={t.send}
										type='submit'
										size='medium'
										disabled={!isFormValid}
									/>
								</Flex>
								<Flex marginTop='2.2rem'>
									<BasicLink colorTheme='light' to={routes.contact}>
										{t.notRegistered}
									</BasicLink>
								</Flex>
							</FormContainer>
						</Flex>
					</CenteredWrapper>
				</MainContainer>
			</MainWrapper>
			<Footer type='default' />
		</WithPagination>
	);
};

export default PasswordRemind;
