import React from 'react';
import styled from 'styled-components';
import { t } from '../../translations/en';
import MainContainer from '../../components/MainContainer/MainContainer';
import Text from '../../components/Text/Text';
import CompanyAddress from '../../components/CompanyAddress/CompanyAddress';
import VerticalGrid from '../../components/VerticalGrid/VerticalGrid';
import Footer from '../../components/Footer/Footer';
import { useSetHeader } from '../../hooks/useSetHeader';
import Title from '../../components/Title/Title';

const GridLayout = styled.div`
	display: grid;
	grid-row-gap: 3rem;
	@media screen and (min-width: 1024px) {
		grid-template-columns: 1fr 445px;
		grid-column-gap: 10rem;
	}
`;

const LegalInformation: React.FC = () => {
	useSetHeader({
		title: t.legalInformationPage.title,
		subtitle: t.legalInformationPage.privacyNotice,
		isStaticPage: true,
	});
	return (
		<>
			<MainContainer marginTop='4.4rem'>
				<GridLayout>
					<VerticalGrid gap='3rem'>
						<VerticalGrid gap='1rem'>
							<Title size='big'>{t.legalInformationPage.heading1}</Title>
							<Text>{t.legalInformationPage.text1}</Text>
						</VerticalGrid>
						<VerticalGrid gap='1rem'>
							<Title size='big'>{t.legalInformationPage.heading2}</Title>
							<Text>{t.legalInformationPage.text2}</Text>
						</VerticalGrid>
						<VerticalGrid gap='1rem'>
							<Title size='big'>{t.legalInformationPage.heading3}</Title>
							<Text>{t.legalInformationPage.text3}</Text>
						</VerticalGrid>
					</VerticalGrid>
					<div>
						<CompanyAddress list={t.legalInformationPage.list} />
					</div>
				</GridLayout>
			</MainContainer>
			<Footer type='logoOnly' />
		</>
	);
};
export default LegalInformation;
