import React, { useLayoutEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { t } from '../../translations/en';
import Chart from 'chart.js';
import Theme from '../../themes/MainTheme';
import MainContainer from '../../components/MainContainer/MainContainer';
import Footer from '../../components/Footer/Footer';
import { FunderService } from '../../services/FunderService';
import { routes } from '../../routes/routes';
import { useSetHeader } from '../../hooks/useSetHeader';
import WithPagination from '../../components/WithPagination/WithPagination';
import { useStoreActions } from '../../easy-peasy/hooks/hooks';

const Wrapper = styled.section`
	position: relative;
`;

const Title = styled.h3`
	position: absolute;
	font-weight: ${({ theme }) => theme.font.weight.bold};
	left: ${({ left }) => (left ? left : 'inherit')};
	top: ${({ top }) => (top ? top : 'inherit')};
	right: ${({ right }) => (right ? right : 'inherit')};
	bottom: ${({ bottom }) => (bottom ? bottom : 'inherit')};
	z-index: 4;
	pointer-events: none;
	user-select: none;
`;

const Table1 = () => {
	const [isLoading, setLoading] = useState(false);
	useSetHeader({
		title: t.fundSellerPotential.title,
	});
	const history = useHistory();
	const canvasRef = useRef(null);
	const fundSellerPotentialAction = useStoreActions(
		actions => actions.fundSellerPotentialModel
	);
	// fetch params

	useLayoutEffect(() => {
		setLoading(true);
		FunderService.getFundSellerPotential()
			.then(response => {
				const data = JSON.parse(response);
				const labelList = data.map(({ name }) => name);
				const linksList = data.map(({ id }) => id);
				const coordinateList = data.map(({ coordinate }) => {
					return {
						x: coordinate.x,
						y: coordinate.y,
					};
				});
				Chart.plugins.register({
					beforeDraw: (chart, easing) => {
						const chartArea = chart.chartArea;
						const ctx = chart.chart.ctx;

						const midX = (chartArea.left + chartArea.right) / 2;
						const midY = (chartArea.top + chartArea.bottom) / 2; //yScale.getPixelForValue(0);

						// Top left quadrant
						ctx.fillStyle = Theme.color.lightBlue;
						ctx.fillRect(
							chartArea.left,
							chartArea.top,
							midX - chartArea.left,
							midY - chartArea.top
						);

						// Top right quadrant
						ctx.fillStyle = Theme.color.yellow;
						ctx.fillRect(
							midX,
							chartArea.top,
							chartArea.right - midX,
							midY - chartArea.top
						);

						// Bottom right quadrant
						ctx.fillStyle = Theme.color.lightBlue;
						ctx.fillRect(
							midX,
							midY,
							chartArea.right - midX,
							chartArea.bottom - midY
						);

						// Bottom left quadrant
						ctx.fillStyle = Theme.color.main;
						ctx.fillRect(
							chartArea.left,
							midY,
							midX - chartArea.left,
							chartArea.bottom - midY
						);
					},
				});

				const ctx =
					canvasRef && canvasRef.current
						? canvasRef.current.getContext('2d')
						: null;
				const scatterChart = new Chart(ctx, {
					type: 'scatter',
					showTooltips: true,
					data: {
						labels: labelList,
						links: linksList,
						datasets: [
							{
								data: coordinateList,
								pointBackgroundColor: 'black',
							},
						],
					},
					options: {
						legend: false,
						scales: {
							yAxes: [
								{
									scaleLabel: {
										display: true,
										labelString: t.chart.yAxis,
										fontSize: 14,
										lineHeight: 3,
										fontStyle: 'bold',
										fontColor: '#000',
									},
								},
							],
							xAxes: [
								{
									scaleLabel: {
										display: true,
										labelString: t.chart.xAxis,
										fontSize: 14,
										lineHeight: 2,
										fontStyle: 'bold',
										fontColor: '#000',
									},
									ticks: {
										beginAtZero: true,
										max: 3.5,
									},
								},
							],
						},
						layout: {
							padding: {
								left: 0,
								right: 50,
								top: 50,
								bottom: 50,
							},
						},
						tooltips: {
							callbacks: {
								label: (tooltipItem, data) => {
									const label = data.labels[tooltipItem.index];
									return label;
								},
							},
						},
						animation: {
							onComplete: function () {
								const ctx = this.chart.ctx;
								ctx.font = Chart.helpers.fontString(
									Chart.defaults.global.defaultFontFamily,
									'normal',
									Chart.defaults.global.defaultFontFamily
								);
								ctx.fillStyle = 'black';
								ctx.textAlign = 'center';
								ctx.textBaseline = 'bottom';
							},
						},
					},
				});

				canvasRef.current.addEventListener('click', e => {
					const activePoint = scatterChart.getElementAtEvent(e);
					if (activePoint.length > 0) {
						const clickedElementindex = activePoint[0]._index;
						const href = scatterChart.data.links[clickedElementindex];
						const labels = scatterChart.data.labels[clickedElementindex];
						fundSellerPotentialAction.addIsFundSellerPotential({
							isFundSellerPotential: true,
							id: href,
							label: labels,
						});
						history.push(`${routes.recommendedFunds}/1`);
					}
				});

				setLoading(false);
			})
			.catch(e => console.log(e));
	}, []);

	return (
		<WithPagination isLoading={isLoading}>
			<MainContainer marginTop='5.4rem'>
				<Wrapper>
					<canvas ref={canvasRef}></canvas>
					{!isLoading && (
						<>
							<Title right='calc(50% + 3rem)' top='8rem'>
								{t.chart.titleLeftTop}
							</Title>
							<Title right='calc(50% + 3rem)' bottom='14.5rem'>
								{t.chart.titleLeftBottom}
							</Title>
							<Title right='10rem' top='8rem'>
								{t.chart.titleRightTop}
							</Title>
							<Title right='10rem' bottom='14.5rem'>
								{t.chart.titleRightBottom}
							</Title>
						</>
					)}
				</Wrapper>
			</MainContainer>
			<Footer type='logoOnly' />
		</WithPagination>
	);
};

export default Table1;
