import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useStoreState } from 'easy-peasy';
import { useSetModal } from '../../hooks/useSetModal';
import { useLocation, useHistory } from 'react-router';
import { routes } from '../../routes/routes';

// modals - don't remove even if not used
import LoginModal from '../../modals/LoginModal';
import GapAnalysisModal from '../../modals/GapAnalysisModal';
import PeergroupsModal from '../../modals/PeergroupsModal';
import SearchFundsModal from '../../modals/SearchFundsModal';
import AssetManagerModal from '../../modals/AssetManagerModal';

const ModalBlock = styled.div<{ doOverflow?: boolean }>`
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -80%);
	opacity: 0;
	background: ${({ theme }) => theme.color.main};
	z-index: 12;
	max-height: 75%;
	overflow-y: ${({ doOverflow }) => (doOverflow ? 'auto' : 'unset')};
	width: 98%;
	padding: 3rem;
	@media screen and (min-width: 768px) {
		padding: 4rem 7rem;
		min-width: 67.5rem;
		width: auto;
	}
`;

const ModalWrapper = styled.div<{ visible: boolean }>`
	height: 100%;
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	background: rgba(255, 255, 255, 0.5);
	z-index: -9;
	opacity: 0;

	pointer-events: none;
	${({ visible }) =>
		visible &&
		css`
			pointer-events: all;
			z-index: 8;
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
			${ModalBlock} {
				transform: translate(-50%, -50%);
				opacity: 1;
				will-change: transform, opacity;
				transition: transform 0.3s ease, opacity 0.3s ease-in-out;
			}
		`}
`;

const ModalTitle = styled.h2`
	position: relative;
	padding-right: 5rem;
	font-weight: 700;
	font-size: ${({ theme }) => theme.font.size.text_XL};
	line-height: ${({ theme }) => theme.font.lineHeight.text_XL};
	color: ${({ theme }) => theme.color.white};
`;

const ModalClose = styled.div`
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	height: 2.8rem;
	width: 2.8rem;
	:before,
	:after {
		content: '';
		left: 50%;
		top: 50%;
		height: 1px;
		width: 100%;
		position: absolute;
		transform: translate(-50%, -50%);
		background: ${({ theme }) => theme.color.white};
	}
	:before {
		transform: translate(-50%, -50%) rotate(45deg);
	}
	:after {
		transform: translate(-50%, -50%) rotate(-45deg);
	}
	:hover {
		cursor: pointer;
	}
`;

const ModalBody = styled.div`
	padding-top: 4rem;
`;

const modalTypes = {
	LoginModal,
	GapAnalysisModal,
	PeergroupsModal,
	SearchFundsModal,
	AssetManagerModal,
};

const Modal: React.FC = () => {
	const modalData = useStoreState(state => state.modalModel.modal);
	const setModal = useSetModal();
	const { visible, component, title, canBeClosed, doOverflow } = modalData;
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		if (visible) {
			setModal({});
		}
	}, [location]);

	const handleModalClose = () => {
		if (canBeClosed) {
			setModal({});
		}
		if (!canBeClosed) {
			history.push(routes.signIn);
		}
	};

	const constrainStopPropagation = (event: React.MouseEvent) => {
		event.stopPropagation();
	};

	const DynamicBody = React.createElement(
		component ? modalTypes[component] : ''
	);

	return (
		<>
			<ModalWrapper visible={visible} onClick={handleModalClose}>
				<ModalBlock doOverflow={doOverflow} onClick={constrainStopPropagation}>
					<ModalTitle>
						{title}
						<ModalClose onClick={handleModalClose} />
					</ModalTitle>
					{component && <ModalBody>{DynamicBody}</ModalBody>}
				</ModalBlock>
			</ModalWrapper>
		</>
	);
};

export default Modal;
