import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Input from "../components/Input/Input";
import BasicLink from "../components/BasicLink/BasicLink";
import { routes } from "../routes/routes";
import Form from "../components/Form/Form";
import { t } from "../translations/en";
import Flex from "../components/Flex/Flex";
import InvalidFormInfo from "../components/InvalidFormInfo/InvalidFormInfo";
import CustomButton from "../components/Button/Button";
import { FunderService } from "../services/FunderService";

const StyledButton = styled(CustomButton)`
  padding: 0;
  min-height: 5.5rem;
  width: 15rem;
`;

const ErrorLogoutInformation = styled.span`
  display: block;
  margin-bottom: 1rem;
  margin-top: -2rem;
  color: ${({ theme }) => theme.color.white};
`;

type getValueType = (e: React.FormEvent) => string;
type handleSendType = (
  e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
) => void;

const LoginModal = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [validForm, setValidForm] = useState<boolean | undefined>(undefined);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const getValue: getValueType = (e) => {
    const target = e.target as HTMLTextAreaElement;
    const value = target.value;
    return value;
  };

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(sessionStorage.getItem("errorMessage"));
    }, 100);
    return () => {
      sessionStorage.removeItem("errorMessage");
    };
  }, []);

  const handleSend: handleSendType = (e) => {
    e.preventDefault();
    setValidForm(undefined);
    setLoading(true);
    FunderService.login(email, password)
      .then((response) => {
        const data = JSON.parse(response);
        localStorage.setItem("token", data.access);
        localStorage.setItem("refresh", data.refresh);
        location.reload();
      })
      .catch((error) => {
        console.log(error.code);
        setValidForm(false);
        setLoading(false);
      });
  };

  return (
    <>
      {errorMessage && (
        <ErrorLogoutInformation>{errorMessage}</ErrorLogoutInformation>
      )}
      <Form onSubmit={handleSend}>
        <Input
          autofocus
          placeholder={t.email}
          onChange={(e) => setEmail(getValue(e))}
        />
        <Input
          type={"password"}
          placeholder={t.password}
          onChange={(e) => setPassword(getValue(e))}
        />
        {validForm === false ? (
          <InvalidFormInfo>{t.wrongPassword}</InvalidFormInfo>
        ) : null}
        <Flex justify="flex-end" marginTop={"1.5rem"}>
          <BasicLink colorTheme="light" to={routes.passwordRemind} size="small">
            {t.forgotPassword}
          </BasicLink>
        </Flex>
        <Flex marginTop={"3rem"}>
          <StyledButton
            content={t.loginButton}
            onClick={handleSend}
            isLoading={isLoading}
          />
        </Flex>
        <Flex marginTop={"1rem"}>
          <BasicLink colorTheme="light" to={routes.contact} size="small">
            {t.notRegistered}
          </BasicLink>
        </Flex>
      </Form>
    </>
  );
};

export default LoginModal;
