import React from 'react';
import Title from '../Title/Title';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';
import { t } from '../../translations/en';
import FundItem from '../FundItem/FundItem';
import TabContainer from '../TabContainer/TabContainer';
import TableRow from '../TableRow/TableRow';
import TableItem from '../TableItem/TableItem';
import VerticalGrid from '../VerticalGrid/VerticalGrid';
import { useGetPerformance } from '../../hooks/useGetPerformance';
import InformationMessage from '../InformationMessage/InformationMessage';
import Tooltip from '../Tooltip/Tooltip';

type DataObject = {
	name: string;
	isin: string;
	peergroup: string;
	rating: number;
	shortTermPerformance: number;
	longTermPerformance: number;
};

interface ShareClassesInterface {
	data: Array<DataObject>;
}

const ShareClasses: React.FC<ShareClassesInterface> = ({ data }) => (
	<>
		<FundItem title={t.fundDetail.shareClasses}>
			<>
				<TabContainer>
					<HorizontalGrid
						columnPattern={
							'repeat(3, calc((100% / 13) *3)) calc((100% / 13) *2) repeat(2, calc((100% / 13) *1))'
						}
						gap={'0'}
						align="center"
					>
						<Title size="big" colorTheme="dark">
							{t.fundDetail.shareClass}
						</Title>
						<Title size="big" colorTheme="dark">
							{t.fundDetail.isin}
						</Title>
						<Title size="big" colorTheme="dark">
							{t.fundDetail.peergroup}
						</Title>
						<Title size="big" colorTheme="dark" align="center">
							<Tooltip tooltip={t.ratingTooltip} textAlign="left">
								{t.fundDetail.rating}
							</Tooltip>
						</Title>
						<Title size="big" colorTheme="dark" align="center">
							<Tooltip
								tooltip={t.shortTermPerformanceTooltip}
								textAlign="left"
								align="right"
							>
								{t.fundDetail.shortTermPerformance}
							</Tooltip>
						</Title>
						<Title size="big" colorTheme="dark" align="center">
							<Tooltip
								tooltip={t.longTermPerformanceTooltip}
								textAlign="left"
								align="right"
							>
								{t.fundDetail.longTermPerformance}
							</Tooltip>
						</Title>
					</HorizontalGrid>
				</TabContainer>
				<VerticalGrid gap="0.7rem">
					{data && data.length ? (
						data.map(
							(
								{
									name,
									isin,
									peergroup,
									rating,
									shortTermPerformance,
									longTermPerformance,
								},
								index
							) => (
								<TableRow
									key={index}
									columnPattern={
										'repeat(3, calc((100% / 13) *3)) calc((100% / 13) *2) repeat(2, calc((100% / 13) *1))'
									}
								>
									<TableItem>{useGetPerformance(name)}</TableItem>
									<TableItem>{useGetPerformance(isin)}</TableItem>
									<TableItem>{useGetPerformance(peergroup)}</TableItem>
									<TableItem center>{useGetPerformance(rating)}</TableItem>
									<TableItem center>
										{useGetPerformance(shortTermPerformance)}
									</TableItem>
									<TableItem center>
										{useGetPerformance(longTermPerformance)}
									</TableItem>
								</TableRow>
							)
						)
					) : (
						<InformationMessage />
					)}
				</VerticalGrid>
			</>
		</FundItem>
	</>
);

export default ShareClasses;
