import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import Flex from '../Flex/Flex';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';
import Title from '../Title/Title';
import { t } from '../../translations/en';
import { useStoreState, useStoreActions } from '../../easy-peasy/hooks/hooks';
import selectTheme from '../../utils/SelectTheme';

const Wrapper = styled.div`
	margin: 3.5rem 0 -1rem 0;
`;

const StyledTitle = styled(Title)`
	line-height: 3.8rem;
	margin-right: 2rem;
`;

interface IFilter {
	strategies: {
		id: string;
		name: string;
	}[];
}

interface IPeergroupFilter {
	peergroups: {
		id: string;
		name: string;
		asset_class?: string;
		url?: string;
	}[];
}

interface IFundSellerFilter {
	fundSellers?: any;
}

interface IFilterResults {
	setFundSellerId?: React.Dispatch<React.SetStateAction<string>>;
	setPeergroupId?: React.Dispatch<React.SetStateAction<string>>;
	setStrategyValue?: React.Dispatch<React.SetStateAction<string>>;
	setInternalExternal?: React.Dispatch<React.SetStateAction<string>>;
	isClearable?: boolean;
	isPeergroupPage?: boolean;
	sellers: any;
}

interface IOption {
	label: string;
	value: string | number;
}

const customStyles = {
	valueContainer: (provided, state) => ({
		...provided,
		minHeight: '2rem',
		fontSize: '1.2rem',
	}),
	placeholder: (provided, state) => ({
		...provided,
		paddingLeft: '0.5rem',
		color: '#008080',
	}),
	singleValue: (provided, state) => ({
		...provided,
		paddingLeft: '0.5rem',
		color: '#008080',
	}),
};

const PeergroupFilters: React.FC<
	IFilter & IPeergroupFilter & IFilterResults & IFundSellerFilter
> = ({ strategies, fundSellers, setFundSellerId, setStrategyValue }) => {
	const [defaultValue, setDefaultValue] = useState<Array<IOption>>(null);
	const [sellerValue, setSellerValue] = useState<Array<IOption>>([]);
	const [strategyFilterValue, setStrategyFilterValue] = useState<IOption>(null);
	const [strategyFilter, setStrategyFilter] = useState<Array<IOption>>(null);
	const [fundSellerFilter, setfundSellerFilter] = useState<Array<IOption>>(
		null
	);

	const sellerData = useStoreState(state => state.sellerModel.id);
	const sellerAction = useStoreActions(actions => actions.sellerModel.addId);
	const clearFilters = useStoreState(
		state => state.peergroupFilterModel.items.clearFilters
	);

	useEffect(() => {
		if (clearFilters) {
			setSellerValue([]);
			setStrategyFilterValue(null);
			setFundSellerId(parseArrayToString([]));
			setStrategyValue('');
		}
	}, [clearFilters]);

	useEffect(() => {
		return () => {
			sellerAction({ id: '' });
		};
	}, []);

	useEffect(() => {
		if (strategies) {
			const strategy = [];
			strategies.map((item, index) => {
				let obj = {
					label: item.id,
					value: item.name,
				};
				strategy.push(obj);
			});
			setStrategyFilter(strategy);
		}
	}, [strategies]);

	useEffect(() => {
		if (fundSellers) {
			const fundSeller = [];
			fundSellers.map((item, index) => {
				let obj = {
					label: item.name,
					value: item.id,
				};
				fundSeller.push(obj);
			});
			setfundSellerFilter(fundSeller);
		}
	}, [fundSellers]);

	const parseArrayToString = (
		array: Array<{ value: number | string; label: string }>
	) => {
		if (array.length) {
			const arrayWithIds = array.map(({ value }) => value);
			return arrayWithIds.join(',');
		}
		return '';
	};

	return (
		<Wrapper>
			<Flex justify='flex-start' align='flex-start'>
				<StyledTitle>{t.filters}</StyledTitle>
				<HorizontalGrid columnPattern={'repeat(4,25rem)'} gap='2rem'>
					<Select
						classNamePrefix='filter'
						styles={customStyles}
						isSearchable={false}
						isMulti
						defaultValue={defaultValue}
						value={sellerValue}
						isClearable
						placeholder={t.recommendedFundsPage.tableHeaders.fundSeller}
						options={fundSellerFilter}
						theme={selectTheme}
						onChange={value => {
							const retunedValue = value ? value : [];
							setSellerValue(retunedValue);
							setFundSellerId(parseArrayToString(retunedValue));
						}}
					/>
					<Select
						styles={customStyles}
						isSearchable={false}
						isClearable
						value={strategyFilterValue}
						placeholder={t.recommendedFundsPage.tableHeaders.strategy}
						options={strategyFilter}
						theme={selectTheme}
						onChange={value => {
							const returnedValue = value === null ? '' : value.value;
							setStrategyFilterValue(value);
							setStrategyValue(returnedValue);
						}}
					/>
				</HorizontalGrid>
			</Flex>
		</Wrapper>
	);
};

export default PeergroupFilters;
