import { Action } from "easy-peasy";
import { action } from "easy-peasy";

export interface SellerModel {
  id?: string | number;
  addId: Action<SellerModel, { id?: string | number }>;
}

const sellerModel: SellerModel = {
  id: "",
  addId: action((state, payload) => {
    state.id = payload.id;
  }),
};

export default sellerModel;
