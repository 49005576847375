import React from 'react';
import styled from 'styled-components'

interface IListLegendItemSquareColor {
    bgColor: string;
    className?: string;
}

const ItemSquareColor = styled.div`
    height: 3rem;
    width: 3rem;
    margin-right: 1.5rem;
`

const ListLegendItemSquareColor: React.FC<IListLegendItemSquareColor> = ({ bgColor, className }) => (
    <ItemSquareColor className={className} style={{backgroundColor: `${bgColor}`}}/>
)

export default ListLegendItemSquareColor;