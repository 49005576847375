import { Action } from "easy-peasy";
import { action } from "easy-peasy";

export interface HeaderDataModel {
  items: {
    title?: string;
    titleName?: string;
    subtitle?: string;
    hasSubtitle?: boolean;
    isStaticPage?: boolean;
    hasHeader?: boolean;
    searchComponent?: string | null;
    additionalSubtitle?: string;
  };
  addHeaderData: Action<
    HeaderDataModel,
    {
      title?: string;
      titleName?: string;
      subtitle?: string;
      isStaticPage?: boolean;
      hasSubtitle?: boolean;
      hasHeader?: boolean;
      searchComponent?: string | null;
      additionalSubtitle?: string;
    }
  >;
}

const headerDataModel: HeaderDataModel = {
  items: {
    hasSubtitle: true,
    hasHeader: true,
    isStaticPage: false,
  },
  addHeaderData: action((state, payload) => {
    state.items.title = payload.title;
    state.items.titleName = payload.titleName;
    state.items.subtitle = payload.subtitle;
    state.items.additionalSubtitle = payload.additionalSubtitle;
    state.items.hasSubtitle =
      payload.hasSubtitle !== undefined ? payload.hasSubtitle : true;
    state.items.hasHeader =
      payload.hasHeader !== undefined ? payload.hasHeader : true;
    state.items.isStaticPage = payload.isStaticPage;
    state.items.searchComponent =
      payload.searchComponent !== undefined ? payload.searchComponent : null;
  }),
};

export default headerDataModel;
