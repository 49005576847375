import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import CurrentRecommendations from "../../components/CurrentRecommendations/CurrentRecommendations";
import ShareClasses from "../../components/ShareClasses/ShareClasses";
import FundSellerPotential from "../../components/FundSellerPotential/FundSellerPotential";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import Footer from "../../components/Footer/Footer";
import { RouteComponentProps, Link, useParams } from "react-router-dom";
import { useSetHeader } from "../../hooks/useSetHeader";
import { t } from "../../translations/en";
import { FunderService } from "../../services/FunderService";
import { useStoreActions } from "../../easy-peasy/hooks/hooks";
import Button from "../../components/Button/Button";
import { routes } from "../../routes/routes";
import BackButton from "../../components/BackButton/BackButton";
import Flex from "../../components/Flex/Flex";
import WithPagination from "../../components/WithPagination/WithPagination";
import { numbersWithDotSeparator } from "../../utils/Strings";

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 7fr 10fr;
  grid-column-gap: 2rem;
`;

interface IParams {
  id: string;
}

interface IFund extends RouteComponentProps<IParams> {
  // needs to be empty
}

const Fund: React.FC<IFund> = (props: IFund) => {
  const [recommendations, setRecommendations] = useState<any | null>(null);
  const [shareClassesData, setShareClassesData] = useState<any | null>(null);
  const [fundSellerData, setFundSellerData] = useState<any | null>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { id } = useParams();

  useEffect(() => {
    setLoading(true);
    FunderService.getFundDataById(props.match.params.id)
      .then((response) => {
        const data = JSON.parse(response);
        const peergroup = data.peergroup ? data.peergroup.name : "n/a";
        const srri = data.srri === null ? "n/a" : data.srri;
        const aum =
          data.million_fund_aum_for_last_month === null
            ? "n/a"
            : parseFloat(data.million_fund_aum_for_last_month).toFixed(2);
        const recommendationArray = data.recommendations.map((item: any) => {
          const date =
            item.start_date &&
            item.start_date.substring(0, item.start_date.lastIndexOf("-"));
          return {
            shareClass: item.fund.share_class.name,
            isin: item.fund.isin,
            fundSeller: item.seller.name,
            startDate: date,
          };
        });
        const shareClasses = data.funds.map((item: any) => {
          return {
            name: item.share_class.name,
            isin: item.isin,
            peergroup: item.peergroup ? item.peergroup.name : "n/a",
            rating: item.morningstar_rating_overall,
            shortTermPerformance: item.short_term_performance,
            longTermPerformance: item.long_term_performance,
          };
        });
        const fundSellersArray = data.sellers.map((item: any) => {
          return {
            name: item.name,
            peergroup: item.number_of_recommendations_in_peergroup,
            marketShare: Number(item.market_share).toFixed(2),
            changeRate: Number(item.new_recommendations_ratio).toFixed(),
            shareExternalFunds: Number(item.share_external_funds).toFixed(),
            potential: item.potential,
            id: item.id,
          };
        });
        const formattedAum = numbersWithDotSeparator(aum);
        headerAction({
          title: `${t.fundDetail.title}`,
          titleName: `${
            data.fund_name ? data.fund_name.name : data.share_class.name
          }`,
          additionalSubtitle: `\xa0\xa0\xa0|\xa0\xa0\xa0${t.peergroup}: ${peergroup}\xa0\xa0\xa0|\xa0\xa0\xa0AuM in EUR Mio.: ${formattedAum}\xa0\xa0\xa0|\xa0\xa0\xa0SRRI: ${srri}`,
          searchComponent: "FundDetailViewSearch",
        });
        setFundSellerData(fundSellersArray);
        setShareClassesData(shareClasses);
        setRecommendations(recommendationArray);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, [id]);

  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );
  useSetHeader({
    title: `${t.fundDetail.title}`,
    searchComponent: "FundDetailViewSearch",
  });
  return (
    <WithPagination isLoading={isLoading}>
      <MainContainer>
        <Flex justify="flex-start">
          <BackButton marginRight="1rem" />
          <Flex marginTop="0.5rem">
            <Link to={routes.requestCompetitorAnalysis}>
              <Button
                size={"small"}
                colorTheme="dark"
                content={t.requestCompetitorAnalysis.requestCompetitorAnalysis}
              />
            </Link>
          </Flex>
        </Flex>
      </MainContainer>
      <MainContainer marginTop="5.4rem">
        <Wrapper>
          <CurrentRecommendations data={recommendations} />
          <div>
            <VerticalGrid gap="8rem">
              <FundSellerPotential data={fundSellerData} />
              <ShareClasses data={shareClassesData} />
            </VerticalGrid>
          </div>
        </Wrapper>
      </MainContainer>
      <Footer type="logoOnly" />
    </WithPagination>
  );
};

export default Fund;
