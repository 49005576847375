import { Action } from "easy-peasy";
import { action } from "easy-peasy";

export interface FundSellerPotentialModel {
  items: {
    isFundSellerPotential?: boolean;
    id?: string;
    label?: string;
  };
  addIsFundSellerPotential: Action<
    FundSellerPotentialModel,
    { isFundSellerPotential?: boolean; id?: string; label?: string }
  >;
}

const fundSellerPotentialModel: FundSellerPotentialModel = {
  items: {
    isFundSellerPotential: false,
    id: "",
    label: "",
  },
  addIsFundSellerPotential: action((state, payload) => {
    state.items.isFundSellerPotential = payload.isFundSellerPotential;
    state.items.id = payload.id;
    state.items.label = payload.label;
  }),
};

export default fundSellerPotentialModel;
