import React from 'react';
import styled from 'styled-components';
import Flex from '../Flex/Flex';
import Title from '../Title/Title';
import Text from '../Text/Text';
import { t } from '../../translations/en';
import InformationList from '../InformationList/InformationList';

interface CompanyAddressInterface {
	list?: Array<string>;
}

const StyledTitle = styled(Title)`
	font-size: ${({ theme }) => theme.font.size.text_XL};
	line-height: ${({ theme }) => theme.font.lineHeight.text_XL};
	padding-right: 0.5rem;
`;

const StyledText = styled(Text)`
	@media screen and (max-width: 444px) {
		display: block;
		width: 100%;
	}
`;

const StyledFlex = styled(Flex)`
	@media screen and (max-width: 444px) {
		flex-wrap: wrap;
	}
`;

const TitleWrapper = styled.div`
	margin-bottom: 2rem;
`;

const CompanyWrapper = styled.div`
	background: ${({ theme }) => theme.color.main};
	padding: 3rem;
	@media screen and (min-width: 768px) {
		padding: 5rem;
	}
	* {
		color: ${({ theme }) => theme.color.white};
	}
`;

const ListWrapper = styled.div`
	margin-top: 4rem;
`;

const ContactWrapper = styled.div`
	margin-top: 3rem;
`;

const CompanyAddress: React.FC<CompanyAddressInterface> = ({ list }) => (
	<CompanyWrapper>
		<div>
			<TitleWrapper>
				<StyledFlex justify='flex-start'>
					<StyledTitle>LuminAM UG</StyledTitle>
					<StyledText>(haftungsbeschränkt)</StyledText>
				</StyledFlex>
			</TitleWrapper>
			<Text>Eschborner Landstraße 42-50</Text>
			<Text>Haus B</Text>
			<Text>60489 Frankfurt am Main</Text>
		</div>
		<ContactWrapper>
			<Title>{t.companyEmail}</Title>
			<Title>{t.companyPhone}</Title>
		</ContactWrapper>
		{list ? (
			<ListWrapper>
				<InformationList
					colorTheme='light'
					size='smaller'
					list={list}
					gap='1.5rem'
				/>
			</ListWrapper>
		) : null}
	</CompanyWrapper>
);
export default CompanyAddress;
