import React from 'react'
import styled from 'styled-components'

interface IFundListTableItemTitle {
    content: string;
}

const Title = styled.p`
    font-weight: ${({ theme }) => theme.font.weight.medium};
    font-size: ${({ theme }) => theme.font.size.text_XS};
    flex-wrap: no-wrap;
`

const FundListTableItemTitle: React.FC<IFundListTableItemTitle> = ({content}) => (
    <Title>{content}</Title>
)

export default FundListTableItemTitle