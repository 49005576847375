import React from "react";
import styled from "styled-components";

type InnerType = boolean | undefined;

interface MainContainerConfig {
  children: React.ReactNode;
  inner?: InnerType;
  padding?: string;
  marginTop?: string;
  marginBottom?: string;
}

const Wrapper = styled("section")<{
  padding: string;
  inner: InnerType;
  marginTop?: string;
  marginBottom?: string;
}>`
  padding: ${({ padding }) => `${padding}`};
  max-width: ${({ inner }) => (inner ? "136rem" : "164rem")};
  width: 100%;
  margin: ${({ marginTop }) => marginTop} auto
    ${({ marginBottom }) => marginBottom} auto;
`;

const MainContainer: React.FC<MainContainerConfig> = ({
  inner,
  children,
  padding = "0 3.2rem",
  marginTop = "0rem",
  marginBottom = "0rem",
}) => (
  <>
    <Wrapper
      padding={padding}
      inner={inner}
      marginTop={marginTop}
      marginBottom={marginBottom}
    >
      {children}
    </Wrapper>
  </>
);

export default MainContainer;
