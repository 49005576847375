const selectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: "#c2e1e0",
    primary: "#c2e1e0",
  },
});

export default selectTheme;
