import styled, { css } from 'styled-components';

type TableItemType = {
	colorTheme?: string;
	paddingLeft?: boolean;
	bold?: boolean;
	center?: boolean;
	size?: 'big';
	background?: string;
};

const TableItem = styled.div<TableItemType>`
	font-size: ${({ theme, size }) =>
		size === 'big'
			? theme.font.size.table_item_big
			: theme.font.size.table_item};
	line-height: ${({ theme }) => theme.font.lineHeight.table_item};
	font-weight: ${({ theme, bold }) =>
		bold ? theme.font.weight.bold : theme.font.weight.medium};
	color: ${({ theme, colorTheme }) =>
		colorTheme ? theme.color.white : theme.color.black};
	padding: 0.7rem 1.5rem;
	padding-right: ${({ colorTheme }) => (colorTheme ? '4.5rem' : '1.5rem')};
	padding-left: ${({ paddingLeft }) => (paddingLeft ? '4.5rem' : '1.5rem')};
	min-height: 4rem;
	display: flex;
	align-items: center;
	position: relative;
	word-break: break-word;
	height: 100%;

	${({ center }) =>
		center &&
		css`
			justify-content: center;
		`}

	${({ colorTheme }) =>
		colorTheme &&
		css`
			background: ${({ theme }) => theme.color[colorTheme]};
		`}

	${({ background }) =>
		background &&
		css`
			background: ${({ theme }) => theme.color[background]};
			color: ${({ theme }) =>
				background === 'main' ? theme.color.white : theme.color.black};
		`};

`;

export default TableItem;
