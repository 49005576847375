import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { routes } from "../routes/routes";
import { t } from "../translations/en";
import Flex from "../components/Flex/Flex";
import CustomButton from "../components/Button/Button";
import { useSetModal } from "../hooks/useSetModal";
import { useHistory } from "react-router";
import { FunderService } from "../services/FunderService";
import Select from "react-select";
import { useStoreActions } from "../easy-peasy/hooks/hooks";

const CustomSelect = styled(Select)`
  height: 5.6rem;
`;

const StyledButton = styled(CustomButton)`
  margin-right: 1rem;
`;

const AssetSelect = styled(CustomSelect)`
  margin-bottom: 1rem;
`;

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    height: "5.6rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    paddingLeft: "4rem",
    color: "#008080",
    lineHeight: "5.6rem",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    paddingLeft: "4rem",
    lineHeight: "5.6rem",
    color: "#008080",
  }),
};

type assetClassType = {
  id: number;
  asset_class: string;
};

const PeergroupsModal = () => {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [validForm, setValidForm] = useState<boolean | undefined>(false);
  const [assetClass, setAssetClass] = useState<Array<assetClassType>>();
  const [peergroups, setPeergroups] = useState<any>();
  const [peergroupDataset, setPeergroupDataset] = useState<any>();
  const [peergroupsDropdown, setPeegroupsDropdown] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [currentChoice, setCurrentChoice] = useState<number>();
  const [peergroupSelectValue, setPeergroupSelectValue] = useState<any>();
  const setClearFilter = useStoreActions(
    (actions) => actions.peergroupFilterModel.setFiltersState
  );

  useEffect(() => {
    FunderService.getAssetClasses()
      .then((response) => {
        const data = JSON.parse(response);
        const assetArr = [];
        const peergroupArr = [];
        const result = data.map((item, index) => {
          const peergroupMappedArray = item.peergroups.map((item) => {
            const { id, name } = item;
            const tempObj = {
              value: id,
              label: name,
            };
            return tempObj;
          });
          const peergroupsObj = {
            id: index,
            peergroups: peergroupMappedArray,
          };
          const { asset_class } = item;
          const obj = {
            value: index,
            label: asset_class,
          };
          assetArr.push(obj);
          return peergroupsObj;
        });
        setAssetClass(assetArr);
        setPeergroups(peergroupArr);
        setPeergroupDataset(result);
        setValidForm(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (currentChoice === null) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [currentChoice]);

  const history = useHistory();
  const setModal = useSetModal();

  const handleCloseModal = () => {
    setModal({});
  };

  const handleSend = () => {
    setClearFilter({ clearFilters: true });
    history.push(`${routes.peergroupPage}/${currentChoice}/1`);
  };

  const handleUpdatePeergroupSelect = (index) => {
    setValidForm(true);
    setIsDisabled(false);
    setPeergroupSelectValue({ value: null, label: "Peergroup" });
    setCurrentChoice(null);
    const dropdownData = peergroupDataset[index].peergroups;
    setPeegroupsDropdown(dropdownData);
  };

  const handleUpdateCurrentChoice = (value) => {
    setCurrentChoice(value.value);
    setPeergroupSelectValue(value);
  };

  return (
    <div>
      <AssetSelect
        styles={customStyles}
        placeholder={t.assetClass}
        options={assetClass}
        isSearchable={false}
        onChange={(value) => handleUpdatePeergroupSelect(value.value)}
      />
      <CustomSelect
        styles={customStyles}
        isSearchable={false}
        isDisabled={isDisabled}
        placeholder={t.peergroup}
        value={peergroupSelectValue}
        options={peergroupsDropdown}
        onChange={(value) => handleUpdateCurrentChoice(value)}
      />
      <Flex marginTop={"3rem"} justify="flex-end">
        <StyledButton
          content={t.cancel}
          onClick={handleCloseModal}
          size="small"
          borderWidth={"1px"}
          isLoading={false}
          backButton
        />
        <CustomButton
          disabled={!validForm}
          content={t.confirm}
          onClick={handleSend}
          size="small"
          borderWidth={"1px"}
          isLoading={isLoading}
        />
      </Flex>
    </div>
  );
};

export default PeergroupsModal;
