import React, { useState } from "react";
import styled, { css } from "styled-components";
import BasicDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useFormatDate } from "../../hooks/useFormatDate";

interface DatePickerInterface {
  colorTheme?: "dark";
  name: string;
  handleChange?: (value: string) => void;
  value?: string;
  formik?: any;
}

const DatePickerWrapper = styled.div`
  & > div {
    width: 100%;
  }
  .react-datepicker__current-month {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    font-size: 1.2rem;
    line-height: 2.4rem;
    width: 2.4rem;
  }
`;

const StyledDatepicker = styled(BasicDatePicker)<{ colorTheme?: "dark" }>`
  width: 100%;
  border: 0.1rem solid ${({ theme }) => theme.color.white};
  padding: 1.6rem 2rem;
  height: 4.3rem;
  font-size: ${({ theme }) => theme.font.size.text_XS};
  background: ${({ theme }) => theme.color.transparentWhite};
  color: ${({ theme }) => theme.color.white};

  ::placeholder {
    color: ${({ theme }) => theme.color.white};
  }

  ${({ colorTheme }) =>
    colorTheme === "dark" &&
    css`
      background: ${({ theme }) => theme.color.mainLight};
      color: ${({ theme }) => theme.color.black};
      ::placeholder {
        color: ${({ theme }) => theme.color.black};
      }
    `}
`;

const DatePicker: React.FC<DatePickerInterface> = ({
  colorTheme,
  name,
  formik,
}) => {
  const [date, setDate] = useState<Date | null>(new Date());
  return (
    <DatePickerWrapper>
      <StyledDatepicker
        name={name}
        colorTheme={colorTheme}
        selected={date}
        dateFormat="dd/MM/yyyy"
        onChange={(date) => {
          setDate(date);
          formik.setFieldValue(name, useFormatDate(date));
        }}
      />
    </DatePickerWrapper>
  );
};

export default DatePicker;
