import React, { useEffect, useState } from "react";
import { FunderService } from "./../services/FunderService";

export const useGetUserProfileData = (setUserData) => {
  useEffect(() => {
    FunderService
      .getUserAccountInfo()
      .then((response) => {
        const data = JSON.parse(response);
        const { email, phone } = data;
        const userDataObj = {
          email: email,
          phone: phone,
        };
        setUserData(userDataObj);
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
};
