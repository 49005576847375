import React from 'react';
import styled from 'styled-components';

const Subtitle = styled.h2`
	font-weight: ${({ theme }) => theme.font.weight.regular};
	font-size: 2rem;
	line-height: 3rem;
	margin-bottom: 2rem;
	@media screen and (min-width: 768px) {
		font-size: ${({ theme }) => theme.font.size.text_30};
		line-height: ${({ theme }) => theme.font.lineHeight.text_45};
	}
`;

const CustomSpan = styled.span`
	display: block;
`;

interface ILandingPageSubtitle {
	subtitle1: string;
	subtitle2: string;
}

const LandingPageSubtitle: React.FC<ILandingPageSubtitle> = ({
	subtitle1,
	subtitle2,
}) => {
	return (
		<Subtitle>
			<CustomSpan>{subtitle1}</CustomSpan>
			<CustomSpan>{subtitle2}</CustomSpan>
		</Subtitle>
	);
};

export default LandingPageSubtitle;
