import React from 'react';
import styled from 'styled-components';
import CookieConsent from 'react-cookie-consent';
import { Link } from 'react-router-dom';
import { routes } from './../../routes/routes';
import Flex from '../Flex/Flex';
import MainContainer from '../MainContainer/MainContainer';

const Wrapper = styled.div`
	max-width: 80%;
`;

const Text = styled.span`
	font-size: ${({ theme }) => theme.font.size.text_M};
	line-height: ${({ theme }) => theme.font.lineHeight.text_M};
`;

const StyledLink = styled(Link)`
	font-size: ${({ theme }) => theme.font.size.text_M};
	line-height: ${({ theme }) => theme.font.lineHeight.text_M};
	padding-left: 0.5rem;
	transition: color 0.3s ease-in-out;
	color: ${({ theme }) => theme.color.main};
	:hover {
		color: ${({ theme }) => theme.color.main};
		text-decoration: none;
	}
`;

const CookiesBanner: React.FC = () => (
	<CookieConsent
		buttonText='Verstanden'
		containerClasses='cookie-wrapper'
		buttonClasses='cookie-button'
		style={{
			background: '#B3D9D9',
			color: '#202529',
			alignItems: 'center',
		}}
		buttonStyle={{
			color: '#fff',
			background: '#008080',
			fontSize: '13px',
			position: 'absolute',
		}}
	>
		<MainContainer padding='0 3.2rem'>
			<Flex justify='space-between'>
				<Wrapper>
					<Text>
						Diese Website verwendet Cookies, um die Nutzerfreundlichkeit zu
						verbessern. Durch die weitere Nutzung der Website akzeptieren Sei
						unsere
					</Text>
					<StyledLink to={routes.legalInformation}>
						Rechtliche Hinweise.
					</StyledLink>
				</Wrapper>
			</Flex>
		</MainContainer>
	</CookieConsent>
);

export default CookiesBanner;
