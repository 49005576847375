import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";
import { useStoreActions } from "../../easy-peasy/hooks/hooks";

//components
import MainContainer from "../../components/MainContainer/MainContainer";
import ListLegendWrapper from "../../components/ListLegendWrapper/ListLegendWrapper";
import PeergroupTable from "../../components/PeergroupTable/PeergroupTable";
import Footer from "../../components/Footer/Footer";
import { t } from "../../translations/en";

//interfaces
import { ISingleRecommendation } from "../../services/FunderService";

//services
import { FunderService } from "../../services/FunderService";

//utils
import { ObjectIndexer } from "../../views/RecommendedFunds/RecommendedFundsPage";
import { useSetHeader } from "../../hooks/useSetHeader";
import { useGetUserAccountType } from "../../hooks/useGetUserAccountType";

type handlePageChangeType = (
  page: number,
  id: string,
  peergroup: string
) => void;

interface IParameterProps {
  id: string;
  page: string;
  peergroup: string;
}

interface resultObjectInterface {
  id: number;
  fund_id: number;
  fund_name: string;
  peergroup: string;
  asset_manager: string;
  rating: string;
  fund_seller_id: number;
  fund_seller_name: string;
}

interface IPeergroupDetails
  extends RouteComponentProps<IParameterProps, {}, { fundType: string }> {}

interface IIsElementSorted extends ObjectIndexer<boolean> {
  fund: boolean;
  category: boolean;
  asset_manager: boolean;
  strategy: boolean;
  short_in_term_performance: boolean;
  long_in_term_performance: boolean;
  rating: boolean;
}

const arraySort = require("array-sort");

const PeergroupDetails: React.FC<IPeergroupDetails> = (
  props: IPeergroupDetails
) => {
  const ACCOUNT_TYPE = {
    SELLER: "seller",
    DEFAULT: "default",
    MANAGER: "manager",
  };

  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );
  useSetHeader({
    title: `${t.recommendedFundsPage.title}`,
  });

  const [peergroupRecommendations, setPeergroupRecomendations] = useState<Array<
    ISingleRecommendation
  > | null>(null);
  const [peergroupAlternatives, setPeergroupAlternatives] = useState<Array<
    ISingleRecommendation
  > | null>(null);
  const [peergroup, setPeergroup] = useState<string>("");
  const [peergroupName, setPeergroupName] = useState<string>("");
  const [recommendedCurrentPage, setRecommendedCurrentPage] = useState<string>(
    "1"
  );
  const [alternativeCurrentPage, setAlternativeCurrentPage] = useState<string>(
    "1"
  );
  const [recommendedPageCount, setRecommendedPageCount] = useState<number>(0);
  const [alternativePageCount, setAlternativePageCount] = useState<number>(0);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [id, setId] = useState<string>("0");
  const [isSorted, setIsSorted] = useState<IIsElementSorted>({
    fund: true,
    category: true,
    asset_manager: true,
    strategy: true,
    short_in_term_performance: true,
    long_in_term_performance: true,
    rating: true,
  });
  const [accountType, setAccountType] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const [managerID, setManagerId] = useState<number | null>(null);

  useGetUserAccountType(setAccountType, setUserId);
  const peergroupId = props.match.params.peergroup;
  const params = useParams<{ id: string; peergroup: string }>();

  useEffect(() => {
    const id = props.match.params.id;

    setId(id);
    const recommendedPage = Number(recommendedCurrentPage);
    FunderService.getPeergroupRecommendations(id, peergroupId, recommendedPage)
      .then((response) => {
        const data = JSON.parse(response);
        setPeergroup(data.peergroup.id);
        setPeergroupName(data.peergroup.name);
        headerAction({
          title: `${t.recommendedFundsPage.title}`,
          titleName: `${data.seller.name}`,
        });
        setRecommendedPageCount(Math.ceil(data.count / data.results.length));
        setPeergroupRecomendations(data.results);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const id = props.match.params.id;
    const alternativePage = Number(alternativeCurrentPage);
    if (userId !== null && accountType === ACCOUNT_TYPE.MANAGER) {
      FunderService.getPeergroupAlternatives(id, peergroupId, alternativePage)
        .then((response) => {
          const data = JSON.parse(response);
          setManagerId(data.asset_manager.id);
          setPeergroup(data.peergroup.id);
          setAlternativePageCount(Math.ceil(data.count / data.results.length));
          setPeergroupAlternatives(data.results);
        })
        .catch((error) => console.log(error));
    } else if (userId !== null) {
      FunderService.getPeergroupAlternatives(id, peergroupId, alternativePage)
        .then((response) => {
          const data = JSON.parse(response);
          setPeergroup(data.peergroup.id);
          setAlternativePageCount(Math.ceil(data.count / data.results.length));
          setPeergroupAlternatives(data.results);
        })
        .catch((error) => console.log(error));
    }
  }, [userId, accountType]);

  const handleRecommendationsPageChange: handlePageChangeType = (
    page,
    id,
    peergroup
  ) => {
    setLoading(true);
    const ID = String(id);
    const customPage = Number(page);
    FunderService.getPeergroupRecommendations(ID, peergroupId, customPage)
      .then((response) => {
        const data = JSON.parse(response);
        setRecommendedCurrentPage(`${page}`);
        setPeergroupRecomendations(data.results);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleAlternativePageChange: handlePageChangeType = (
    page,
    id,
    peergroup
  ) => {
    setLoading(true);
    const customPage = Number(page);
    if (userId !== null && accountType === ACCOUNT_TYPE.MANAGER) {
      FunderService.getPeergroupAlternatives(id, peergroupId, customPage)
        .then((response) => {
          const data = JSON.parse(response);
          setAlternativeCurrentPage(`${page}`);
          setPeergroupAlternatives(data.results);
          setLoading(false);
          window.scrollTo(0, 2000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    } else if (userId !== null) {
      FunderService.getPeergroupAlternatives(id, peergroupId, customPage)
        .then((response) => {
          const data = JSON.parse(response);
          setAlternativeCurrentPage(`${page}`);
          setPeergroupAlternatives(data.results);
          setLoading(false);
          window.scrollTo(0, 2000);
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
        });
    }
  };

  const handleSortRecommended = (sortBy: string) => {
    //Making a copy of existing results
    let unsorted = JSON.parse(JSON.stringify(peergroupRecommendations));
    let sortingResult: Array<ISingleRecommendation>;
    const sortType = !isSorted[sortBy];
    sortingResult =
      unsorted &&
      arraySort(unsorted, sortBy, sortType === true && { reverse: true });
    setPeergroupRecomendations(sortingResult);
    setIsSorted({ ...isSorted, [sortBy]: sortType });
  };

  const handleSortAlternatives = (sortBy: string) => {
    //Making a copy of existing results
    let unsorted = JSON.parse(JSON.stringify(peergroupAlternatives));
    let sortingResult: Array<ISingleRecommendation>;
    const sortType = !isSorted[sortBy];
    sortingResult =
      unsorted &&
      arraySort(unsorted, sortBy, sortType === true && { reverse: true });
    setPeergroupAlternatives(sortingResult);
    setIsSorted({ ...isSorted, [sortBy]: sortType });
  };

  return (
    <MainContainer>
      <ListLegendWrapper
        peergroup={peergroupName}
        isPotential={true}
        isPeergroupDetails={true}
      />
      <PeergroupTable
        handleSort={handleSortRecommended}
        pagesAmount={recommendedPageCount}
        currentPage={recommendedCurrentPage}
        handlePageChange={(page) =>
          handleRecommendationsPageChange(page, id, peergroup)
        }
        isHeaderShorter={true}
        ifLastHeaderCell={true}
        ifRequestLink={true}
        id={id}
        results={peergroupRecommendations}
        data={peergroupRecommendations}
        headerContent={t.peergrupDetails.currentRecommendations}
        isLoading={isLoading}
      />
      <PeergroupTable
        handleSort={handleSortAlternatives}
        handlePageChange={(page) =>
          handleAlternativePageChange(page, id, peergroup)
        }
        pagesAmount={alternativePageCount}
        currentPage={alternativeCurrentPage}
        isLoading={isLoading}
        results={peergroupAlternatives}
        isHeaderShorter={true}
        ifLastHeaderCell={true}
        id={id}
        ifRequestLink={true}
        data={peergroupAlternatives}
        headerContent={t.peergrupDetails.alternatives}
      />
      <Footer type={"logoOnly"} />
    </MainContainer>
  );
};

export default PeergroupDetails;
