import React, { useState, useEffect } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import Text from '../../components/Text/Text';
import MainContainer from '../../components/MainContainer/MainContainer';
import { t } from '../../translations/en';
import Input from '../../components/Input/Input';
import Checkbox from '../../components/Checkbox/Checkbox';
import Title from '../../components/Title/Title';
import Footer from '../../components/Footer/Footer';
import Button from '../../components/Button/Button';
import { useSetHeader } from '../../hooks/useSetHeader';
import HorizontalGrid from '../../components/HorizontalGrid/HorizontalGrid';
import VerticalGrid from '../../components/VerticalGrid/VerticalGrid';
import FundItem from '../../components/FundItem/FundItem';
import Textarea from '../../components/Textarea/Textarea';
import { FunderService } from '../../services/FunderService';
import { useHistory } from 'react-router';
import WithPagination from '../../components/WithPagination/WithPagination';
import { routes } from '../../routes/routes';

const StyledTitle = styled(Title)`
	line-height: ${({ theme }) => theme.font.lineHeight.text_L};
`;

const Wrapper = styled.div`
	max-width: 44.5rem;
`;

const CustomInput = styled(Input)`
	width: 100%;
	padding: 1.6rem 2rem;
	height: 4.3rem;
`;

const StyledButton = styled(Button)`
	margin-top: 3rem;
`;

const GridLayout = styled.div`
	display: grid;
	grid-row-gap: 3rem;
	@media screen and (min-width: 768px) {
		grid-template-columns: 1fr 1fr;
		grid-column-gap: 10rem;
	}
`;

const Contact: React.FC = () => {
	const [name, setName] = useState<string>(''),
		[company, setCompany] = useState<string>(''),
		[email, setEmail] = useState<string>(''),
		[phone, setPhone] = useState<string>(''),
		[yourProblem, setYourProblem] = useState<string>(''),
		[checkbox1, setCheckbox1] = useState<boolean>(false),
		[checkbox2, setCheckbox2] = useState<boolean>(false),
		[currentCheckboxChoice, setCurrentCheckboxChoice] = useState<string>(''),
		[isFormValid, setIsFormValid] = useState<boolean>(false),
		[isLoading, setLoading] = useState<boolean>(false);

	useSetHeader({
		title: t.contactPage.title,
		hasSubtitle: false,
		isStaticPage: true,
	});
	const { addToast } = useToasts();
	const history = useHistory();

	const handleSendForm = e => {
		e.preventDefault();
		if (isFormValid) {
			setLoading(true);

			const dataObj = {
				name: name,
				company: company,
				email: email,
				phone: phone,
				kind_of_help: currentCheckboxChoice,
				your_problem: yourProblem,
			};
			FunderService.postContactForm(dataObj)
				.then(response => {
					addToast(t.contactForm.alertSuccess, {
						appearance: 'success',
						autoDismiss: true,
					});
					setLoading(false);
					history.push(`${routes.signIn}`);
				})
				.catch(error => {
					setLoading(false);
					if (error.response.status === 400) {
						addToast(error.response.data.email[0], {
							appearance: 'error',
							autoDismiss: true,
						});
					} else {
						addToast(t.contactForm.alertError, {
							appearance: 'error',
							autoDismiss: true,
						});
					}
					setLoading(false);
					console.log(error);
				});
		}
	};

	useEffect(() => {
		if (
			company.length !== 0 &&
			email.length !== 0 &&
			phone.length !== 0 &&
			currentCheckboxChoice.length !== 0 &&
			(checkbox1 === true || checkbox2 === true)
		) {
			setIsFormValid(true);
		} else {
			setIsFormValid(false);
		}
	}, [
		name,
		company,
		email,
		phone,
		currentCheckboxChoice,
		checkbox1,
		checkbox2,
	]);

	return (
		<WithPagination isLoading={isLoading}>
			<MainContainer marginBottom='4.4rem' marginTop='4rem'>
				<GridLayout>
					<div>
						<VerticalGrid gap='4rem'>
							<Text>{t.contactUs.text1}</Text>
							<div>
								<Text>{t.contactUs.text2}</Text>

								<StyledTitle>{t.contactUs.email}</StyledTitle>

								<StyledTitle>{t.contactUs.telephoneNumber}</StyledTitle>
								<StyledTitle>{t.contactUs.telephoneNumber2}</StyledTitle>
							</div>
						</VerticalGrid>
					</div>
					<Wrapper>
						<FundItem title={t.contactPage.formTitle}>
							<form onSubmit={e => handleSendForm(e)}>
								<VerticalGrid gap='2.4rem'>
									<VerticalGrid gap='0.6rem'>
										<CustomInput
											minLength={1}
											placeholder={t.name}
											colorTheme='dark'
											value={name}
											onChange={(e: React.SyntheticEvent) => {
												let target = e.target as HTMLInputElement;
												setName(target.value);
											}}
										/>
										<CustomInput
											minLength={1}
											placeholder={t.company}
											colorTheme='dark'
											onChange={(e: React.SyntheticEvent) => {
												let target = e.target as HTMLInputElement;
												setCompany(target.value);
											}}
										/>
										<CustomInput
											maxLength={254}
											type={'email'}
											placeholder={t.email}
											colorTheme='dark'
											onChange={(e: React.SyntheticEvent) => {
												let target = e.target as HTMLInputElement;
												setEmail(target.value);
											}}
										/>
										<CustomInput
											minLength={1}
											placeholder={t.phone}
											colorTheme='dark'
											onChange={(e: React.SyntheticEvent) => {
												let target = e.target as HTMLInputElement;
												setPhone(target.value);
											}}
										/>
									</VerticalGrid>
									<VerticalGrid gap='0.6rem'>
										<Checkbox
											paddingLeft={'5.5rem'}
											height={'4rem'}
											width={'4rem'}
											name='selected peergroups'
											label={t.contactUs.checkbox1}
											dataset={'i-am-interested-in-the-db'}
											value={checkbox1}
											colorTheme='dark'
											handleChange={value => {
												setCheckbox2(false);
												setCheckbox1(value);
												const element = event.target as HTMLInputElement;
												const checkboxChoice = element.getAttribute(
													'data-checkboxvalue'
												);
												setCurrentCheckboxChoice(checkboxChoice);
											}}
										/>

										<Checkbox
											paddingLeft={'5.5rem'}
											height={'4rem'}
											width={'4rem'}
											name='competitors'
											label={t.contactUs.checkbox2}
											dataset={'i-need-help'}
											value={checkbox2}
											colorTheme='dark'
											handleChange={value => {
												setCheckbox1(false);
												setCheckbox2(value);
												const element = event.target as HTMLInputElement;
												const checkboxChoice = element.getAttribute(
													'data-checkboxvalue'
												);
												setCurrentCheckboxChoice(checkboxChoice);
											}}
										/>
									</VerticalGrid>
									<Textarea
										placeholder={t.yourProblem}
										setYourProblem={setYourProblem}
									/>
								</VerticalGrid>
								<StyledButton
									size={'small'}
									colorTheme='dark'
									content={t.submit}
									type='submit'
									disabled={!isFormValid}
								/>
							</form>
						</FundItem>
					</Wrapper>
				</GridLayout>

				<Footer type={'logoOnly'} />
			</MainContainer>
		</WithPagination>
	);
};

export default Contact;
