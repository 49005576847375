import React from 'react';
import styled from 'styled-components';

interface IListLegendItemContent {
	content: string;
}

const ItemContent = styled.p`
	font-size: ${({ theme }) => theme.font.size.text_M};
`;

const ListLegendItemContent: React.FC<IListLegendItemContent> = ({
	content,
}) => <ItemContent>{content}</ItemContent>;

export default ListLegendItemContent;
