import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { verifyToken } from '../../utils/verifyToken';
import Loader from '../Loader/Loader';
import { useStoreActions } from '../../easy-peasy/hooks/hooks';

interface LoginRouteInterface {
	component: React.FC<{
		history: any;
		location: any;
		match: any;
		staticContext?: any;
	}>;
	path: string;
	exact?: boolean;
}

const LoginRoute: React.FC<LoginRouteInterface> = ({
	component: Component,
	path,
	exact,
}) => {
	const verifyAction = useStoreActions(
		(actions) => actions.verifiedModel.addVerified
	);
	const [verifiedToken, setVerifiedToken] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(true);
	const location = useLocation();
	useLayoutEffect(() => {
		verifyToken(setVerifiedToken, setLoading);
	}, [location.pathname]);
	useEffect(() => {
		verifyAction({ verified: verifiedToken });
	}, [verifiedToken]);
	if (isLoading) {
		return <Loader />;
	}
	return (
		<Route
			path={path}
			exact={exact}
			render={(props) =>
				verifiedToken ? (
					<>
						<Redirect
							to={{
								pathname: `${routes.recommendedFunds}/1`,
								state: { from: props.location },
							}}
						/>
					</>
				) : (
					<>
						<Component {...props} />
					</>
				)
			}
		/>
	);
};

export default LoginRoute;
