import React from "react";
import styled from "styled-components";

interface TextareaInterface {
  placeholder: string;
  setYourProblem?: React.Dispatch<React.SetStateAction<string>>;
}

const Wrapper = styled.div`
  padding: 1.6rem 0;
  width: 100%;
  min-height: 20rem;
  background: ${({ theme }) => theme.color.mainLight};
`;

const StyledTextarea = styled.textarea`
  border: none;
  padding: 0 2rem;
  background: ${({ theme }) => theme.color.mainLight};
  font-size: ${({ theme }) => theme.font.size.text_XS};
  line-height: 1.6rem;
  min-height: 20rem;
  width: 100%;
  ::placeholder {
    color: ${({ theme }) => theme.color.black};
  }
`;

const Textarea: React.FC<TextareaInterface> = ({
  placeholder,
  setYourProblem,
}) => (
  <Wrapper>
    <StyledTextarea
      placeholder={placeholder}
      onChange={(e: React.SyntheticEvent) => {
        let target = e.target as HTMLTextAreaElement;
        setYourProblem(target.value);
      }}
    />
  </Wrapper>
);

export default Textarea;
