import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import MainContainer from '../MainContainer/MainContainer';
import LuminLogo from '../../images/lumin-logo.png';
import Flex from '../Flex/Flex';
import { routes } from '../../routes/routes';
import { useSetModal } from '../../hooks/useSetModal';
import { t } from '../../translations/en';
import SideBar from '../SideBar/SideBar';
import { useStoreState } from '../../easy-peasy/hooks/hooks';
import FooterLink from '../../components/FooterLink/FooterLink';

interface HeaderTitleConfig {
	isStaticPage?: boolean;
	isLandingView?: boolean;
	verified?: boolean;
}

const Logo = styled.img`
	height: 5.2rem;
	margin-right: 1rem;
`;

const ContentWrapper = styled.section`
	background: ${({ theme }) => theme.color.transparent};
	padding: 1.6rem 0;
`;

const HeaderWrapper = styled.header`
	position: relative;
	z-index: 9;
`;

const StyledFooterLink = styled(FooterLink)`
	@media screen and (max-width: 1023px) {
		display: none;
	}
`;

const HeaderTitle: React.FC<HeaderTitleConfig> = ({ isStaticPage }) => {
	const setModal = useSetModal();
	const handleModalTrigger = () => {
		setModal({ visible: true, title: t.login, component: 'LoginModal' });
	};
	const verified = useStoreState(state => state.verifiedModel.items.verified);
	const token = Boolean(localStorage.getItem('token'));
	const [staticPage, setStaticPage] = useState<boolean>(false);

	useLayoutEffect(() => {
		setStaticPage(isStaticPage);
	}, [isStaticPage]);

	return (
		<HeaderWrapper>
			<MainContainer>
				<ContentWrapper>
					<Flex justify='space-between'>
						<Flex justify='flex-start'>
							<Link
								to={verified ? `${routes.recommendedFunds}/1` : routes.signIn}
							>
								<Logo src={LuminLogo} />
							</Link>
						</Flex>
						<Flex>
							{staticPage && (
								<>
									<StyledFooterLink destination={routes.aboutUs}>
										{t.aboutUs}
									</StyledFooterLink>
									<StyledFooterLink destination={routes.contact}>
										{t.contact}
									</StyledFooterLink>
									<StyledFooterLink destination={routes.news}>
										{t.news}
									</StyledFooterLink>
									<StyledFooterLink destination={routes.legalInformation}>
										{t.legalInformation}
									</StyledFooterLink>
									{token ? null : (
										<StyledFooterLink onClick={handleModalTrigger}>
											{t.login}
										</StyledFooterLink>
									)}
									<SideBar verified={token} />
								</>
							)}
						</Flex>
						{!staticPage && <SideBar verified={token} />}
					</Flex>
				</ContentWrapper>
			</MainContainer>
		</HeaderWrapper>
	);
};

export default HeaderTitle;
