import React from "react";
import { Route } from "react-router-dom";
import BackButton from "../BackButton/BackButton";
import MainContainer from "../MainContainer/MainContainer";
import { routes } from "../../routes/routes";

interface PublicRouteInterface {
  component: React.FC<{
    history: any;
    location: any;
    match: any;
    staticContext?: any;
  }>;
  path: string;
  exact?: boolean;
}

const PublicRoute: React.FC<PublicRouteInterface> = ({
  component: Component,
  path,
  exact,
}) => {
  const unavaibleBackButtonPaths = [
    routes.passwordChange,
    routes.passwordRemind,
    routes.previewPage,
    routes.aboutUs,
    routes.contact,
    routes.news,
    routes.legalInformation,
  ];
  const isBackButtonHidden = unavaibleBackButtonPaths.includes(path);
  return (
    <Route
      path={path}
      exact={exact}
      render={(props) => (
        <>
          {!isBackButtonHidden && (
            <MainContainer>
              <BackButton />
            </MainContainer>
          )}
          <Component {...props} />
        </>
      )}
    />
  );
};

export default PublicRoute;
