import React from 'react';
import styled from 'styled-components';

type FlexType = 'flex-start' | 'flex-end' | 'center' | 'space-between';

interface FlexConfig {
	align?: FlexType;
	justify?: FlexType;
	children: React.ReactNode;
	marginTop?: string;
	height?: string;
	className?: string;
}

const StyledFlex = styled.div<{
	justify: FlexType;
	align: FlexType;
	marginTop: string;
	height: string;
}>`
	display: flex;
	align-items: ${({ align }) => align};
	justify-content: ${({ justify }) => justify};
	margin-top: ${({ marginTop }) => marginTop};
	height: ${({ height }) => height};
`;

const Flex: React.FC<FlexConfig> = ({
	justify = 'center',
	align = 'center',
	marginTop = '0rem',
	height = 'auto',
	children,
	className,
}) => (
	<StyledFlex
		className={className}
		justify={justify}
		align={align}
		marginTop={marginTop}
		height={height}
	>
		{children}
	</StyledFlex>
);

export default Flex;
