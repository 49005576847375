import styled from 'styled-components';

const OptionsList = styled.div`
	position: absolute;
	left: 0;
	top: calc(100% + 1rem);
	width: 100%;
	z-index: 5;
	background: ${({ theme }) => theme.color.white};
	display: none;
	max-height: 20rem;
	overflow: auto;
`;

export default OptionsList;
