import React from "react";
import styled from "styled-components";

type colorThemeType = "light" | "dark";
type sizeType = "small" | "big" | "smaller";

interface InformationListConfig {
  list: Array<string>;
  colorTheme: colorThemeType;
  className?: string;
  gap?: string;
  size?: sizeType;
  isAboutUs?: boolean;
}

const Wrapper = styled("div")<{
  className?: string;
  gap?: string;
}>`
  display: grid;
  grid-row-gap: ${({ gap }) => gap};
  grid-auto-rows: min-content;
`;

const ListItem = styled("p")<{
  colorTheme: colorThemeType;
  size: sizeType;
  isAboutUs: boolean;
}>`
  position: relative;
  color: ${({ theme, colorTheme }) =>
    colorTheme === "light" ? theme.color.white : theme.color.black};
  font-size: ${({ theme, size }) =>
    size === "small"
      ? theme.font.size.text_M
      : size === "smaller"
      ? theme.font.size.text_XS
      : "1.6rem"};
  line-height: ${({ theme, size }) =>
    size === "smaller"
      ? theme.font.lineHeight.text_M
      : theme.font.lineHeight.text_L};
  padding-left: 1.5rem;
  &:before {
    content: " ";
    position: absolute;
    height: ${({ isAboutUs }) => (isAboutUs === true ? "5px" : "2px")};
    width: ${({ isAboutUs }) => (isAboutUs === true ? "5px" : "10px")};
    background: ${({ theme, colorTheme }) =>
      colorTheme === "light" ? theme.color.white : theme.color.black};
    left: 0px;
    top: ${({ size, isAboutUs }) =>
      size === "smaller" ? "10px" : isAboutUs ? "12px" : "14px"};
    border-radius: ${({ isAboutUs }) => (isAboutUs === true ? "50%" : "none")};
  }
`;

const InformationList: React.FC<InformationListConfig> = ({
  list,
  colorTheme,
  className,
  isAboutUs = false,
  gap = "2.5rem",
  size = "small",
}) => (
  <Wrapper className={className} gap={gap}>
    {list.map((item, index) => (
      <ListItem
        colorTheme={colorTheme}
        key={index}
        size={size}
        isAboutUs={isAboutUs}
      >
        {item}
      </ListItem>
    ))}
  </Wrapper>
);

export default InformationList;
