import React from 'react';
import styled from 'styled-components';
import Title from '../Title/Title';
import Flex from '../Flex/Flex';
import Caption from '../Caption/Caption';

interface FundItemInterface {
	title: string;
	children: React.ReactNode;
	withCaption?: boolean;
}

const Wrapper = styled.div`
	display: grid;
	grid-auto-rows: min-content;
	grid-row-gap: 2rem;
`;

const ContentWrapper = styled.div`
	display: grid;
	grid-row-gap: 1rem;
`;

const FundItem: React.FC<FundItemInterface> = ({
	title,
	children,
	withCaption,
}) => (
	<Wrapper>
		{withCaption ? (
			<Flex justify='space-between'>
				<Title size='big' colorTheme='medium'>
					{title}
				</Title>
				<Caption type='fund' />
			</Flex>
		) : (
			<Title size='big' colorTheme='medium'>
				{title}
			</Title>
		)}
		<ContentWrapper>{children}</ContentWrapper>
	</Wrapper>
);

export default FundItem;
