import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { routes } from "../../routes/routes";
import { t } from "../../translations/en";
import Flex from "../Flex/Flex";
import Search from "../Search/Search";
import { FunderService } from "../../services/FunderService";
import { useHistory, useLocation } from "react-router";
import selectTheme from "../../utils/SelectTheme";
import Select from "react-select";
import { useStoreActions } from "./../../easy-peasy/hooks/hooks";
import { useQuery } from "../../hooks/useQuery";

interface HeaderSearchInterface {
  type?: string | null;
  titleName?: string;
}

type handleSendType = (id: number) => void;

const StyledForm = styled.div`
  width: 30rem;
`;

const labels = {
  AssetManagerViewSearch: t.assetManager,
  FundDetailViewSearch: t.name,
};

const redirectRoutes = {
  AssetManagerViewSearch: routes.assetManagerView,
  FundDetailViewSearch: routes.fund,
  PeergroupHeaderSearch: routes.peergroupPage,
  GapPeergroupPageSearch: routes.gapPeergroupPage,
};

const HeaderSearch: React.FC<HeaderSearchInterface> = ({ type, titleName }) => {
  const [isListLoading, setListLoading] = useState<boolean>(false);
  const [assetClassName, setAssetClassName] = useState<string>("");
  const [assetClasses, setAssetClasses] = useState<any>();
  const [selectDropdown, setSelectDropdown] = useState<any>();
  const [peergroupSelectValue, setPeergroupSelectValue] = useState<any>(null);
  const [currentChoice, setCurrentChoice] = useState<number>();
  const [assetClassId, setAssetClassId] = useState<string>();
  const [autocompleteList, setAutocompleteList] = useState<
    Array<{
      value: number;
      name: string;
    }>
  >([]);

  const history = useHistory();
  const query = useQuery();
  const { pathname } = useLocation();
  const id = pathname.split("/")[2];
  const peergroupId = query.get("peergroupId");
  const pageSize = 20;

  const setClearFilter = useStoreActions(
    (actions) => actions.peergroupFilterModel.setFiltersState
  );

  const filterSearchList = (value: string, page: number) => {
    if (value !== null) {
      setListLoading(true);
      if (type === "AssetManagerViewSearch") {
        FunderService.getAssetManagerList(value, page, pageSize)
          .then((response) => {
            const data = JSON.parse(response).results.map((item) => ({
              value: item.id,
              name: item.name,
            }));
            if (page === 1) {
              setAutocompleteList(data);
            } else {
              setAutocompleteList([...autocompleteList, ...data]);
            }
            setListLoading(false);
          })
          .catch((error) => {
            setListLoading(false);
          });
      }
      if (type === "FundDetailViewSearch") {
        FunderService.getFundList(value, page, pageSize)
          .then((response) => {
            const data = JSON.parse(response).results.map((item) => ({
              value: item.id,
              name: item.name_with_isin,
            }));
            if (page === 1) {
              setAutocompleteList(data);
            } else {
              setAutocompleteList([...autocompleteList, ...data]);
            }
            setListLoading(false);
          })
          .catch((error) => {
            setListLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    filterSearchList("", 1);
  }, [type]);

  useEffect(() => {
    if (type === "GapPeergroupPageSearch") {
      if (assetClassId !== "" && peergroupId) {
        FunderService.getPeergroupsById(peergroupId)
          .then((response) => {
            setCurrentChoice(Number(peergroupId));
            const data = JSON.parse(response);
            setAssetClassName(data.asset_class);
            setPeergroupSelectValue(null);
          })
          .catch((error) => console.log(error));
      }
    }
    if (type === "PeergroupHeaderSearch") {
      if (assetClassId !== "" && id !== null) {
        FunderService.getPeergroupsById(id)
          .then((response) => {
            setCurrentChoice(Number(id));
            const data = JSON.parse(response);
            setAssetClassName(data.asset_class);
            setPeergroupSelectValue(null);
          })
          .catch((error) => console.log(error));
      }
    }
  }, [peergroupId]);

  useEffect(() => {
    if (type === "PeergroupHeaderSearch" || type === "GapPeergroupPageSearch") {
      FunderService.getAssetClasses().then((response) => {
        const data = JSON.parse(response);
        setAssetClasses(data);
      });
    }
  }, []);

  useEffect(() => {
    if (assetClasses && assetClassName) {
      const result = assetClasses.filter((item) =>
        assetClassName.includes(item.asset_class)
      );
      const peergroupsResult = result[0].peergroups;
      const dropdownArr = [];
      peergroupsResult.map((item) => {
        const { id, name } = item;
        const dropdownObj = {
          value: id,
          label: name,
        };
        dropdownArr.push(dropdownObj);
      });
      setSelectDropdown(dropdownArr);
    }
  }, [assetClasses, assetClassName]);

  const handleSend: handleSendType = (id) => {
    setClearFilter({});
    if (type === "AssetManagerViewSearch") {
      history.push(`${redirectRoutes[type]}/${id}/1`);
    } else {
      history.push(`${redirectRoutes[type]}/${id}`);
    }
  };

  const customStyles = {
    placeholder: (provided, state) => ({
      ...provided,
      ppaddingLeft: "0.5rem",
      color: "#008080",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      paddingLeft: "0.5rem",
      color: "#008080",
    }),
    container: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  const handleChangePeergroup = (id: number) => {
    setClearFilter({ clearFilters: false });
    if (type === "PeergroupHeaderSearch") {
      history.push(`${redirectRoutes[type]}/${id}/1`);
      setPeergroupSelectValue(null);
    }
    if (type === "GapPeergroupPageSearch") {
      const strategy = query.get("strategy") ? query.get("strategy") : "";
      const sellerId = query.get("sellerId") ? query.get("sellerId") : "";
      history.push({
        pathname: `${redirectRoutes[type]}/`,
        search: `?peergroupId=${id}&sellerId=${sellerId}&strategy=${strategy}`,
      });
      setPeergroupSelectValue(null);
    }
  };

  return (
    <StyledForm>
      <Flex justify="flex-end">
        {type !== "PeergroupHeaderSearch" &&
        type !== "GapPeergroupPageSearch" ? (
          <Search
            name={"fund"}
            list={autocompleteList}
            label={labels[type]}
            canBeCleared={true}
            handleChange={(value, page) => {
              filterSearchList(value, page);
            }}
            handleSelect={(_, id) => {
              handleSend(id);
            }}
            isListLoading={isListLoading}
          />
        ) : (
          <Select
            styles={customStyles}
            isSearchable={false}
            placeholder={t.peergroup}
            theme={selectTheme}
            value={peergroupSelectValue}
            options={selectDropdown}
            onChange={(value) => {
              setPeergroupSelectValue({
                label: value.label,
                value: value.value,
              });
              handleChangePeergroup(value.value);
            }}
          />
        )}
      </Flex>
    </StyledForm>
  );
};

export default HeaderSearch;
