import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';

// Easy peasy imports

import store from './easy-peasy/store';
import { StoreProvider } from 'easy-peasy';
import styled from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

const CustomApp = styled(App)`
	background-color: red;
`;

ReactDOM.render(
	<StoreProvider store={store}>
		<BrowserRouter>
			<CustomApp />
		</BrowserRouter>
	</StoreProvider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
