import React from 'react';
import styled from 'styled-components';
import ListLegendItemSquareColor from '../ListLegendItemSquareColor/ListLegendItemSquareColor';
import FundListTableItemTitle from '../FundListTableItemTitle/FundListTableItemTitle';
import Theme from '../../themes/MainTheme';

export interface IFundListTableItem {
	type: string;
	title: string;
	coverage: string;
}

const ItemWrapper = styled.div`
	height: 4rem;
	display: flex;
	align-items: center;
`;

const ColorSquare = styled(ListLegendItemSquareColor)`
	height: 100%;
	width: 2rem;
`;

const FundListTableItem: React.FC<IFundListTableItem> = ({
	title,
	coverage,
}) => {
	const handlePrintColorSquare = (coverage: string) => {
		switch (coverage) {
			case 'high-coverage':
				return <ColorSquare bgColor={Theme.color.main} />;
			case 'medium-coverage':
				return <ColorSquare bgColor={Theme.color.lightBlue} />;
			case 'no-coverage':
				return <ColorSquare bgColor={Theme.color.yellow} />;
			default:
				return <ColorSquare bgColor={Theme.color.white} />;
		}
	};
	return (
		<ItemWrapper>
			{handlePrintColorSquare(coverage)}
			<FundListTableItemTitle content={title} />
		</ItemWrapper>
	);
};

export default FundListTableItem;
