import React from 'react';
import styled from 'styled-components';

type positionType = 'absolute' | 'static' | 'fixed';

interface LoaderInterface {
	position?: positionType;
}

const Wrapper = styled.div<{ position?: positionType }>`
	display: inline-block;
	position: ${({ position }) => position};
	top: 50%;
	left: 50%;
	transform: ${({ position }) =>
		position === 'static' ? 'none' : 'translate(-50%, -50%)'};
	width: 40px;
	height: 40px;
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: 32px;
		height: 32px;
		margin: 4px;
		border: 4px solid #fff;
		border-radius: 50%;
		animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-color: #008080 transparent transparent transparent;
	}
	div:nth-child(1) {
		animation-delay: -0.45s;
	}
	div:nth-child(2) {
		animation-delay: -0.3s;
	}
	div:nth-child(3) {
		animation-delay: -0.15s;
	}
	@keyframes lds-ring {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const Loader: React.FC<LoaderInterface> = ({ position = 'fixed' }) => (
	<Wrapper position={position}>
		<div></div>
		<div></div>
		<div></div>
		<div></div>
	</Wrapper>
);

export default Loader;
