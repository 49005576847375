import React, { useState, useEffect, useLayoutEffect } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import { useSetHeader } from "../../hooks/useSetHeader";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import Flex from "../../components/Flex/Flex";
import { t } from "../../translations/en";
import Input from "../../components/Input/Input";
import BasicLink from "../../components/BasicLink/BasicLink";
import { routes } from "../../routes/routes";
import CustomButton from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import PasswordRemindTitle from "../../components/PasswordRemindTitle/PasswordRemindTitle";
import PasswordRemindSubtitle from "../../components/PasswordRemindSubtitle/PasswordRemindSubtitle";
import FormContainer from "../../components/FormContainer/FormContainer";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import { useHistory } from "react-router";
import { FunderService } from "../../services/FunderService";
import WithPagination from "../../components/WithPagination/WithPagination";

const CenteredWrapper = styled.div`
  text-align: center;
`;

const StyledInput = styled(Input)`
  width: 100%;
`;

const PasswordChange: React.FC = () => {
  useSetHeader({ hasHeader: false });
  const history = useHistory();
  const [password, setPassword] = useState<{
    firstPassword: string;
    secondPassword: string;
  }>({
    firstPassword: "",
    secondPassword: "",
  });
  const [queryData, setQueryData] = useState<{ uid: string; token: string }>({
    uid: "",
    token: "",
  });
  const [isformValid, setFormValidity] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const { addToast } = useToasts();

  const minimalPasswordLength = 8;
  const hasOnlyNumbers = (value: string) => /^\d+$/.test(value);

  useLayoutEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uid = urlParams.get("uid");
    const token = urlParams.get("token");
    if (!uid || !token || !uid.length || !token.length) {
      history.push(routes.homepage);
    } else {
      setQueryData({ uid, token });
    }
  }, []);

  const validateForm = () => {
    const { firstPassword, secondPassword } = password;
    if (
      firstPassword === secondPassword &&
      firstPassword.length >= minimalPasswordLength &&
      !hasOnlyNumbers(firstPassword)
    ) {
      setFormValidity(true);
      return;
    }
    setFormValidity(false);
  };

  const updatePassword = (
    e: React.FormEvent<Element>,
    type: "firstPassword" | "secondPassword"
  ) => {
    const target = e.target as HTMLTextAreaElement;
    const { value } = target;
    if (type === "firstPassword") {
      setPassword({
        firstPassword: value,
        secondPassword: password.secondPassword,
      });
    }
    if (type === "secondPassword") {
      setPassword({
        firstPassword: password.firstPassword,
        secondPassword: value,
      });
    }
  };

  useEffect(() => {
    validateForm();
  }, [password]);

  const submitForm = (e: React.FormEvent<Element>) => {
    e.preventDefault();
    const passwordChangeData = {
      uid: queryData.uid,
      token: queryData.token,
      password1: password.firstPassword,
      password2: password.secondPassword,
    };
    if (isformValid) {
      setLoading(true);
      FunderService.postPasswordChange(passwordChangeData)
        .then((response) => {
          const data = JSON.parse(response);
          addToast(t.passwordRemindPage.alertSuccessChangePassword, {
            appearance: "success",
            autoDismiss: true,
          });
          history.push(routes.signIn);
          setLoading(false);
        })
        .catch((error) => {
          addToast(t.passwordRemindPage.alertErrorChangePassword, {
            appearance: "error",
            autoDismiss: true,
          });
          setLoading(false);
          console.log(error);
        });
    }
  };

  return (
    <WithPagination isLoading={isLoading}>
      <MainWrapper>
        <MainContainer>
          <CenteredWrapper>
            <div>
              <PasswordRemindTitle>
                {t.passwordRemindPage.title}
              </PasswordRemindTitle>
              <PasswordRemindSubtitle>
                {t.passwordRemindPage.subtitle.step1}
              </PasswordRemindSubtitle>
            </div>
            <Flex>
              <FormContainer onSubmit={submitForm}>
                <VerticalGrid gap="1rem">
                  <StyledInput
                    placeholder={t.passwordRemindPage.newPassword}
                    type="password"
                    onChange={(e) => updatePassword(e, "firstPassword")}
                  />
                  <StyledInput
                    placeholder={t.passwordRemindPage.repeatPassword}
                    type="password"
                    onChange={(e) => updatePassword(e, "secondPassword")}
                  />
                </VerticalGrid>
                <Flex justify="flex-end" marginTop="2.2rem">
                  <BasicLink colorTheme="light" to={routes.signIn}>
                    {t.passwordRemindPage.back}
                  </BasicLink>
                </Flex>
                <Flex marginTop="1.2rem">
                  <CustomButton
                    content={t.send}
                    size="medium"
                    type="submit"
                    disabled={!isformValid}
                  />
                </Flex>
                <Flex marginTop="2.2rem">
                  <BasicLink colorTheme="light" to={routes.contact}>
                    {t.notRegistered}
                  </BasicLink>
                </Flex>
              </FormContainer>
            </Flex>
          </CenteredWrapper>
        </MainContainer>
      </MainWrapper>
      <Footer type="default" />
    </WithPagination>
  );
};

export default PasswordChange;
