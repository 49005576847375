import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useHistory, useParams } from 'react-router-dom';
import MainContainer from '../../components/MainContainer/MainContainer';
import ListLegendWrapper from '../../components/ListLegendWrapper/ListLegendWrapper';
import { t } from '../../translations/en';
import RecommendedFundListTableWrapper from '../../components/RecommendFundListTableWrapper/RecommendedFundListTableWrapper';
import {
	FunderService,
	ISingleRecommendation,
} from '../../services/FunderService';
import Footer from '../../components/Footer/Footer';
import { routes } from '../../routes/routes';
import { useSetHeader } from '../../hooks/useSetHeader';
import { useStoreActions, useStoreState } from '../../easy-peasy/hooks/hooks';
import RecommendedFundsFilters from '../../components/RecommendedFundsFilters/RecommendedFundsFilters';
import WithPagination from '../../components/WithPagination/WithPagination';
type handlePageChangeType = (page: number, id?: string) => void;
type FilterType = {
	id: string;
	name: string;
	url?: string;
	asset_class?: string;
};

interface IProps {
	id: string;
	page: string;
}

interface IRecommendedFunds extends RouteComponentProps<IProps> {
	// needs to be empty
}
export interface ObjectIndexer<T> {
	[id: string]: T;
}

interface IIsElementSorted extends ObjectIndexer<boolean> {
	fund: boolean;
	category: boolean;
	asset_manager: boolean;
	strategy: boolean;
	short_in_term_performance: boolean;
	long_in_term_performance: boolean;
	rating: boolean;
}

const arraySort = require('array-sort');

const RecommendedFunds: React.FC<IRecommendedFunds> = (
	props: IRecommendedFunds
) => {
	const headerAction = useStoreActions(
		actions => actions.headerData.addHeaderData
	);
	const fundSellerPotentialData = useStoreState(
		state => state.fundSellerPotentialModel.items
	);
	const fundSellerPotentialAction = useStoreActions(
		actions => actions.fundSellerPotentialModel.addIsFundSellerPotential
	);

	useSetHeader({
		title: `${t.recommendedFundsPage.title}`,
	});
	const history = useHistory();
	const params = useParams<{ page: string }>();
	const [previous, setPrevious] = useState<null | string>(null),
		[results, setResults] = useState<Array<ISingleRecommendation> | null>(null),
		[ordering, setOrdering] = useState<string>(''),
		[redirectDestination, setRedirectDestination] = useState<string>(''),
		[pageId, setPageId] = useState<string>(''),
		[pageCount, setPageCount] = useState<number>(0),
		[currentPage, setCurrentPage] = useState<number>(
			parseInt(props.match.params.page)
		),
		[isFirstLoading, setFirstLoading] = useState<boolean>(true),
		[isLoading, setLoading] = useState<boolean>(false),
		[strategiesFilter, setStrategiesFilter] = useState<Array<FilterType>>(),
		[peergroupFilter, setPeergroupFilter] = useState<Array<FilterType>>(),
		[fundSellerFilter, setfundSellerFilter] = useState<Array<FilterType>>(),
		[internalExternalFilter, setInternalExternalFilter] = useState<
			Array<FilterType>
		>(),
		[peergroupId, setPeergroupId] = useState<string>(''),
		[fundSellerId, setFundSellerId] = useState<string | number>(
			fundSellerPotentialData.id
		),
		[strategyValue, setStrategyValue] = useState<string>(''),
		[internalExternalValue, setInternalExternalValue] = useState<string>('');
	const pageSize = 50;

	const internalExternalOptions = [
		{ id: 'n/a', name: 'n/a' },
		{ id: 'Extern', name: 'Extern' },
		{ id: 'Intern', name: 'Intern' },
	];
	// *** UTILS *****************
	const handleChangeResponse = (response: any) => {
		try {
			const data = JSON.parse(response);
			setResults(data.results);
			setPageCount(Math.ceil(data.count / pageSize));
			setPrevious(data.previous);
		} catch {}
		setLoading(false);
	};
	const handlePageChange: handlePageChangeType = currentPage => {
		history.push(`${routes.recommendedFunds}/${currentPage}`);
		setLoading(true);
		setCurrentPage(Number(currentPage));
	};
	const handleSort = (sortBy: string) => {
		if (currentPage !== 1) {
			handlePageChange(1);
		}
		setOrdering(sortBy);
	};
	// ***************************

	// *** FETCH FUNCTIONS *******
	const fetchData = (withScroll?: boolean) => {
		setLoading(true);
		FunderService.getFilteredData(
			currentPage,
			fundSellerId,
			peergroupId,
			strategyValue,
			ordering,
			internalExternalValue
		)
			.then(response => {
				handleChangeResponse(response);
				if (withScroll) {
					window.scrollTo(0, 0);
				}
				setLoading(false);
			})
			.catch(e => {
				setLoading(false);
			});
	};
	const fetchDataWithScroll = () => {
		fetchData(true);
	};
	// ***************************

	// *** MOUNT *****************
	useEffect(() => {
		const destination = `/fund-list`;
		setRedirectDestination(destination);
		if (isFirstLoading) {
			setLoading(true);
			FunderService.getFilteredData(
				params.page,
				fundSellerId,
				peergroupId,
				strategyValue,
				ordering,
				internalExternalValue
			)
				.then(response => {
					handleChangeResponse(response);
					setFirstLoading(false);
				})
				.catch(error => {
					console.log(error);
					history.push(`${routes.recommendedFunds}/1`);
				});

			// Get stratiegies filter options
			FunderService.getStrategiesFilter()
				.then(response => {
					try {
						const data = JSON.parse(response);
						setStrategiesFilter(data);
					} catch {}
				})
				.catch(error => console.log(error));

			// Get peergroups filter options
			FunderService.getPeergroupsFilter()
				.then(response => {
					try {
						const data = JSON.parse(response);
						setPeergroupFilter(data);
					} catch {}
				})
				.catch(error => console.log(error));

			// Get fund sellers filter options
			FunderService.getFundSellers()
				.then(response => {
					try {
						const data = JSON.parse(response);
						setfundSellerFilter(data);
					} catch {}
				})
				.catch(error => console.log(error));

			setInternalExternalFilter(internalExternalOptions);
		}

		return () => {
			headerAction({});
			fundSellerPotentialAction({
				isFundSellerPotential: false,
				id: '',
				label: '',
			});
		};
	}, []);
	// ***************************

	// *** REFRESHING FUNCTIONS***
	useEffect(() => {
		if (!isFirstLoading) {
			if (currentPage !== 1) {
				handlePageChange(1);
			}
			fetchData();
		}
	}, [
		fundSellerId,
		peergroupId,
		strategyValue,
		ordering,
		internalExternalValue,
	]);

	useEffect(() => {
		if (!isFirstLoading) {
			setLoading(true);
			setCurrentPage(Number(params.page));
			fetchDataWithScroll();
		}
	}, [params]);
	// ***************************

	return (
		<WithPagination isLoading={isLoading}>
			<MainContainer>
				<ListLegendWrapper
					destination={redirectDestination}
					isPotential={true}
					content={t.recommendedFundsPage.buttonContent}
				/>
				<RecommendedFundsFilters
					strategies={strategiesFilter}
					peergroups={peergroupFilter}
					fundSellers={fundSellerFilter}
					internalExternal={internalExternalFilter}
					handleFilterResults={fetchData}
					setFundSellerId={setFundSellerId}
					setPeergroupId={setPeergroupId}
					setStrategyValue={setStrategyValue}
					setInternalExternalValue={setInternalExternalValue}
					isClearable
				/>
				<RecommendedFundListTableWrapper
					ifRequestLink={false}
					id={props.match.params.id}
					pagesAmount={pageCount}
					currentPage={currentPage}
					handlePageChange={handlePageChange}
					handleSort={handleSort}
					data={results}
					isLoading={isLoading}
					doRedirect={true}
				/>
				<Footer type={'logoOnly'} />
			</MainContainer>
		</WithPagination>
	);
};

export default RecommendedFunds;
