import React, { useEffect, useState } from "react";
import HeaderTitle from "../HeaderTitle/HeaderTitle";
import InformationBar from "../InformationBar/InformationBar";
import { useStoreState } from "../../easy-peasy/hooks/hooks";
import { FunderService } from "../../services/FunderService";

interface HeaderInterface {
  isStaticPage?: boolean;
}

type CustomDataType = {
  title?: string;
  titleName?: string;
  subtitle?: string;
  hasSubtitle?: boolean;
  hasHeader?: boolean;
  additionalSubtitle?: string;
};

const initialData = {
  title: undefined,
  subtitle: undefined,
  hasSubtitle: false,
  hasHeader: false,
};

const Header: React.FC<HeaderInterface> = ({ isStaticPage }) => {
  const [customData, setCustomData] = useState<CustomDataType>(initialData);
  const [date, setDate] = useState<string>("");
  const [isImportData, setIsImportData] = useState<boolean>(false);

  const defaultTitle = customData.title;
  const defaultSubtitle = customData.subtitle;

  const headerData = useStoreState((state) => state.headerData.items);
  const verified = useStoreState((state) => state.verifiedModel.items.verified);

  useEffect(() => {
    setCustomData(headerData);
  }, [headerData]);

  useEffect(() => {
    if (verified) {
      FunderService.getLastImportDate()
        .then((response) => {
          const splittedDate = response
            .replace('"', "")
            .replace('"', "")
            .split("-");
          const date = new Date(
            Number(splittedDate[0]),
            Number(splittedDate[1]) - 1,
            Number(splittedDate[2])
          );
          const options = { year: "numeric", month: "long" };
          const formattedDate = new Intl.DateTimeFormat(
            "de-DE",
            options
          ).format(date);
          setDate(formattedDate);
          setIsImportData(true);
        })
        .catch((error) => console.log(error));
    }
  }, [verified]);

  return (
    <>
      <HeaderTitle
        isStaticPage={
          headerData.isStaticPage !== undefined ? headerData.isStaticPage : null
        }
      />
      {customData.hasHeader && customData.title && (
        <InformationBar
          searchComponent={headerData.searchComponent}
          title={customData.title ? customData.title : defaultTitle}
          titleName={customData.titleName ? customData.titleName : ""}
          isStaticPage={headerData.isStaticPage}
          isImportData={isImportData}
          subtitle={headerData.subtitle}
          additionalSubtitle={customData.additionalSubtitle}
          content={
            customData.subtitle
              ? customData.subtitle
                ? customData.subtitle
                : defaultSubtitle
              : date
          }
        />
      )}
    </>
  );
};

export default Header;
