import React, { useState } from "react";
import styled from "styled-components";
import { t } from "../../translations/en";
import FooterLogo from "../FooterLogo/FooterLogo";
import Flex from "../Flex/Flex";
import FooterLinks from "../FooterLinks/FooterLinks";
import InformationList from "../InformationList/InformationList";
import { footerType } from "../../types/footerTypes";
import { useGetUserAccountType } from "../../hooks/useGetUserAccountType";

interface IFooter {
  type: footerType;
  className?: string;
}

const FooterWrapper = styled("footer")<{ type: footerType }>`
  background: ${({ theme }) => theme.color.white};
  display: flex;
  align-items: ${({ type }) => (type === "default" ? "center" : "flex-start")};
  justify-content: ${({ type }) =>
    type === "default" || type === "withList" ? "space-between" : "flex-end"};
  margin: 2.2rem auto 0 auto;
  max-width: 164rem;
  padding: 0 1.6rem 0 3.2rem;
`;

const WithListWrapper = styled.div`
  width: 100%;
  margin-top: 3.5rem;
`;

const StyledInformationList = styled(InformationList)`
  max-width: 52rem;
`;

const WhiteSpace = styled.div`
  height: 7rem;
`;

const ACCOUNT_TYPE = {
  SELLER: "seller",
  DEFAULT: "default",
  MANAGER: "manager",
};

const Footer: React.FC<IFooter> = ({ type, className }) => {
  const [accountType, setAccountType] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);

  type === "withList" ? useGetUserAccountType(setAccountType, setUserId) : null;

  return (
    <FooterWrapper className={className} type={type}>
      {type === "withList" ? (
        <>
          <WithListWrapper>
            <Flex justify="space-between" align="flex-start">
              <StyledInformationList
                colorTheme="dark"
                list={
                  accountType === ACCOUNT_TYPE.SELLER
                    ? t.footer.list.seller
                    : t.footer.list.default
                }
              />
              <FooterLinks type={type} />
            </Flex>
          </WithListWrapper>
          <FooterLogo />
        </>
      ) : (
        <WhiteSpace />
      )}
    </FooterWrapper>
  );
};
export default Footer;
