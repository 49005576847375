const Theme = {
  color: {
    gray: "#a3aaaa",
    darkGray: "#949494",
    darkerGray: "#656D6D",
    textGray: "#9c9c9c",
    chartGray: "#c0c0c0",
    lighterGray: "rgba(229, 229, 229, 0.5)",
    white: "#fff",
    transparentWhite: "rgba(255, 255, 255, 0.1)",
    transparentWhiteActive: "rgba(255, 255, 255, 0.3)",
    black: "#202529",
    lightblack: "#242A30",
    main: "#008080",
    mainLight: "#B3D9D9",
    mainTransparent: "rgba(0, 144, 144, 0.3)",
    mainTransparentActive: "rgba(0, 144, 144, 0.5)",
    error: "#D85B5F",
    warning: "#FFBD2E",
    success: "#28CA42",
    yellow: "#F1E72C",
    yellowLight: "#FFFECD",
    lightBlue: "#7FBFBF",
    darkBlue: "#003781",
    lightGreen: "#c2e1e0",
    highlight: "rgba(255, 254, 205, 0.4)",
    transparent: "transparent",
  },
  font: {
    weight: {
      light: 300,
      regular: 400,
      medium: 600,
      bold: 700,
      extraBold: 900,
    },
    size: {
      title_XXS: "1.4rem",
      title_XS: "1.8rem",
      title_S: "2rem",
      title_M: "2.5rem",
      title_L: "3.5rem",
      title_XL: "4rem",
      title_XXL: "5rem",
      text_XXS: "1rem",
      text_XS: "1.2rem",
      text_S: "1.4rem",
      text_M: "1.4rem",
      text_L: "2rem",
      text_XL: "2.2rem",
      smallButton: "1.4rem",
      button: "2.2rem",
      table_header: "1.6rem",
      big_text: "1.6rem",
      table_item: "1.4rem",
      table_item_big: "1.8rem",
      text_30: "3rem",
      text_35: "3.5rem",
    },
    lineHeight: {
      title_XXS: "2.4rem",
      title_XS: "3rem",
      title_S: "3.2rem",
      title_M: "3.6rem",
      title_L: "5.2rem",
      title_XL: "4.9rem",
      title_XXL: "7rem",
      text_XS: "1.8rem",
      text_S: "1.9rem",
      text_M: "2.4rem",
      text_L: "3rem",
      text_XL: "3rem",
      smallButton: "2.6rem",
      button: "3.3rem",
      table_header: "2rem",
      table_item: "1.8rem",
      text_45: "4.5rem",
      text_50: "5rem",
    },
    text: "Poppins, sans-serif",
    title: "Poppins, sans-serif",
    icon: "icomoon",
  },
  theme: {
    dark: "dark",
    light: "light",
    main: "main",
  },
  padding: {
    main: "0 1.6rem",
  },
};

export default Theme;
