import React, { useEffect, useState } from "react";
import { FunderService } from "./../services/FunderService";

export const useGetUserAccountType = (setAccountType, setUserId) => {
  const ACCOUNT_TYPE = {
    SELLER: "seller",
    DEFAULT: "default",
    MANAGER: "manager",
  };

  useEffect(() => {
    FunderService
      .getUserAccountInfo()
      .then((response) => {
        const data = JSON.parse(response);
        if (data.seller) {
          setAccountType(ACCOUNT_TYPE.SELLER);
          setUserId(data.seller.id);
          return;
        }
        if (data.company && data.company.asset_manager) {
          setAccountType(ACCOUNT_TYPE.MANAGER);
          setUserId(data.company.asset_manager.id);
          return;
        }
        setAccountType(ACCOUNT_TYPE.DEFAULT);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
};
