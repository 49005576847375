import styled from 'styled-components';
import Title from '../Title/Title';

const TabContainer = styled.div`
	background: ${({ theme }) => theme.color.lighterGray};
	min-height: 7.3rem;
	padding: 0.7rem 0;
	align-items: center;
	display: grid;

	${Title} {
		font-size: ${({ theme }) => theme.font.size.table_header};
		line-height: ${({ theme }) => theme.font.lineHeight.table_header};
		padding: 0 1.5rem;
	}
`;

export default TabContainer;
