import React from 'react';
import styled from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
import HorizontalGrid from '../../components/HorizontalGrid/HorizontalGrid';
import { t } from '../../translations/en';
import { replaceWhitespacesWithNbsp } from '../../utils/Strings';
import Title from '../../components/Title/Title';
import PositiveChart from '../../components/Chart/PositiveChart';
import Chart from '../../components/Chart/Chart';
import Footer from '../../components/Footer/Footer';
import { useSetHeader } from '../../hooks/useSetHeader';
import { FunderService } from '../../services/FunderService';
import Tooltip from '../../components/Tooltip/Tooltip';
import InformationMessage from '../../components/InformationMessage/InformationMessage';
import Loader from '../../components/Loader/Loader';
import { Link } from 'react-router-dom';
import { routes } from '../../routes/routes';

type DataType = {
	id: number;
	fund: string;
	recommendations: number;
	separatedRecommendations: string;
	peergroup: string;
	netFlows: number;
	separatedNetFlows: string;
	aum: number;
	separatedAum: string;
};

type DataKeys = keyof DataType;

const StyledHorizontalGrid = styled(HorizontalGrid)`
	padding-bottom: 2rem;
`;

const StyledTitle = styled(Title)`
	font-size: 1.6rem;
	span {
		display: block;
		font-weight: ${({ theme }) => theme.font.weight.regular};
	}
`;

const StyledLink = styled(Link)`
	:hover {
		text-decoration: none;
		color: ${({ theme }) => theme.color.main};
	}
`;

const Wrapper = styled.div<{ color: string; center?: boolean }>`
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	& > div {
		width: 100%;
	}
	:before {
		content: '';
		position: absolute;
		left: ${({ center }) => (center ? '50%' : '0')};
		top: 50%;
		transform: ${({ center }) =>
			center ? 'translate(-50%, -50%)' : 'translateY(-50%)'};
		height: 170%;
		width: 2px;
		background: ${({ theme, color }) => theme.color[color]};
	}
`;

const TableItem = styled.div<{ align?: 'right' | 'center' | 'left' }>`
	padding: 1rem 0;
	text-align: ${({ align }) => (align ? align : 'left')};
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
`;

const RecommendationChart: React.FC = () => {
	const [
		recommendationsBiggestValue,
		setRecommendationsBiggestValue,
	] = React.useState<number>(0);
	const [data, setData] = React.useState<Array<DataType>>(null);
	const [aumBiggestValue, setAumBiggestValue] = React.useState<number>(0);
	const [isLoading, setLoading] = React.useState<boolean>(true);
	const [netFlowsBiggestValue, setNetFlowsBiggestValue] = React.useState<
		number
	>(0);
	useSetHeader({
		title: t.recommendationChartPage.title,
	});

	const calculateBiggestValue = (valueArray: Array<any>) => {
		const sortedArray = valueArray.sort((a, b) => b - a);
		return sortedArray[0];
	};

	const checkBiggestValue = (type: DataKeys) => {
		const valueArray = data
			.map(item => item[type])
			.map(item => Math.abs(parseInt(String(item))));
		return calculateBiggestValue(valueArray);
	};

	const getSeparatedValue = (value: number | string) => {
		if (typeof value === 'number') {
			value = String(value);
		}
		return value.split('.')[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
	};

	React.useLayoutEffect(() => {
		FunderService.getFundsWithTheMostRecommendations()
			.then(response => {
				const data = JSON.parse(response);
				const sortedResponse: Array<DataType> = data.map(item => {
					return {
						id: item.id,
						fund: item.share_class.name,
						recommendations: Number(item.recommendation_count),
						separatedRecommendations: getSeparatedValue(
							item.recommendation_count
						),
						peergroup: item.peergroup.name,
						netFlows: parseInt(item.million_net_flow_for_last_3_months),
						separatedNetFlows: getSeparatedValue(
							item.million_net_flow_for_last_3_months
						),
						aum: parseInt(item.million_fund_aum_for_last_month),
						separatedAum: getSeparatedValue(
							item.million_fund_aum_for_last_month
						),
					};
				});
				setData(sortedResponse);
				setLoading(false);
			})
			.catch(error => {
				setLoading(false);
				console.log(error);
			});
	}, []);

	React.useEffect(() => {
		if (data) {
			setRecommendationsBiggestValue(checkBiggestValue('recommendations'));
			setNetFlowsBiggestValue(checkBiggestValue('netFlows'));
			setAumBiggestValue(checkBiggestValue('aum'));
		}
	}, [data]);

	return (
		<>
			<MainContainer marginTop={'4.4rem'}>
				{isLoading ? (
					<Loader />
				) : (
					<>
						{data ? (
							<>
								<StyledHorizontalGrid
									columnPattern={'repeat(4, 3fr)'}
									align='flex-end'
									gap={'2rem'}
								>
									<StyledTitle colorTheme='dark'>
										{t.recommendationChartPage.fund}
									</StyledTitle>
									<StyledTitle colorTheme='dark'>
										{t.recommendationChartPage.position}
									</StyledTitle>
									<StyledTitle colorTheme='dark'>
										{t.recommendationChartPage.netFlows}{' '}
										<span>
											{replaceWhitespacesWithNbsp(
												t.recommendationChartPage.eurMio
											)}
										</span>
									</StyledTitle>
									<StyledTitle colorTheme='dark'>
										{t.recommendationChartPage.aum}{' '}
										<span>
											{replaceWhitespacesWithNbsp(
												t.recommendationChartPage.eurMio
											)}
										</span>
									</StyledTitle>
								</StyledHorizontalGrid>
								<HorizontalGrid
									columnPattern={'repeat(4, 3fr)'}
									align='center'
									gap={'2rem'}
								>
									{data
										? data.map(
												(
													{
														id,
														fund,
														recommendations,
														separatedRecommendations,
														peergroup,
														netFlows,
														separatedNetFlows,
														aum,
														separatedAum,
													}: DataType,
													index
												) => {
													const tooltipValue = `${t.peergroup}: ${peergroup} ${
														fund.length > 37 ? `<br>${t.fund}: ${fund}` : ''
													}`;
													return (
														<React.Fragment key={index}>
															<Tooltip
																tooltip={tooltipValue}
																align='right'
																textAlign='left'
																size='small'
															>
																<StyledLink to={`${routes.fund}/${id}`}>
																	<TableItem align='right'>
																		{fund.length > 37
																			? `${fund.slice(0, 36)}...`
																			: fund}
																	</TableItem>
																</StyledLink>
															</Tooltip>
															<Wrapper color='main'>
																<div>
																	<PositiveChart
																		data={recommendations}
																		separatedData={separatedRecommendations}
																		biggestValue={recommendationsBiggestValue}
																		color='main'
																	/>
																</div>
															</Wrapper>
															<Wrapper center color='lightBlue'>
																<div>
																	<TableItem>
																		<Chart
																			data={netFlows}
																			separatedData={separatedNetFlows}
																			biggestValue={netFlowsBiggestValue}
																			color='lightBlue'
																		/>
																	</TableItem>
																</div>
															</Wrapper>
															<Wrapper color='chartGray'>
																<div>
																	<TableItem>
																		<PositiveChart
																			data={aum}
																			separatedData={separatedAum}
																			biggestValue={aumBiggestValue}
																			color='chartGray'
																		/>
																	</TableItem>
																</div>
															</Wrapper>
														</React.Fragment>
													);
												}
										  )
										: null}
								</HorizontalGrid>
							</>
						) : (
							<InformationMessage />
						)}
					</>
				)}
			</MainContainer>
			<Footer type='logoOnly' />
		</>
	);
};

export default RecommendationChart;
