import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import ColorContainer from '../../components/ListLegendItemSquareColor/ListLegendItemSquareColor';
import Theme from '../../themes/MainTheme';
import { useHistory } from 'react-router-dom';
import { t } from '../../translations/en';
import InformationMessage from '../InformationMessage/InformationMessage';
import { routes } from '../../routes/routes';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';

const CustomContainer = styled.div`
	padding: 0;
`;

const Wrapper = styled.div<{ doRedirect: boolean }>`
	${({ doRedirect }) =>
		doRedirect &&
		css`
			cursor: pointer;
		`}
`;

const CustomRow = styled.div`
	margin: 0;
	min-height: 4rem;
	height: auto;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
	margin-top: 0.8rem;
	padding: 0 2rem 0 0;
`;

const CustomCol = styled.div`
	min-height: 4rem;
	height: 100%;
	background: ${({ theme }) => theme.color.white};
	font-size: ${({ theme }) => theme.font.size.table_item};
	line-height: ${({ theme }) => theme.font.lineHeight.table_item};
	font-weight: ${({ theme }) => theme.font.weight.regular};
	align-items: center;
	display: flex;
	word-break: break-word;
`;

const CenterCustomCol = styled(CustomCol)`
	text-align: center;
	justify-content: center;
	word-break: break-word;
`;

const FundCol = styled.div`
	padding: 0;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	word-break: break-word;
`;

const CustomColorContainer = styled(ColorContainer)`
	height: 100%;
	min-width: 3rem;
`;

const CustomFundContent = styled.p`
	padding-left: 0rem;
	font-weight: ${({ theme }) => theme.font.weight.medium};
	font-size: ${({ theme }) => theme.font.size.table_item};
	line-height: ${({ theme }) => theme.font.lineHeight.table_item};
`;

const PlusPerformanceIcon = styled.span`
	font-size: ${({ theme }) => theme.font.size.table_item};
`;

const LinkCol = styled(CustomCol)`
	box-shadow: -1px 0px 0 0 rgba(0, 0, 0, 0.1);
	line-height: ${({ theme }) => theme.font.lineHeight.text_XS};
	font-size: ${({ theme }) => theme.font.size.text_XXS};
	font-weight: ${({ theme }) => theme.font.weight.medium};
	color: ${({ theme }) => theme.color.white};
	background: ${({ theme }) => theme.color.main};
	text-align: center;
	padding: 0;
	word-break: break-word;
	&:hover {
		cursor: pointer;
	}
`;

const CustomStrategyCol = styled(CustomCol)`
	justify-content: center;
`;

const LastHeaderCol = styled(CenterCustomCol)``;

interface IRecommendedFundListTableItemWrapper {
	data: Array<any> | null;
	ifRequestLink?: boolean;
	doRedirect: boolean;
	deleteExternalInternal?: boolean;
	isPeergroupPage?: boolean;
	isAlternativesPage?: boolean;
	currentRecommendations?: boolean;
}

function getPeergroupName(peergroup) {
	return peergroup ? peergroup.name : 'n/a';
}

function getAssetManagerName(assetManager) {
	return assetManager ? assetManager.name : 'n/a';
}

function getKategorie(fund) {
	return fund.kategorie ? fund.kategorie : 'n/a';
}

const RecommendedFundListTableItemWrapper: React.FC<IRecommendedFundListTableItemWrapper> = ({
	data,
	ifRequestLink = false,
	doRedirect,
	isPeergroupPage = false,
	isAlternativesPage = false,
	currentRecommendations,
}) => {
	const history = useHistory();

	const [funds, setFunds] = useState<Array<any> | null>(null);
	useEffect(() => {
		setFunds(data);
	}, [data]);

	const handlePrintPotentialColor = (potential: string) => {
		switch (`${potential}`) {
			case '3':
				return <CustomColorContainer bgColor={Theme.color.main} />;
			case '2':
				return <CustomColorContainer bgColor={Theme.color.lightBlue} />;
			case '1':
				return <CustomColorContainer bgColor={Theme.color.yellow} />;
			default:
				return <CustomColorContainer bgColor={Theme.color.white} />;
		}
	};
	return (
		<CustomContainer>
			{funds ? (
				funds.map((item: any) => {
					const { fund, seller, external_internal } = item;
					return (
						<Wrapper doRedirect={doRedirect} key={item.id}>
							<CustomRow>
								{isPeergroupPage ? (
									<HorizontalGrid
										columnPattern={'2fr 1.5fr repeat(4, 1fr) repeat(3, 0.5fr)'}
										gap='1.5rem'
										onClick={() => {
											if (doRedirect) {
												history.push(`${routes.fund}/${item.fund.id}`);
											}
										}}
									>
										<FundCol>
											{handlePrintPotentialColor(fund.potential)}
											<CustomFundContent>
												{fund.share_class.name}
											</CustomFundContent>
										</FundCol>
										<CustomCol>{getPeergroupName(fund.peergroup)}</CustomCol>
										<CustomCol>{getKategorie(fund)}</CustomCol>
										<CustomCol>
											{getAssetManagerName(fund.asset_manager)}
										</CustomCol>
										<CustomStrategyCol>{fund.strategy}</CustomStrategyCol>
										{currentRecommendations ? (
											<CustomCol>{seller.name}</CustomCol>
										) : (
											<CenterCustomCol>
												<CustomCol>{fund.number_of_recommendations}</CustomCol>
											</CenterCustomCol>
										)}
										<CenterCustomCol>
											<PlusPerformanceIcon>
												{fund.short_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<CenterCustomCol>
											<PlusPerformanceIcon>
												{fund.long_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<LastHeaderCol>
											{fund.morningstar_rating_overall}
										</LastHeaderCol>
										{ifRequestLink ? (
											<LinkCol
												onClick={(e: React.MouseEvent) => {
													e.stopPropagation();
													history.push(routes.requestCompetitorAnalysis);
												}}
											>
												{t.peergrupDetails.requestCompetitorAnalysis}
											</LinkCol>
										) : null}
									</HorizontalGrid>
								) : isAlternativesPage ? (
									<HorizontalGrid
										columnPattern={'2fr 1.5fr repeat(4, 1fr) repeat(3, 0.5fr)'}
										gap='1.5rem'
										onClick={() => {
											if (doRedirect) {
												history.push(`${routes.fund}/${item.id}`);
											}
										}}
									>
										<FundCol>
											{handlePrintPotentialColor(item.potential)}
											<CustomFundContent>
												{item.share_class.name}
											</CustomFundContent>
										</FundCol>
										<CustomCol>{getPeergroupName(item.peergroup)}</CustomCol>
										<CustomCol>{getKategorie(item)}</CustomCol>
										<CustomCol>
											{getAssetManagerName(item.asset_manager)}
										</CustomCol>
										<CenterCustomCol>{item.strategy}</CenterCustomCol>

										<CenterCustomCol>
											<CustomCol>{item.number_of_recommendations}</CustomCol>
										</CenterCustomCol>

										<CenterCustomCol>
											<PlusPerformanceIcon>
												{item.short_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<CenterCustomCol>
											<PlusPerformanceIcon>
												{item.long_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<LastHeaderCol>
											{item.morningstar_rating_overall}
										</LastHeaderCol>
									</HorizontalGrid>
								) : (
									<HorizontalGrid
										columnPattern={
											'2.6fr repeat(4, 2fr) repeat(2, 1fr) repeat(3, 0.5fr)'
										}
										gap='2rem'
										onClick={() => {
											if (doRedirect) {
												history.push(`${routes.fund}/${item.fund.id}`);
											}
										}}
									>
										<FundCol>
											{handlePrintPotentialColor(fund.potential)}
											<CustomFundContent>{seller.name}</CustomFundContent>
										</FundCol>
										<CustomCol>{fund.share_class.name}</CustomCol>
										<CustomCol>{getPeergroupName(fund.peergroup)}</CustomCol>
										<CustomCol>{getKategorie(fund)}</CustomCol>
										<CustomCol>
											{getAssetManagerName(fund.asset_manager)}
										</CustomCol>
										<CustomCol>{fund.strategy}</CustomCol>

										<CenterCustomCol>{external_internal}</CenterCustomCol>
										<CenterCustomCol>
											<PlusPerformanceIcon>
												{fund.short_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<CenterCustomCol>
											<PlusPerformanceIcon>
												{fund.long_term_performance}
											</PlusPerformanceIcon>
										</CenterCustomCol>
										<CenterCustomCol>
											{fund.morningstar_rating_overall}
										</CenterCustomCol>
										{ifRequestLink ? (
											<LinkCol
												onClick={(e: React.MouseEvent) => {
													e.stopPropagation();
													history.push(routes.requestCompetitorAnalysis);
												}}
											>
												{t.peergrupDetails.requestCompetitorAnalysis}
											</LinkCol>
										) : null}
									</HorizontalGrid>
								)}
							</CustomRow>
						</Wrapper>
					);
				})
			) : (
				<InformationMessage />
			)}
		</CustomContainer>
	);
};

export default RecommendedFundListTableItemWrapper;
