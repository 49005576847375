import React from 'react';
import styled from 'styled-components';
import TableHeader from '../TableHeader/TableHeader';
import FundListTableItem from '../FundListTableItem/FundListTableItem';
import MoreOptionsComponent from '../MoreOptionsComponent/MoreOptionsComponent';
import { IAttributes } from '../FundListTableWrapper/FundListTableWrapper';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { useStoreActions } from '../../easy-peasy/hooks/hooks';

interface IFundListTableColumn {
	title: string;
	data: Array<IData>;
	id?: string | number;
	sellerName: string;
}

interface IData {
	type: 'string';
	attributes: IAttributes;
}

const TableColumn = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-content: flex-start;
	width: 19.5%;
`;

const MappedComponentWrapper = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: space-between;
	width: 100%;
	border: 1px solid ${({ theme }) => theme.color.lighterGray};
	margin-top: 0.8rem;
	box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);

	&:hover {
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
		cursor: pointer;
	}
`;

const StyledLink = styled.div`
	display: block;
	width: 100%;
`;

const MoreButtonLink = styled.span`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FundListTableColumn: React.FC<IFundListTableColumn> = ({
	data,
	title,
	id,
	sellerName,
}) => {
	const history = useHistory();

	return (
		<TableColumn>
			<TableHeader title={title} />
			{data.map((item: any) => {
				return (
					<StyledLink
						key={'table-item' + item.attributes.title}
						onClick={() => {
							history.push({
								pathname: `${routes.gapPeergroupPage}/`,
								search: `?peergroupId=${item.id}&sellerId=${id}`,
							});
						}}
					>
						<MappedComponentWrapper>
							<FundListTableItem
								type={item.type}
								title={item.attributes.title}
								coverage={item.attributes.coverage}
							/>
							<MoreButtonLink>
								<MoreOptionsComponent />
							</MoreButtonLink>
						</MappedComponentWrapper>
					</StyledLink>
				);
			})}
		</TableColumn>
	);
};

export default FundListTableColumn;
