import React, { useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Text from '../Text/Text';
import OptionsList from '../OptionList/OptionList';
import OptionItem from '../OptionItem/OptionItem';

const SelectWrapper = styled.div`
	position: relative;
	width: 100%;
`;

const SelectedOption = styled.div<{
	filterType?: boolean;
	colorTheme?: 'dark';
}>`
	border: 0.1rem solid ${({ theme }) => theme.color.white};
	padding: 1.6rem 3rem;
	font-size: ${({ theme }) => theme.font.size.text_XS};
	background: ${({ theme }) => theme.color.white};
	color: ${({ theme }) => theme.color.main};
	font-weight: ${({ theme }) => theme.font.weight.bold};
	position: relative;
	width: 100%;
	user-select: none;
	transition: background 0.3s ease-in-out;

	&.active {
		:after {
			transform: translateY(-50%) rotate(180deg);
		}
		& ~ ${OptionsList} {
			display: block;
		}
	}

	:after {
		content: '\\e800';
		font-family: ${({ theme }) => theme.font.icon};
		position: absolute;
		right: 2rem;
		top: 50%;
		font-size: 0.6rem;
		transform: translateY(-50%);
	}
	:hover {
		cursor: pointer;
	}

	${({ filterType }) =>
		filterType &&
		css`
			max-height: 41px;
			height: 41px;
			display: flex;
			align-items: center;
			border: none;
			border-bottom: 1px solid ${({ theme }) => theme.color.black};
			color: ${({ theme }) => theme.color.black};
			padding: 0.8rem 1.7rem 0.8rem 0;
			& ~ ${OptionsList} {
				border: 1px solid ${({ theme }) => theme.color.black};
				width: max-content;
				min-width: 100%;
			}

			& ~ ${OptionsList} ${OptionItem} {
				padding: 0 1rem;
				${Text} {
					font-size: ${({ theme }) => theme.font.size.text_XS};
				}
			}

			:after {
				right: 0;
			}
		`}

	${({ colorTheme }) =>
		colorTheme === 'dark' &&
		css`
			display: flex;
			align-items: center;
			background: ${({ theme }) => theme.color.mainLight};
			color: ${({ theme }) => theme.color.black};
			font-weight: ${({ theme }) => theme.font.weight.regular};
			& ~ ${OptionsList} {
				border: 1px solid ${({ theme }) => theme.color.black};
				width: max-content;
				min-width: 100%;
			}
		`}
`;

const RemoveButton = styled.div`
	position: absolute;
	right: 0.9rem;
	top: 50%;
	transform: translateY(-50%);
	z-index: 5;
	height: 4rem;
	width: 2rem;
	line-height: 4rem;
	text-align: center;
	vertical-align: middle;

	:before {
		content: '\\e80a';
		font-family: ${({ theme }) => theme.font.icon};
		font-size: 0.8rem;
		position: absolute;
		right: 0.1rem;
		height: 100%;
		top: 2%;
		width: 2rem;
		z-index: 3;
	}

	&:hover {
		cursor: pointer;
	}
`;

type handleChangeType = { value: string; label: string };

interface SelectConfig {
	label: string;
	options: Array<object>;
	handleChange: (data: handleChangeType) => void;
	filterType?: boolean;
	colorTheme?: 'dark';
	isClearable?: boolean;
}

type toggleSelectType = (e: React.MouseEvent<HTMLDivElement>) => void;
type toggleClassType = (
	el: HTMLTextAreaElement | HTMLDivElement | null,
	actionValue: 'add' | 'remove' | 'toggle',
	className: string
) => void;
type selectOptionType = (value: string, label: string) => void;
type hideOptionsListType = (target: EventTarget | null) => void;

const toggleClass: toggleClassType = (el, actionValue, className) => {
	if (el) {
		if (actionValue === 'toggle') {
			el.classList.toggle(className);
		} else if (actionValue === 'remove') {
			el.classList.remove(className);
		} else if (actionValue === 'add') {
			el.classList.add(className);
		}
	}
};

const Select: React.FC<SelectConfig> = ({
	label,
	handleChange,
	options,
	filterType,
	isClearable,
	colorTheme,
}) => {
	const [currentOption, setCurrentOption] = useState<string>(label);
	const [currentValue, setCurrentValue] = useState<string>();
	const selectRef = useRef<HTMLDivElement>(null);
	const optionsRef = useRef<HTMLDivElement>(null);

	const hideOptionsList: hideOptionsListType = (target) => {
		const htmlTarget = target as HTMLTextAreaElement;
		if (
			target &&
			target !== optionsRef.current &&
			htmlTarget.parentNode !== optionsRef.current &&
			target !== selectRef.current
		) {
			toggleClass(selectRef.current, 'remove', 'active');
		}
	};

	const toggleSelect: toggleSelectType = (e) => {
		const target = e.target as HTMLTextAreaElement;
		toggleClass(target, 'toggle', 'active');
		document.addEventListener('mousedown', (e) => hideOptionsList(e.target));
	};

	const selectOption: selectOptionType = (itemValue, itemLabel) => {
		setCurrentOption(`${itemLabel}`);
		setCurrentValue(`${itemValue}`);
		handleChange({ value: itemValue, label: itemLabel });
		toggleClass(selectRef.current, 'remove', 'active');
	};

	const handleClearFilter = () => {
		selectOption(options[0]['value'], options[0]['label']);
	};

	return (
		<SelectWrapper>
			<SelectedOption
				colorTheme={colorTheme}
				ref={selectRef}
				onClick={toggleSelect}
				filterType={filterType}
			>
				{currentOption}
				{isClearable && currentValue !== '' && currentValue !== undefined ? (
					<RemoveButton onClick={handleClearFilter} />
				) : null}
			</SelectedOption>
			<OptionsList ref={optionsRef}>
				{options &&
					options.map((item: any, index) => (
						<OptionItem
							key={index}
							onClick={() => selectOption(item.value, item.label)}
							className={currentOption === `${item.label}` ? 'selected' : ''}
						>
							<Text>{item.label}</Text>
						</OptionItem>
					))}
			</OptionsList>
		</SelectWrapper>
	);
};

export default Select;
