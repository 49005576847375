import React from 'react';
import styled from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
import { useSetHeader } from '../../hooks/useSetHeader';
import { Container as BContainer, Row, Col } from 'react-bootstrap';
import LandingPageTitle from '../../components/LandingPageTitle/LandingPageTitle';
import LandingPageSubtitle from '../../components/LandingPageSubtitle/LandingPageSubtitle';
import LandingPageDivider from '../../components/LandingPageDivider/LandingPageDivider';
import { t } from '../../translations/en';
import LadningPageDescription from '../../components/LandingPageDescription/LandingPageDescription';
import Button from '../../components/Button/Button';
import BackgroundImage from '../../images/background-image.jpg';
import BigBackgroundImage from '../../images/background-image_imac.jpg';
import { useSetModal } from '../../hooks/useSetModal';

const Container = styled(BContainer)`
	// margin-top: 10rem;
	padding-bottom: 2rem;
	margin-top: calc(40vh - 100px);
	@media screen and (min-width: 1024px) {
		padding: 0 12rem;
	}
	@media screen and (min-height: 600px) and (min-width: 400px) {
		margin-top: calc(55vh - 50px);
	}
`;

const Wrapper = styled.div`
	max-width: 90rem;
`;

const Background = styled.div`
	position: fixed;
	height: 100vh;
	width: 100vw;
	left: 0;
	top: 0;
	@media (max-width: 1980px) { 
		background: url('${BackgroundImage}') center/cover no-repeat;
	  }
	@media (min-width: 1980px) {
		background: url('${BigBackgroundImage}') center/cover no-repeat;

	}
`;

const LandingView: React.FC = () => {
	useSetHeader({ isStaticPage: true, hasHeader: false });
	const setModal = useSetModal();
	const handleTriggerModal = () => {
		setModal({ visible: true, title: t.login, component: 'LoginModal' });
	};

	return (
		<>
			<Background />
			<MainContainer>
				<Container fluid={true}>
					<Wrapper>
						<Row>
							<Col md={12}>
								<LandingPageTitle
									title1={t.landingPage.newTitle.newTitle1}
								/>
								<LandingPageSubtitle
									subtitle1={t.landingPage.newSubtitle.newSubtitle1}
									subtitle2={t.landingPage.newSubtitle.newSubtitle2}
								/>
								<Button
									colorTheme={'dark'}
									size={'medium'}
									content={t.login}
									onClick={handleTriggerModal}
								/>
							</Col>
						</Row>
					</Wrapper>
				</Container>
			</MainContainer>
		</>
	);
};

export default LandingView;
