import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import FundListTableColumn from '../FundListTableColumn/FundListTableColumn';
import { t } from '../../translations/en';
import mockedData from './FundListData.json';
import { IGetGapAnalysis } from '../../services/FunderService';
import Loader from '../Loader/Loader';

interface IFundListTableWrapper {
	data: Array<IData> | undefined;
	id: string;
	sellerName: string;
}
interface IData {
	type: string;
	attributes: IAttributes;
}
export interface IAttributes {
	title: string;
	coverage: string;
}

const TableWrapper = styled.section`
	display: flex;
	justify-content: space-between;
	margin-top: 6.9rem;
`;

const FundListTableWrapper: React.FC<IFundListTableWrapper> = ({
	data,
	id,
	sellerName,
}) => {
	const [equities, setEquities] = useState(Array()),
		[fixedIncome, setFixedIncome] = useState(Array()),
		[multiAsset, setMultiAsset] = useState(Array()),
		[property, setProperty] = useState(Array()),
		[other, setOther] = useState(Array());

	useEffect(() => {
		{
			data && handleFilterData(data);
		}
	}, [data]);

	const handleFilterData = (data: Array<IData>) => {
		data.forEach(item => {
			switch (item.type) {
				case 'Equity':
					setEquities(prevEquities => [...prevEquities, item]);
					break;
				case 'Fixed Income':
					setFixedIncome(prevFixedIncome => [...prevFixedIncome, item]);
					break;
				case 'Multi Asset':
					setMultiAsset(prevMultiAsset => [...prevMultiAsset, item]);
					break;
				case 'Property':
					setProperty(prevProperty => [...prevProperty, item]);
					break;
				default:
					setOther(prevOther => [...prevOther, item]);
					break;
			}
		});
	};

	return (
		<>
			{data ? (
				<>
					<TableWrapper>
						<FundListTableColumn
							id={id}
							sellerName={sellerName}
							data={equities}
							title={t.fundPageList.equities}
						/>
						<FundListTableColumn
							id={id}
							sellerName={sellerName}
							data={fixedIncome}
							title={t.fundPageList.fixedIncome}
						/>
						<FundListTableColumn
							id={id}
							sellerName={sellerName}
							data={multiAsset}
							title={t.fundPageList.multiAsset}
						/>
						<FundListTableColumn
							id={id}
							sellerName={sellerName}
							data={property}
							title={t.fundPageList.property}
						/>
						<FundListTableColumn
							id={id}
							sellerName={sellerName}
							data={other}
							title={t.fundPageList.other}
						/>
					</TableWrapper>
				</>
			) : (
				<Loader />
			)}
		</>
	);
};

export default FundListTableWrapper;
