import React from 'react';
import styled from 'styled-components';
import Title from '../Title/Title';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';
import { t } from '../../translations/en';
import FundItem from '../FundItem/FundItem';
import TabContainer from '../TabContainer/TabContainer';
import TableRow from '../TableRow/TableRow';
import TableItem from '../TableItem/TableItem';
import VerticalGrid from '../VerticalGrid/VerticalGrid';
import { useGetRowColor } from '../../hooks/useGetRowColor';
import InformationMessage from '../InformationMessage/InformationMessage';
import { useHistory } from 'react-router-dom';
import { routes } from '../../routes/routes';
import { useGetPerformance } from '../../hooks/useGetPerformance';
import Tooltip from '../Tooltip/Tooltip';

type DataObject = {
	name: string;
	peergroup: number;
	marketShare: string;
	changeRate: string;
	shareExternalFunds: string;
	potential: string;
	id: string;
};

const CustomTableItem = styled(TableItem)`
	padding-left: 3.5rem;
	word-break: break-word;
`;

const CustomTableRow = styled(TableRow)`
	:before {
		width: 2rem;
	}
	:hover {
		cursor: pointer;
	}
`;

interface FundSellerPotentialInterface {
	data: Array<DataObject>;
}

const handlePrintPercentage = (value: string) => {
	if (value === 'NaN' || value === null || value === '') {
		return 'n/a';
	} else {
		return `${value.replace('.', ',')}%`;
	}
};

const FundSellerPotential: React.FC<FundSellerPotentialInterface> = ({
	data,
}) => {
	const history = useHistory();
	return (
		<>
			<FundItem title={t.fundDetail.fundSellerPotential} withCaption>
				<>
					<TabContainer>
						<HorizontalGrid
							columnPattern={'repeat(5, 1fr)'}
							gap={'0'}
							align='center'
						>
							<Title size='big' colorTheme='dark'>
								{t.fundDetail.fundSeller}
							</Title>
							<Title size='big' colorTheme='dark'>
								{t.fundDetail.recommendations}
							</Title>
							<Title size='big' colorTheme='dark' align='center'>
								<Tooltip
									tooltip={t.fundDetail.marketShareTooltip}
									textAlign='left'
								>
									{t.fundDetail.marketShare}
								</Tooltip>
							</Title>
							<Title size='big' colorTheme='dark' align='center'>
								<Tooltip
									tooltip={t.fundDetail.changeRateTooltip}
									textAlign='left'
								>
									{t.fundDetail.changeRate}
								</Tooltip>
							</Title>
							<Title size='big' colorTheme='dark' align='center'>
								{t.fundDetail.shareExternalFunds}
							</Title>
						</HorizontalGrid>
					</TabContainer>
					<VerticalGrid gap='0.7rem'>
						{data && data.length ? (
							data.map(
								(
									{
										name,
										peergroup,
										marketShare,
										changeRate,
										shareExternalFunds,
										potential,
										id,
									},
									index
								) => (
									<CustomTableRow
										colorTheme={useGetRowColor(potential)}
										key={index}
										columnPattern={'repeat(5, 1fr)'}
										onClick={() => history.push(`${routes.fundList}/${id}`)}
									>
										<CustomTableItem paddingLeft>
											{useGetPerformance(name)}
										</CustomTableItem>
										<CustomTableItem>
											{useGetPerformance(peergroup)}
										</CustomTableItem>
										<CustomTableItem center>
											{handlePrintPercentage(marketShare)}
										</CustomTableItem>
										<CustomTableItem center>
											{handlePrintPercentage(changeRate)}
										</CustomTableItem>
										<CustomTableItem center>
											{handlePrintPercentage(shareExternalFunds)}
										</CustomTableItem>
									</CustomTableRow>
								)
							)
						) : (
							<InformationMessage />
						)}
					</VerticalGrid>
				</>
			</FundItem>
		</>
	);
};

export default FundSellerPotential;
