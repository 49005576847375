import styled from 'styled-components';

const PasswordRemindTitle = styled.h2`
	font-size: ${({ theme }) => theme.font.size.text_30};
	line-height: ${({ theme }) => theme.font.lineHeight.text_45};
	color: ${({ theme }) => theme.color.white};
	font-weight: ${({ theme }) => theme.font.weight.bold};
`;

export default PasswordRemindTitle;
