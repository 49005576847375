import React from 'react';
import styled from 'styled-components';
import { t } from '../../translations/en';

const Message = styled.h3`
	padding: 1rem 1.5rem;
	font-size: ${({ theme }) => theme.font.size.text_M};
	font-weight: ${({ theme }) => theme.font.weight.bold};
	color: ${({ theme }) => theme.color.gray};
	text-align: center;
`;

const InformationMessage: React.FC = () => <Message>{t.emptyTable}</Message>;

export default InformationMessage;
