import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";

interface FooterLinkConfig {
  children: string;
  destination?: string;
  onClick?: () => void;
  onMouseDown?: () => void;
  isSidebar?: boolean;
  className?: string;
}

const LinkWrapper = styled.span`
  user-select: none;
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.text_M};
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
  margin-right: 3rem;
  transition: color 0.3s ease-in-out;
  @media screen and (min-width: 1600px) {
    margin-right: 7.7rem;
  }
  :hover {
    color: ${({ theme }) => theme.color.main};
    cursor: pointer;
  }
`;

const SideBarLinkWrapper = styled.span`
  color: ${({ theme }) => theme.color.main};
  font-size: ${({ theme }) => theme.font.size.text_XL};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  margin-right: 3rem;
  margin-bottom: 2.4rem;
  transition: color 0.3s ease-in-out;
  @media screen and (min-width: 1600px) {
    margin-right: 7.7rem;
  }
  :hover {
    color: ${({ theme }) => theme.color.black};
    cursor: pointer;
  }
`;

const SideBarNavLink = styled(NavLink)`
  width: 100%;
  user-select: none;
  :hover {
    color: ${({ theme }) => theme.color.black};
    text-decoration: none;
    cursor: pointer;
  }
`;

const StyledNavLink = styled(NavLink)`
  :hover {
    color: ${({ theme }) => theme.color.main};
    text-decoration: none;
    cursor: pointer;
  }
`;

const FooterLink: React.FC<FooterLinkConfig> = ({
  children,
  destination,
  onClick,
  onMouseDown,
  isSidebar,
  className,
}) => (
  <>
    {onClick || onMouseDown ? (
      <>
        {isSidebar ? (
          <SideBarLinkWrapper
            className={className}
            onMouseDown={onMouseDown}
            onClick={onClick}
          >
            {children}
          </SideBarLinkWrapper>
        ) : (
          <LinkWrapper
            className={className}
            onMouseDown={onMouseDown}
            onClick={onClick}
          >
            {children}
          </LinkWrapper>
        )}
      </>
    ) : (
      <>
        {isSidebar ? (
          <SideBarLinkWrapper className={className}>
            <SideBarNavLink to={destination}>{children}</SideBarNavLink>
          </SideBarLinkWrapper>
        ) : (
          <LinkWrapper className={className}>
            <StyledNavLink to={destination}>{children}</StyledNavLink>
          </LinkWrapper>
        )}
      </>
    )}
  </>
);

export default FooterLink;
