import { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface PeergroupFilterModel {
	items: {
		clearFilters?: boolean;
	};
	setFiltersState: Action<
		PeergroupFilterModel,
		{
			clearFilters?: boolean;
		}
	>;
}

const peergroupFilterModel: PeergroupFilterModel = {
	items: {
		clearFilters: false,
	},
	setFiltersState: action((state, payload) => {
		state.items.clearFilters = payload.clearFilters;
	}),
};

export default peergroupFilterModel;
