import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import Flex from "../Flex/Flex";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import Title from "../Title/Title";
import { t } from "../../translations/en";
import selectTheme from "../../utils/SelectTheme";
import { useUpdateQuery } from "./../../hooks/useUpdateQuery";

const Wrapper = styled.div`
  margin: 3.5rem 0 -1rem 0;
`;

const StyledTitle = styled(Title)`
  line-height: 3.8rem;
  margin-right: 2rem;
`;

interface IFilter {
  strategies: {
    id: string;
    name: string;
  }[];
}

interface IPeergroupFilter {
  peergroups: {
    id: string;
    name: string;
    asset_class?: string;
    url?: string;
  }[];
}

interface IFundSellerFilter {
  fundSellers?: any;
  sellerId: string;
  sellerName: string;
  strategyId: string;
  peergroupId: string;
}

interface IFilterResults {
  setFundSellerId?: React.Dispatch<React.SetStateAction<string>>;
  setPeergroupId?: React.Dispatch<React.SetStateAction<string>>;
  setStrategyValue?: React.Dispatch<React.SetStateAction<string>>;
  setInternalExternal?: React.Dispatch<React.SetStateAction<string>>;
  isClearable?: boolean;
  isPeergroupPage?: boolean;
  sellers: any;
}

interface IOption {
  label: string;
  value: string | number;
}

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: "2rem",
    fontSize: "1.2rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
};

const GapPeergroupFilters: React.FC<
  IFilter & IPeergroupFilter & IFilterResults & IFundSellerFilter
> = ({
  strategies,
  fundSellers,
  setFundSellerId,
  setStrategyValue,
  sellerId,
  strategyId,
  peergroupId,
}) => {
  const [sellerStringValue, setSellerStringValue] = useState<string>("");
  const [strategyStringValue, setStrategyStringValue] = useState<string>("");
  const [sellerValue, setSellerValue] = useState<Array<IOption>>([]);
  const [localStrategyValue, setLocalStrategyValue] = useState<IOption>(null);
  const [strategyFilter, setStrategyFilter] = useState<Array<IOption>>(null);
  const [fundSellerFilter, setfundSellerFilter] = useState<Array<IOption>>(
    null
  );
  const updateQuery = useUpdateQuery();

  useEffect(() => {
    if (strategies) {
      const strategy = [];
      strategies.map((item, index) => {
        let obj = {
          label: item.id,
          value: item.name,
        };
        strategy.push(obj);
      });
      setStrategyFilter(strategy);
      if (strategyId && strategyId !== "") {
        setLocalStrategyValue({ label: strategyId, value: strategyId });
        setStrategyValue(strategyId);
        setStrategyStringValue(`${strategyId}`);
      } else {
        setLocalStrategyValue(null);
        setStrategyValue("");
        setStrategyStringValue("");
      }
    }
  }, [strategies, strategyId]);

  useEffect(() => {
    if (fundSellers) {
      let fundSeller = [];
      fundSellers.map((item, index) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        fundSeller.push(obj);
      });
      setfundSellerFilter(fundSeller);
      if (sellerId && sellerId !== "" && sellerId !== "0") {
        const sellers = sellerId.split(",");
        const filteredSellers = [];
        sellers.forEach((id) => {
          filteredSellers.push(
            fundSeller.filter(({ label, value }) => value === parseInt(id))[0]
          );
        });
        setSellerStringValue(parseArrayToString(filteredSellers));
        setFundSellerId(parseArrayToString(filteredSellers));
        setSellerValue(filteredSellers);
      }
    }
  }, [fundSellers, sellerId]);

  const parseArrayToString = (
    array: Array<{ value: number | string; label: string }>
  ) => {
    if (array.length) {
      const arrayWithIds = array.map(({ value }) => value);
      return arrayWithIds.join(",");
    }
    return "";
  };

  return (
    <Wrapper>
      <Flex justify="flex-start" align="flex-start">
        <StyledTitle>{t.filters}</StyledTitle>
        <HorizontalGrid columnPattern={"repeat(4,25rem)"} gap="2rem">
          <Select
            classNamePrefix="filter"
            styles={customStyles}
            isSearchable={false}
            isMulti
            value={sellerValue}
            isClearable
            placeholder={t.recommendedFundsPage.tableHeaders.fundSeller}
            options={fundSellerFilter}
            theme={selectTheme}
            onChange={(value) => {
              const returnedValue = value ? value : [];
              const returnedStringValue = value
                ? parseArrayToString(returnedValue)
                : "";
              setSellerValue(returnedValue);
              setFundSellerId(parseArrayToString(returnedValue));
              setSellerStringValue(returnedStringValue);
              updateQuery(
                `peergroupId=${peergroupId}&sellerId=${returnedStringValue}&strategy=${strategyStringValue}`
              );
            }}
          />
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            value={localStrategyValue}
            placeholder={t.recommendedFundsPage.tableHeaders.strategy}
            options={strategyFilter}
            theme={selectTheme}
            onChange={(value) => {
              const returnedValue = value === null ? "" : value.value;
              setLocalStrategyValue(value);
              setStrategyValue(returnedValue);
              setStrategyStringValue(returnedValue);
              updateQuery(
                `peergroupId=${peergroupId}&sellerId=${sellerStringValue}&strategy=${returnedValue}`
              );
            }}
          />
        </HorizontalGrid>
      </Flex>
    </Wrapper>
  );
};

export default GapPeergroupFilters;
