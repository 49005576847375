import React from 'react';
import styled from 'styled-components';
import FooterLink from '../FooterLink/FooterLink';
import { t } from '../../translations/en';
import { routes } from '../../routes/routes';

type footerType = 'default' | 'logoOnly' | 'withList';

interface FooterLinksConfig {
	type: footerType;
}

const LinksWrapper = styled('section')<{ type: footerType }>`
	display: flex;
	align-items: center;
`;

const FooterLinks: React.FC<FooterLinksConfig> = ({ type }) => (
	<LinksWrapper type={type}>
		<FooterLink destination={routes.aboutUs}>{t.aboutUs}</FooterLink>
		<FooterLink destination={routes.legalInformation}>
			{t.legalInformation}
		</FooterLink>
		<FooterLink destination={routes.news}>{t.news}</FooterLink>
		<FooterLink destination={routes.contact}>{t.contactPage.title}</FooterLink>
	</LinksWrapper>
);

export default FooterLinks;
