import React from 'react';
import styled, { css } from 'styled-components';
import { buttonSizeType } from '../../types/buttonTypes';
import Loader from '../Loader/Loader';

type colorThemeType = 'light' | 'dark';

interface ButtonInterface {
	size?: buttonSizeType;
	fulfilled?: boolean;
	isLoading?: boolean;
	colorTheme?: colorThemeType;
	backButton?: boolean;
	borderWidth?: string;
}

interface TranslateButtonConfig {
	className?: string;
	content: string;
	size?: buttonSizeType;
	onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
	disabled?: boolean;
	fulfilled?: boolean;
	isLoading?: boolean;
	type?: 'button' | 'submit' | 'reset';
	colorTheme?: colorThemeType;
	backButton?: boolean;
	borderWidth?: string;
}

const Button = styled.button<ButtonInterface>`
	color: ${({ theme, colorTheme }) =>
		colorTheme === 'dark' ? theme.color.white : theme.color.main};
	background: ${({ theme, colorTheme }) =>
		colorTheme === 'dark' ? theme.color.main : theme.color.white};
	outline: none;
	border-width: ${({ borderWidth }) => borderWidth};
	border-style: solid;
	border-color:
		${({ theme, colorTheme }) =>
			colorTheme === 'dark' ? theme.color.main : theme.color.white};
	padding: 1.5rem 2rem;
	min-height: 6.68rem;
	width: ${({ fulfilled }) => (fulfilled ? '100%' : '21rem')};
	font-weight: ${({ theme }) => theme.font.weight.medium};
	font-size: ${({ theme }) => theme.font.size.button};
	line-height: ${({ theme }) => theme.font.lineHeight.button};
	transition: color 0.3s ease, background 0.3s ease;
	position: relative;
	:hover {
		cursor: default;
	}

	${({ backButton }) =>
		backButton &&
		css`
			color: ${({ theme }) => theme.color.white};
			background: ${({ theme }) => theme.color.main};
		`}

	${({ size }) =>
		size === 'small' &&
		css`
			padding: 0.2rem 2rem;
			min-height: auto;
			width: auto;
			font-size: ${({ theme }) => theme.font.size.smallButton};
			line-height: ${({ theme }) => theme.font.lineHeight.smallButton};
		`}

	${({ size }) =>
		size === 'medium' &&
		css`
			padding: 0.5rem 3.5rem;
			min-height: auto;
			width: auto;
			font-size: 2rem;
			line-height: 3.3rem;
			border-width: 1px;
			font-weight: ${({ theme }) => theme.font.weight.bold};
		`}

	${({ isLoading }) =>
		isLoading &&
		css`
			color: transparent;
		`}

	${({ isLoading }) =>
		!isLoading &&
		css`
			:disabled {
				background: ${({ theme }) => theme.color.gray};
				border-color: ${({ theme }) => theme.color.gray};
				color: ${({ theme }) => theme.color.darkerGray};
			}

			:not(:disabled):hover {
				color: ${({ theme, colorTheme }) =>
					colorTheme === 'dark' ? theme.color.main : theme.color.white};
				background: ${({ theme, colorTheme }) =>
					colorTheme === 'dark' ? theme.color.white : theme.color.main};
				cursor: pointer;
			}
		`}
`;

const CustomButton: React.FC<TranslateButtonConfig> = ({
	className,
	content,
	size,
	onClick,
	disabled,
	fulfilled,
	isLoading,
	colorTheme,
	type,
	backButton,
	borderWidth = '2px',
}) => (
	<Button
		borderWidth={borderWidth}
		backButton={backButton}
		className={className}
		size={size}
		onClick={onClick}
		disabled={disabled}
		fulfilled={fulfilled}
		isLoading={isLoading}
		colorTheme={colorTheme}
		type={type}
	>
		{isLoading && <Loader position="absolute" />}
		{content}
	</Button>
);

export default CustomButton;
