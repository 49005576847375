import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import MainWrapper from "../../components/MainWrapper/MainWrapper";
import MainContainer from "../../components/MainContainer/MainContainer";
import Heading from "../../components/Heading/Heading";
import { t } from "../../translations/en";
import Subtitle from "../../components/Subtitle/Subtitle";
import Title from "../../components/Title/Title";
import Checkbox from "../../components/Checkbox/Checkbox";
import Select from "../../components/Select/Select";
import Flex from "../../components/Flex/Flex";
import BasicLink from "../../components/BasicLink/BasicLink";
import Search from "../../components/Search/Search";
import Footer from "../../components/Footer/Footer";
import { useGetEnv } from "../../hooks/useGetEnv";
import CustomButton from "../../components/Button/Button";
import { routes } from "../../routes/routes";
import { FunderService } from "../../services/FunderService";
import { useGetUserAccountType } from "../../hooks/useGetUserAccountType";
import { useSetHeader } from "../../hooks/useSetHeader";

const ContentColumn = styled.div`
  &:not(:last-of-type) {
    position: relative;
    :after {
      content: "";
      position: absolute;
      right: 0rem;
      top: 0;
      height: 100%;
      width: 1px;
      background: ${({ theme }) => theme.color.white};
    }
  }
`;

const RedirectButtonWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const ContentColumnInner = styled.div<{
  position: "left" | "center" | "right";
  fullHeight?: boolean;
}>`
  margin: ${({ position }) =>
    position === "left"
      ? "0 3.2rem 0 0"
      : position === "center"
      ? "0 3.2rem"
      : "0 0 0 3.2rem"};
  width: ${({ position }) => (position === "left" ? "auto" : "100%")};
  height: ${({ fullHeight }) => (fullHeight ? "100%" : "auto")};
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  :not(:first-of-type) {
    ${ContentColumn} {
      padding-top: 2rem;
    }
    :nth-of-type(2) {
      ${ContentColumn} {
        padding-top: 4rem;
      }
    }
  }
`;

const CheckboxWrapper = styled.div`
  display: grid;
  grid-template-rows: 2.4rem 2.4rem auto;
  grid-row-gap: 1.5rem;
`;

interface optionsDataObject {
  id: number;
  name: string;
}

interface autocompleteFundsDataObject {
  id: number;
  share_class_with_isin: string;
}

interface autocompleteManagersDataObject {
  id: number;
  name: string;
}

type SelectedValueType = {
  value: string;
  label: string;
  id?: number;
};

const ACCOUNT_TYPE = {
  SELLER: "seller",
  DEFAULT: "default",
  MANAGER: "manager",
};

const LandingPage: React.FC = () => {
  const API_URL = useGetEnv("API_URL");
  const [optionsList, setOptionsList] = useState<Array<object>>([{}]);
  const [autocompleteFundsList, setAutocompleteFundsList] = useState<
    Array<object>
  >([{}]);
  const [autocompleteManagersList, setAutocompleteManagersList] = useState<
    Array<object>
  >([{}]);
  const [autocompleteFundsString, setAutocompleteFundsString] = useState<
    string | null
  >(null);
  const [autocompleteManagersString, setAutocompleteManagersString] = useState<
    string | null
  >(null);
  const [autocompleteFundsId, setAutocompleteFundsId] = useState<number | null>(
    null
  );
  const [autocompleteManagerId, setAutocompleteManagerId] = useState<
    number | null
  >(null);
  const [selectedValue, setSelectedValue] = useState<SelectedValueType | null>(
    null
  );
  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);
  const [isFundsFormValid, setFundsFormValid] = useState<boolean>(false);
  const [isManagersFormValid, setManagersFormValid] = useState<boolean>(false);
  const [isListLoading, setListLoading] = useState<boolean>(false);
  const [accountType, setAccountType] = useState<string | null>(null);
  const [userId, setUserId] = useState<number | null>(null);
  const history = useHistory();

  useSetHeader({ hasHeader: false });

  const filterFundsSearchList = (value: string) => {
    setAutocompleteFundsString(value);
  };

  const filterManagersSearchList = (value: string) => {
    setAutocompleteManagersString(value);
  };

  useGetUserAccountType(setAccountType, setUserId);

  // fetch select list getUserAccountInfo
  useEffect(() => {
    FunderService.getFundSellers()
      .then((response) => {
        const data = JSON.parse(response);
        const formattedData = data.map((item: optionsDataObject) => {
          return { value: item.id, label: item.name };
        });
        setOptionsList(formattedData);
      })
      .catch((error) => {
        console.log(
          `%cCaught fetch error:\n ${error}`,
          "color: #f50; background: yellow"
        );
        history.push(routes.signIn);
      });
  }, []);

  // fetch autocomplete manager list
  useEffect(() => {
    if (autocompleteManagersString !== null) {
      setListLoading(true);
      FunderService.getAssetManagerList(autocompleteManagersString)
        .then((response) => {
          const data = JSON.parse(response);
          const formattedData = data.map(
            (item: autocompleteManagersDataObject) => {
              return { value: item.id, name: item.name };
            }
          );
          setAutocompleteManagersList(formattedData);
          setListLoading(false);
        })
        .catch((error) => {
          setListLoading(false);
          console.log(error);
        });
    }
  }, [autocompleteManagersString]);

  // fetch autocomplete funds list
  useEffect(() => {
    if (autocompleteFundsString !== null) {
      setListLoading(true);
      FunderService.getFundList(autocompleteFundsString)
        .then((response) => {
          const data = JSON.parse(response);
          const formattedData = data.results.map(
            (item: autocompleteFundsDataObject) => {
              return { value: item.id, name: item.share_class_with_isin };
            }
          );
          setListLoading(false);
          setAutocompleteFundsList(formattedData);
        })
        .catch((error) => {
          console.log(
            `%cCaught fetch error:\n ${error}`,
            "color: #f50; background: yellow"
          );
          setListLoading(false);
          history.push(routes.signIn);
        });
    }
  }, [autocompleteFundsString]);

  const handleSend = () => {
    if (selectedValue !== null && (checkbox1 === true || checkbox2 === true)) {
      if (checkbox1 && !checkbox2) {
        window.location.href = `${useGetEnv("CLIENT_URL")}recommended-funds/${
          selectedValue.value
        }`;
      }
      if (!checkbox1 && !checkbox1) {
        window.location.href = `${useGetEnv("CLIENT_URL")}fund-list/${
          selectedValue.value
        }`;
      }
    }
  };

  const handleFundsSend = () => {
    history.push(`${routes.fund}/${autocompleteFundsId}`);
  };

  const handleManagersSend = () => {
    history.push(`${routes.assetManagerView}/${autocompleteManagerId}`);
  };

  return (
    <>
      <MainWrapper>
        <MainContainer>
          {/* heading section */}
          <ContentWrapper>
            <ContentColumn>
              <ContentColumnInner position="left">
                {accountType === ACCOUNT_TYPE.SELLER ? (
                  <>
                    <Heading>{t.landingPage.title1.seller}</Heading>
                    <BasicLink
                      colorTheme="light"
                      to={`${routes.recommendedFunds}/${userId}`}
                    >
                      <Subtitle colorTheme="light">
                        {t.landingPage.subtitle1.seller}
                      </Subtitle>
                    </BasicLink>
                  </>
                ) : (
                  <>
                    <BasicLink colorTheme="light" to={routes.table1}>
                      <Heading>{t.landingPage.title1.default}</Heading>
                    </BasicLink>
                    <Subtitle colorTheme="light">
                      {t.landingPage.subtitle1.default}
                    </Subtitle>
                  </>
                )}
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              <ContentColumnInner position="center">
                <Heading>{t.landingPage.title2}</Heading>
                <Subtitle colorTheme="light">
                  {t.landingPage.subtitle2}
                </Subtitle>
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              <ContentColumnInner position="right">
                {accountType === ACCOUNT_TYPE.MANAGER ? (
                  <>
                    <BasicLink colorTheme="light" to={routes.globalInvestors}>
                      <Heading>{t.landingPage.title3.default}</Heading>
                    </BasicLink>
                    <Subtitle colorTheme="light">
                      {t.landingPage.subtitle3.default}
                    </Subtitle>
                  </>
                ) : (
                  <>
                    {accountType === ACCOUNT_TYPE.SELLER ? (
                      <BasicLink
                        colorTheme="light"
                        to={`${routes.assetManagerView}/${userId}`}
                      >
                        <Heading>{t.landingPage.title3.seller}</Heading>
                      </BasicLink>
                    ) : (
                      <Heading>{t.landingPage.title3.seller}</Heading>
                    )}

                    <Subtitle colorTheme="light">
                      {t.landingPage.subtitle3.seller}
                    </Subtitle>
                  </>
                )}
              </ContentColumnInner>
            </ContentColumn>
          </ContentWrapper>
          {/* ----- */}

          {/* select section */}
          <ContentWrapper>
            <ContentColumn>
              <ContentColumnInner position="left">
                <Subtitle colorTheme="light">
                  {t.landingPage.subtitle4}
                </Subtitle>
                <Flex marginTop={"2rem"}>
                  <Select
                    label={t.landingPage.label1}
                    handleChange={(value: SelectedValueType) =>
                      setSelectedValue(value)
                    }
                    options={optionsList}
                  />
                </Flex>
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn style={{ display: "flex", alignItems: "flex-end" }}>
              <ContentColumnInner position="center">
                <Search
                  list={autocompleteFundsList}
                  label={t.landingPage.label2}
                  handleChange={(value) => {
                    filterFundsSearchList(value);
                    setFundsFormValid(false);
                  }}
                  handleSelect={(value, id) => {
                    setFundsFormValid(value);
                    setAutocompleteFundsId(id);
                  }}
                  isListLoading={isListLoading}
                />
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn style={{ display: "flex", alignItems: "flex-end" }}>
              <ContentColumnInner position="right">
                {accountType !== ACCOUNT_TYPE.MANAGER && (
                  <Search
                    list={autocompleteManagersList}
                    label={t.landingPage.label3}
                    handleChange={(value) => {
                      filterManagersSearchList(value);
                      setManagersFormValid(false);
                    }}
                    handleSelect={(value, id) => {
                      setManagersFormValid(value);
                      setAutocompleteManagerId(id);
                    }}
                    isListLoading={isListLoading}
                  />
                )}
              </ContentColumnInner>
            </ContentColumn>
          </ContentWrapper>
          {/* ----- */}

          {/* titles */}
          <ContentWrapper>
            <ContentColumn>
              <ContentColumnInner position="left">
                <Flex marginTop={"2.5rem"} justify="flex-start">
                  <Title colorTheme="light">{t.pleaseChoose}</Title>
                </Flex>
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              <ContentColumnInner position="center"></ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              <ContentColumnInner position="right"></ContentColumnInner>
            </ContentColumn>
          </ContentWrapper>
          {/* ----- */}

          {/* checkboxes and links */}
          <ContentWrapper>
            <ContentColumn>
              {/* fund seller */}
              <ContentColumnInner position="left">
                <CheckboxWrapper>
                  <Checkbox
                    label={t.landingPage.checkbox1}
                    name="recommendations"
                    handleChange={(value) => {
                      setCheckbox2(false);
                      setCheckbox1(value);
                    }}
                    value={checkbox1}
                  />
                  <Checkbox
                    label={t.landingPage.checkbox2}
                    name="analysis"
                    handleChange={(value) => {
                      setCheckbox1(false);
                      setCheckbox2(value);
                    }}
                    value={checkbox2}
                  />
                  <Flex>
                    <CustomButton
                      content={t.submit}
                      size="small"
                      disabled={
                        !(
                          selectedValue !== null &&
                          (checkbox1 === true || checkbox2 === true)
                        )
                      }
                      onClick={handleSend}
                    />
                  </Flex>
                </CheckboxWrapper>
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              {/* funds */}
              <ContentColumnInner position="center">
                <CheckboxWrapper>
                  <div>
                    <BasicLink colorTheme="light" to={routes.newFunds}>
                      {t.landingPage.link1}
                    </BasicLink>
                  </div>
                  <div>
                    <BasicLink
                      colorTheme="light"
                      to={routes.recommendationChart}
                    >
                      {t.landingPage.link2}
                    </BasicLink>
                  </div>
                  <Flex>
                    <CustomButton
                      content={t.submit}
                      size="small"
                      disabled={!isFundsFormValid}
                      onClick={handleFundsSend}
                    />
                  </Flex>
                </CheckboxWrapper>
              </ContentColumnInner>
            </ContentColumn>
            <ContentColumn>
              {/* scorecard */}
              <ContentColumnInner position="right" fullHeight>
                <RedirectButtonWrapper>
                  {accountType === ACCOUNT_TYPE.MANAGER ? (
                    <CustomButton
                      content={t.scorecardButton}
                      size="small"
                      onClick={() => history.push(routes.globalInvestors)}
                    />
                  ) : (
                    <CustomButton
                      content={t.submit}
                      size="small"
                      disabled={!isManagersFormValid}
                      onClick={handleManagersSend}
                    />
                  )}
                </RedirectButtonWrapper>
              </ContentColumnInner>
            </ContentColumn>
          </ContentWrapper>
        </MainContainer>
      </MainWrapper>
      <Footer type="withList" />
    </>
  );
};

export default LandingPage;
