import React from 'react';
import styled from 'styled-components';

interface FormConfig {
	children: React.ReactNode;
	onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
	className?: string;
}

const StyledForm = styled.form`
	display: grid;
	grid-row-gap: 1rem;
	width: 100%;
`;

const Form: React.FC<FormConfig> = ({ children, onSubmit, className }) => (
	<StyledForm onSubmit={onSubmit} className={className}>
		{children}
	</StyledForm>
);

export default Form;
