import React from "react";
import styled from "styled-components";
import MainContainer from "../MainContainer/MainContainer";
import Flex from "../Flex/Flex";
import Heading from "../Heading/Heading";
import HeaderSearch from "../HeaderSearch/HeaderSearch";
import { t } from "../../translations/en";

interface InformationBarConfig {
  content: string;
  title: string;
  titleName?: string;
  originalPage?: boolean;
  className?: string;
  searchComponent?: string | null;
  isImportData?: boolean;
  isStaticPage?: boolean;
  subtitle: string;
  additionalSubtitle?: string;
}

const ContentWrapper = styled.section`
  background: ${({ theme }) => theme.color.lightGreen};
  padding: 2rem 0;
  min-height: 10rem;
  display: flex;
  align-items: center;
`;

const TextContent = styled.h3`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.big_text};
  line-height: ${({ theme }) => theme.font.lineHeight.text_M};
`;

const CustomHeading = styled(Heading)`
  color: ${({ theme }) => theme.color.black};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.text_XL};
  line-height: ${({ theme }) => theme.font.lineHeight.text_XL};
`;

const HeadingName = styled(Heading)`
  color: ${({ theme }) => theme.color.textGray};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.text_XL};
  line-height: ${({ theme }) => theme.font.lineHeight.text_XL};
  margin-left: 0.5rem;
`;

const InformationBar: React.FC<InformationBarConfig> = ({
  content,
  title,
  titleName,
  className,
  searchComponent,
  isStaticPage,
  subtitle,
  additionalSubtitle,
}) => {
  return (
    <>
      <ContentWrapper className={className}>
        <MainContainer>
          <Flex justify="space-between">
            <div>
              <Flex justify="flex-start">
                <CustomHeading>{title}</CustomHeading>
                <HeadingName>{titleName}</HeadingName>
              </Flex>
              {isStaticPage ? (
                subtitle ? (
                  <TextContent>
                    {content} {additionalSubtitle && additionalSubtitle}
                  </TextContent>
                ) : null
              ) : (
                <TextContent>
                  {t.fundPageList.information} {content}
                  {additionalSubtitle && additionalSubtitle}{" "}
                </TextContent>
              )}
            </div>
            {searchComponent ? (
              <HeaderSearch type={searchComponent} titleName={titleName} />
            ) : null}
          </Flex>
        </MainContainer>
      </ContentWrapper>
    </>
  );
};

export default InformationBar;
