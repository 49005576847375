import React from "react";
import styled from "styled-components";
import RecommendedFundListTableWrapper from "../RecommendFundListTableWrapper/RecommendedFundListTableWrapper";
import { ISingleRecommendation } from "../../services/FunderService";

const Header = styled.h2`
  font-size: ${({ theme }) => theme.font.size.title_M};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.darkerGray};
  margin-top: 5rem;
`;

const TableWrapper = styled(RecommendedFundListTableWrapper)``;

interface IPeergroupTable {
  data: Array<ISingleRecommendation> | null;
  headerContent: string;
  ifRequestLink?: boolean;
  ifLastHeaderCell?: boolean;
  isHeaderShorter?: boolean;
  handlePageChange: (page: number, id?: string) => void;
  currentPage: string;
  pagesAmount: number;
  results?: Array<ISingleRecommendation> | null;
  isLoading?: boolean;
  id: string;
  peergroup?: string;
  handleSort: (sortBy: string) => void | null;
}

const PeergroupTable: React.FC<IPeergroupTable> = ({
  handleSort,
  id,
  currentPage,
  data,
  headerContent,
  ifRequestLink,
  ifLastHeaderCell,
  isHeaderShorter,
  pagesAmount,
  handlePageChange,
  isLoading,
}) => {
  return (
    <>
      <Header>{headerContent}</Header>
      <TableWrapper
        id={id}
        handlePageChange={(page) => handlePageChange(page, id)}
        data={data}
        handleSort={handleSort}
        currentPage={Number(currentPage)}
        pagesAmount={pagesAmount}
        marginTop={"2rem"}
        ifRequestLink={ifRequestLink}
        ifLastHeaderCell={ifLastHeaderCell}
        isHeaderShorter={isHeaderShorter}
        isLoading={isLoading}
        peergroupPage
      />
    </>
  );
};

export default PeergroupTable;
