import styled from 'styled-components';

const HorizontalGrid = styled.div<{
	columnPattern: string;
	gap?: string;
	align?: 'center' | 'flex-end' | 'flex-start';
}>`
	display: grid;
	grid-template-columns: ${({ columnPattern }) => columnPattern};
	grid-gap: ${({ gap }) => (gap ? gap : 0)};
	grid-auto-columns: min-content;
	align-items: ${({ align }) => (align ? align : 'flex-start')};
`;

export default HorizontalGrid;
