import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

type sizeType = 'small' | 'big';

const StyledLink = styled(Link)<{ colortheme: string; size?: sizeType }>`
	color: ${({ theme, colortheme }) =>
		colortheme === 'light' ? theme.color.white : theme.color.black};
	position: relative;
	overflow: hidden;
	padding-bottom: 0.6rem;
	font-size: ${({ size, theme }) =>
		size === 'small' ? theme.font.size.text_M : '1.6rem'};
	:after {
		content: '';
		position: absolute;
		left: 0%;
		bottom: 0rem;
		height: 0.2rem;
		width: 100%;
		background: ${({ theme, colortheme }) =>
			colortheme === 'light' ? theme.color.white : theme.color.black};
		transform: scale(0);
		pointer-events: none;
		transition: transform 0.3s ease, opacity 0.3s ease;
	}
	:hover {
		color: ${({ theme }) => theme.color.white};
		text-decoration: none;
		:after {
			transform: scale(1);
		}
	}
`;

interface BasicLinkConfig {
	children: string | React.ReactNode;
	to: string;
	colorTheme: string;
	size?: sizeType;
}

const BasicLink: React.FC<BasicLinkConfig> = ({
	children,
	to,
	colorTheme,
	size = 'big',
}) => (
	<StyledLink colortheme={colorTheme} to={to} size={size}>
		{children}
	</StyledLink>
);

export default BasicLink;
