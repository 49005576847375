import React from 'react';
import styled from 'styled-components';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';

interface PositiveChartInterface {
	data: number;
	biggestValue: number;
	color: string;
	separatedData: string;
}

const ChartIndex = styled.div<{ width: number; color: string; data: string }>`
	height: 0.8rem;
	width: ${({ width }) => `calc(${width} * 95%)`};
	background: ${({ theme, color }) => theme.color[color]};
	position: relative;
	:after {
		content: '${({ data }) => data}';
		position: absolute; 
		top: 50%;
		left: calc(100% + 0.5rem);
		transform: translateY(-50%);
	}
`;

const ChartValue = styled.div`
	text-align: right;
	font-size: 1.6rem;
	line-height: 0.8rem;
	color: transparent;
`;

const StyledHorizontalGrid = styled(HorizontalGrid)`
	position: relative;
`;

const PositiveChart: React.FC<PositiveChartInterface> = ({
	data,
	biggestValue,
	color,
	separatedData,
}) => {
	const biggestData = biggestValue ? biggestValue : 1;
	return (
		<StyledHorizontalGrid columnPattern='1fr max-content'>
			<ChartIndex
				width={data / biggestData}
				color={color}
				data={separatedData}
			/>
			<ChartValue>{data}</ChartValue>
		</StyledHorizontalGrid>
	);
};

export default PositiveChart;
