import React from 'react';
import styled from 'styled-components';
import ListLegendItemSquareColor from '../ListLegendItemSquareColor/ListLegendItemSquareColor';
import ListLegendItemContent from '../ListLegendItemContent/ListLegendItemContent';
import Tooltip from '../Tooltip/Tooltip';

interface IListLegendItemWrapper {
	bgColor: string;
	content: string;
	tooltipContent: string;
	align?: 'left' | 'center' | 'right';
}

const ItemWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-left: 4.5rem;
`;

const ListLegendItemWrapper: React.FC<IListLegendItemWrapper> = ({
	bgColor,
	content,
	tooltipContent,
	align = 'center',
}) => (
	<ItemWrapper>
		<ListLegendItemSquareColor bgColor={bgColor} />
		<Tooltip tooltip={tooltipContent} size="big" align={align}>
			<ListLegendItemContent content={content} />
		</Tooltip>
	</ItemWrapper>
);

export default ListLegendItemWrapper;
