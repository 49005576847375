export const useGetAverageColor = (
	value: number | string,
	numberType?: 'positive' | undefined
) => {
	const colorArray = [
		'yellow',
		'yellowLight',
		'lighterGray',
		'mainLight',
		'main',
		'white',
	];
	let calculatedValue = 0;
	if (typeof value === 'number') {
		calculatedValue = numberType === 'positive' ? value - 1 : value + 2;
	}
	if (typeof value === 'string') {
		switch (value) {
			case '--':
				calculatedValue = 0;
				break;
			case '-':
				calculatedValue = 1;
				break;
			case 'n/a':
				calculatedValue = 5;
				break;
			case '+':
				calculatedValue = 3;
				break;
			case '++':
				calculatedValue = 4;
				break;
			default:
				calculatedValue = 5;
				break;
		}
	}
	if (value === null) {
		calculatedValue = 5;
	}
	return colorArray[calculatedValue];
};
