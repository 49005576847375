import React from "react";
import { useStoreActions } from "../easy-peasy/hooks/hooks";

type ModalConfigType = {
  visible?: boolean;
  canBeClosed?: boolean;
  doOverflow?: boolean;
  component?:
    | "LoginModal"
    | "GapAnalysisModal"
    | "SearchFundsModal"
    | "PeergroupsModal"
    | "AssetManagerModal"
    | null;
  title?: string;
};

type UseSetModalType = () => (headerConfig: ModalConfigType) => void;

export const useSetModal: UseSetModalType = () => {
  const modalAction = useStoreActions((actions) => actions.modalModel.setModal);
  return ({
    visible = false,
    component = null,
    title = "",
    canBeClosed = true,
    doOverflow = true,
  }) => {
    modalAction({ visible, component, title, canBeClosed, doOverflow });
  };
};
