import React from 'react';
import styled from 'styled-components';
import Loader from '../Loader/Loader';

const Wrapper = styled.div`
	position: relative;
	z-index: 9;
	:before {
		height: 100vh;
		width: 100vw;
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		background: ${({ theme }) => theme.color.lighterGray};
	}
`;

interface WithPaginationInterface {
	isLoading: boolean;
	children: React.ReactNode;
}

const WithPagination: React.FC<WithPaginationInterface> = ({
	isLoading,
	children
}) => (
	<div>
		{isLoading && (
			<Wrapper>
				<Loader position="fixed" />
			</Wrapper>
		)}
		{children}
	</div>
);

export default WithPagination;
