import styled from "styled-components";

const Subtitle = styled("h4")<{ colorTheme?: string; size?: "big" }>`
  color: ${({ theme, colorTheme }) =>
    colorTheme === "light" ? theme.color.white : theme.color.black};
  font-size: ${({ theme, size }) =>
    size === "big" ? theme.font.size.text_L : theme.font.size.text_M};
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
`;

export default Subtitle;
