import React from 'react';
import styled from 'styled-components';
import ReactPaginate from 'react-paginate';
import { t } from '../../translations/en';

interface PaginationInterface {
	pagesAmount: number;
	currentPage: number | string;
	handleChange: (page: number, id?: string) => void;
	className?: string;
}

const Wrapper = styled.div`
	margin-top: 3rem;
	.pagination-container {
		color: ${({ theme }) => theme.color.main};
		display: flex;
	}
	.pagination-item {
		padding: 0.5rem 0.75rem;
		border: 1px solid #dee2e6;
		font-size: ${({ theme }) => theme.font.size.text_M};
		:not(.active):hover {
			background: #dee2e6;
			cursor: pointer;
		}
	}
	.previous {
		margin-right: 0.5rem;
	}
	.next {
		margin-left: 0.5rem;
	}
	.break {
		padding: 0.5rem 0.75rem;
	}
	.disabled {
		display: none;
	}
	.active {
		background: ${({ theme }) => theme.color.main};
		color: ${({ theme }) => theme.color.white};
		border-color: ${({ theme }) => theme.color.main};
	}
`;

const Pagination: React.FC<PaginationInterface> = ({
	pagesAmount,
	handleChange,
	currentPage,
	className,
}) => {
	const pageRangeDisplayed = 6;
	const marginPagesDisplayed = 3;

	if (typeof currentPage === 'string') {
		currentPage = parseInt(currentPage);
	}

	return (
		<Wrapper className={className}>
			{pagesAmount > 1 && (
				<ReactPaginate
					pageCount={pagesAmount}
					pageRangeDisplayed={pageRangeDisplayed}
					marginPagesDisplayed={marginPagesDisplayed}
					nextLabel={t.next}
					previousLabel={t.previous}
					initialPage={currentPage - 1}
					forcePage={currentPage - 1}
					onPageChange={({ selected }) => handleChange(selected + 1)}
					containerClassName={'pagination-container'}
					pageLinkClassName={'pagination-item'}
					previousLinkClassName={'pagination-item previous'}
					nextLinkClassName={'pagination-item next'}
					activeLinkClassName={'active'}
					disabledClassName={'disabled'}
				/>
			)}
		</Wrapper>
	);
};

export default Pagination;
