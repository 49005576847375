import { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface PeergroupModel {
	peergroup?: string;
	addPeergroup: Action<PeergroupModel, { peergroup?: string }>;
}

const peergroupModel: PeergroupModel = {
	peergroup: 'xxx',
	addPeergroup: action((state, payload) => {
		state = payload;
	}),
};

export default peergroupModel;
