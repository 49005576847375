import React, { useLayoutEffect, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
import HorizontalGrid from '../../components/HorizontalGrid/HorizontalGrid';
import { t } from '../../translations/en';
import VerticalGrid from '../../components/VerticalGrid/VerticalGrid';
import LuminLogo from '../../images/lumin-logo.png';
import { routes } from '../../routes/routes';
import { useHistory } from 'react-router';
import { useSetHeader } from '../../hooks/useSetHeader';
import { FunderService } from '../../services/FunderService';
import { useParseDate } from '../../hooks/useParseDate';
import WithPagination from '../../components/WithPagination/WithPagination';
import Pagination from '../../components/Pagination/Pagination';
import { useLocation } from 'react-router-dom';

const Card = styled.div<{ onClick?: () => void; index?: number }>`
	padding: 3.5rem 4rem;
	display: flex;
	flex-flow: column;
	min-height: 32rem;
	justify-content: space-between;

	&:hover {
		cursor: pointer;
		box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.4);
	}

	${({ index }) =>
		index === 0 || index === 2
			? css`
					background: ${({ theme }) => theme.color.lightBlue};
			  `
			: index === 1 || index === 3
			? css`
					background: ${({ theme }) => theme.color.main};
					color: ${({ theme }) => theme.color.white};
			  `
			: css`
					background: ${({ theme }) => theme.color.darkGray};
			  `}
`;

const NoNewsCard = styled(Card)`
	padding: 3.5rem 0;
	background: transparent;

	&:hover {
		cursor: default;
		box-shadow: none;
	}
`;

const CardTitle = styled.h2`
	font-weight: ${({ theme }) => theme.font.weight.bold};
	font-size: ${({ theme }) => theme.font.size.text_XL};
	line-height: ${({ theme }) => theme.font.lineHeight.text_XL};
	display: -webkit-box;
	-webkit-line-clamp: 4;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
`;

const ImageCard = styled.div`
	padding: 3.5rem 4rem;
	flex-flow: column;
	min-height: 32rem;
	justify-content: center;
	background: ${({ theme }) => theme.color.white};
	display: none;
	@media screen and (min-width: 660px) {
		display: flex;
	}

	img {
		width: 100%;
	}
`;

const StyledPagination = styled(Pagination)`
	display: flex;
	justify-content: flex-end;
`;

const DesktopOnly = styled.div`
	display: none;
	@media screen and (min-width: 1024px) {
		display: block;
	}
`;

type NewsItem = {
	content: string;
	id: number;
	published_at: string;
	title: string;
	url: string;
	type: {
		id: number;
		name: string;
		url: string;
	};
};

const GridLayout = styled.div`
	display: grid;
	grid-row-gap: 1.6rem;
	@media screen and (min-width: 660px) {
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 1.6rem;
	}
	@media screen and (min-width: 1024px) {
		grid-template-columns: repeat(3, 1fr);
		grid-column-gap: 1.6rem;
	}
`;

const additionalSpaceMap = {
	0: [1],
	1: [1],
	2: [],
	3: [1, 1],
	4: [1],
	5: [],
};

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const News: React.FC = () => {
	const history = useHistory();
	const query = useQuery();
	const currentPage = parseInt(query.get('page')) || 1;
	useSetHeader({
		title: t.newsPage.title,
		hasSubtitle: false,
		isStaticPage: true,
	});
	const newsPerPage = 5;
	const [news, setNews] = useState<Array<NewsItem>>([]);
	const [additionalSpace, setAdditionalSpace] = useState<Array<any>>([]);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [newsCount, setNewsCount] = useState<number>(0);

	const getNews = () => {
		setLoading(true);
		FunderService.getNewsList(newsPerPage, currentPage)
			.then(response => {
				const data = JSON.parse(response);
				setNews(data.results.slice(0, newsPerPage));
				setNewsCount(data.count);
				setLoading(false);
			})
			.catch(error => {
				if (error.response.status === 404) {
					history.push({
						pathname: routes.news,
						search: `?page=1`,
					});
				} else {
					console.log(error);
				}
			});
	};

	useLayoutEffect(() => getNews(), [currentPage]);

	useEffect(() => {
		if (isLoading) {
			setAdditionalSpace([1, 1]);
		} else {
			try {
				setAdditionalSpace(additionalSpaceMap[news.length]);
			} catch (error) {
				setAdditionalSpace([]);
			}
		}
	}, [isLoading, news]);

	let newsCards = null;
	let imageCard = null;

	if (!isLoading) {
		imageCard = (
			<ImageCard>
				<img src={LuminLogo} />
			</ImageCard>
		);

		if (news && news.length > 0) {
			newsCards = news.map(({ id, title, published_at, type }, index) => (
				<Card
					index={index}
					key={id}
					onClick={() => history.push(`${routes.news}/${id}`)}
				>
					<VerticalGrid gap='3.2rem'>
						<p>{type.name}</p>
						<CardTitle>{title}</CardTitle>
					</VerticalGrid>
					<div>{useParseDate(published_at)}</div>
				</Card>
			));
		} else {
			newsCards = [
				<NoNewsCard key={0}>
					<VerticalGrid gap='3.2rem'>
						<CardTitle>{isLoading ? '' : t.noNewsAvailableYet}</CardTitle>
					</VerticalGrid>
				</NoNewsCard>,
			];
		}
	}

	return (
		<WithPagination isLoading={isLoading}>
			<MainContainer marginTop='4.4rem' marginBottom='4.4rem'>
				<GridLayout>
					{newsCards}
					{additionalSpace.map((_, index) => (
						<DesktopOnly key={index}></DesktopOnly>
					))}
					{imageCard}
				</GridLayout>
				<StyledPagination
					pagesAmount={Math.ceil(newsCount / newsPerPage)}
					handleChange={page =>
						history.push({ pathname: routes.news, search: `?page=${page}` })
					}
					currentPage={currentPage}
				/>
			</MainContainer>
		</WithPagination>
	);
};

export default News;
