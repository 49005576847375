import styled from 'styled-components';

const OptionItem = styled.div`
	padding: 0.5rem 3rem;
	user-select: none;
	transition: background 0.3s ease-in-out;

	* {
		pointer-events: none;
	}

	&.selected {
		background: ${({ theme }) => theme.color.mainTransparentActive};
		cursor: pointer;
		& > * {
			color: ${({ theme }) => theme.color.white};
		}
	}

	:not(.selected):hover {
		background: ${({ theme }) => theme.color.mainTransparent};
		cursor: pointer;
	}
`;

export default OptionItem;
