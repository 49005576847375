import React, { useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import VerticalGrid from '../../components/VerticalGrid/VerticalGrid';
import Footer from '../../components/Footer/Footer';
import NewsWrapper from '../../components/NewsWrapper/NewsWrapper';
import Text from '../../components/Text/Text';
import Flex from '../../components/Flex/Flex';
import Title from '../../components/Title/Title';
import { useSetHeader } from '../../hooks/useSetHeader';
import { t } from '../../translations/en';
import { FunderService } from '../../services/FunderService';
import { useParams } from 'react-router';
import WithPagination from '../../components/WithPagination/WithPagination';
import { useParseDate } from '../../hooks/useParseDate';

const NewsHeader = styled.div`
	padding: 5rem 0;
	background: ${({ theme }) => theme.color.main};
	color: ${({ theme }) => theme.color.white};
	margin-top: 0.5rem;
`;

const NewsSubtitle = styled.span`
	font-size: ${({ theme }) => theme.font.size.text_M};
	@media screen and (min-width: 768px) {
		font-size: 1.8rem;
	}
	@media screen and (min-width: 1336px) {
		font-size: ${({ theme }) => theme.font.size.title_LL};
	}
`;

const NewsTitle = styled.h2`
	font-weight: ${({ theme }) => theme.font.weight.bold};
	font-size: 2rem;
	line-height: 3rem;
	@media screen and (min-width: 768px) {
		font-size: 2.5rem;
		line-height: 3.5rem;
	}
	@media screen and (min-width: 1336px) {
		font-size: ${({ theme }) => theme.font.size.title_XL};
		line-height: ${({ theme }) => theme.font.lineHeight.title_XL};
	}
`;

const NewsFooter = styled.div`
	border-top: 1px solid #d6d6d6;
	padding-top: 2.4rem;
`;

const Author = styled.div`
	margin-right: 7rem;
	@media screen and (max-width: 600px) {
		width: 100%;
		margin-right: 0;
		margin-bottom: 2rem;
	}
`;

const StyledFlex = styled(Flex)`
	@media screen and (max-width: 600px) {
		flex-wrap: wrap;
	}
`;

type Article = {
	content: string;
	id: number;
	published_at: string;
	title: string;
	url: string;
	type: {
		id: number;
		name: string;
		url: string;
	};
};

const NewsDetail: React.FC = () => {
	useSetHeader({
		title: t.newsPage.title,
		hasSubtitle: false,
		isStaticPage: true,
	});
	const [article, setArticle] = useState<Article | null>(null);
	const { id } = useParams();
	useLayoutEffect(() => {
		FunderService.getNewsItem(id)
			.then(response => {
				const data = JSON.parse(response);
				setArticle(data);
			})
			.catch(error => {
				console.log(error);
			});
	}, []);

	let content = null;
	if (article && article.content) {
		content = article.content.split('\n').map((part, i) => (
			<React.Fragment key={i}>
				{part}
				<br />
			</React.Fragment>
		));
	}

	return (
		<WithPagination isLoading={!article}>
			{article && (
				<>
					<NewsHeader>
						<NewsWrapper>
							<>
								<VerticalGrid gap={'4rem'}>
									<NewsSubtitle>{article.type.name}</NewsSubtitle>
									<NewsTitle>{article.title}</NewsTitle>
									<NewsSubtitle>
										{useParseDate(article.published_at)}
									</NewsSubtitle>
								</VerticalGrid>
							</>
						</NewsWrapper>
					</NewsHeader>
					<NewsWrapper marginTop={'4rem'}>
						<Text>{content}</Text>
					</NewsWrapper>
					<NewsWrapper marginTop={'9rem'}>
						<NewsFooter>
							<StyledFlex justify='flex-start'>
								<Author>
									<Title size='big'>Johanna Zoelch</Title>
									<Text>Managing Director</Text>
								</Author>
								<div>
									<Text>Johanna.zoelch@lumin-am.com </Text>
									<Text>+49 69 678333 42</Text>
								</div>
							</StyledFlex>
						</NewsFooter>
					</NewsWrapper>
					<Footer type='logoOnly' />
				</>
			)}
		</WithPagination>
	);
};

export default NewsDetail;
