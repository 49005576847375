import React from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import { t } from "../../translations/en";
import CustomButton from "../../components/Button/Button";
import { routes } from "../../routes/routes";
import { useHistory } from "react-router";
import { useSetHeader } from "../../hooks/useSetHeader";

const ExceptionTitle = styled.h2`
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.color.main};
`;

const ExceptionMessage = styled.h3`
  font-size: ${({ theme }) => theme.font.size.title_M};
  line-height: ${({ theme }) => theme.font.lineHeight.title_M};
`;

const Exception: React.FC = () => {
  const history = useHistory();
  useSetHeader({
    title: t.exceptionPage.title,
    hasSubtitle: false,
    isStaticPage: true,
  });
  return (
    <MainContainer marginTop={"4.4rem"}>
      <VerticalGrid gap="1.5rem">
        <div>
          <ExceptionTitle>{t.exceptionPage.title}</ExceptionTitle>
          <ExceptionMessage>{t.exceptionPage.message}</ExceptionMessage>
        </div>
        <div>
          <CustomButton
            content={t.exceptionPage.back}
            size="small"
            colorTheme="dark"
            onClick={() => history.push(`${routes.recommendedFunds}/1`)}
          />
        </div>
      </VerticalGrid>
    </MainContainer>
  );
};

export default Exception;
