import styled from "styled-components";

const TableRow = styled.div<{
  colorTheme?: string | undefined;
  columnPattern: string;
  type?: "recommendations";
  gap?: string;
}>`
  border: none;
  display: grid;
  grid-template-columns: ${({ columnPattern }) => columnPattern};
  background: transparent;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
  position: relative;
  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 3rem;
    background: ${({ theme, colorTheme }) =>
      colorTheme ? theme.color[colorTheme] : "transparent"};
  }
`;

export default TableRow;
