export const useGetRowColor = (potential: string) => {
	const colorArray = ['main', 'lightBlue', 'yellow', 'white'];
	switch (`${potential}`) {
		case '3':
			return colorArray[0];
		case '2':
			return colorArray[1];
		case '1':
			return colorArray[2];
		default:
			return colorArray[3];
	}
};
