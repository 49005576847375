import React from "react";
import styled from "styled-components";
import RecommendedFundListTableHeader from "../RecommendedFundListTableHeader/RecommendedFundListTableHeader";
import RecommendedFundListTableItemWrapper from "../RecommendedFundListTableItemWrapper/RecommendedFundListTableItemWrapper";
import { footerType } from "../../types/footerTypes";
import { ISingleRecommendation } from "../../services/FunderService";
import Loader from "../Loader/Loader";
import WithPagination from "../WithPagination/WithPagination";
import Pagination from "../Pagination/Pagination";
import InformationMessage from "../InformationMessage/InformationMessage";
import Flex from "../Flex/Flex";
import CustomButton from "../Button/Button";
import { routes } from "../../routes/routes";
import { t } from "../../translations/en";
import { useHistory } from "react-router";

interface IRecommendedFundListTableWrapper {
  type?: footerType;
  data: Array<ISingleRecommendation> | null;
  handleSort: (sortBy: string, scope: string) => void | null;
  marginTop?: string;
  currentPage: number | string;
  pagesAmount: number;
  handlePageChange: (page: number, id?: string, peergroup?: string) => void;
  id: string;
  ifRequestLink?: boolean;
  ifLastHeaderCell?: boolean;
  isHeaderShorter?: boolean;
  peergroup?: string;
  isLoading?: boolean;
  doRedirect?: boolean;
  peergroupPage?: boolean;
  isPeergroupPage?: boolean;
  isAlternativesPage?: boolean;
  scope?: string;
  addRecommendationButton?: boolean;
  currentRecommendations?: boolean;
}

const StyledPagination = styled(Pagination)`
  margin-top: 0;
`;

const RecommendedFundListTableWrapper: React.FC<IRecommendedFundListTableWrapper> = ({
  data,
  handleSort,
  handlePageChange,
  currentPage,
  pagesAmount,
  id,
  marginTop,
  ifRequestLink,
  ifLastHeaderCell,
  peergroup,
  isLoading = false,
  doRedirect = true,
  peergroupPage,
  isPeergroupPage = false,
  isAlternativesPage = false,
  scope,
  addRecommendationButton = true,
  currentRecommendations = false,
}) => {
  const history = useHistory();
  return (
    <WithPagination isLoading={isLoading}>
      {data ? (
        <>
          <RecommendedFundListTableHeader
            ifLastHeaderCell={ifLastHeaderCell}
            marginTop={marginTop}
            handleSort={handleSort}
            peergroupPage={peergroupPage}
            isPeergroupPage={isPeergroupPage}
            isAlternativesPage={isAlternativesPage}
            scope={scope}
            currentRecommendations={currentRecommendations}
          />

          <>
            {data.length ? (
              <RecommendedFundListTableItemWrapper
                ifRequestLink={ifRequestLink}
                data={data}
                doRedirect={doRedirect}
                isPeergroupPage={isPeergroupPage}
                isAlternativesPage={isAlternativesPage}
                currentRecommendations={currentRecommendations}
              />
            ) : (
              <InformationMessage />
            )}
          </>
        </>
      ) : (
        <Loader />
      )}
      {data && (
        <>
          <Flex
            justify={addRecommendationButton ? "space-between" : "flex-end"}
            align="flex-end"
            marginTop="3rem"
          >
            {addRecommendationButton && (
              <CustomButton
                content={t.addRecommendations}
                size="small"
                colorTheme="dark"
                onClick={() => history.push(routes.addRecommendations)}
              />
            )}
            <StyledPagination
              pagesAmount={pagesAmount}
              handleChange={(currentPage) =>
                handlePageChange(currentPage, id, peergroup)
              }
              currentPage={currentPage}
            />
          </Flex>
        </>
      )}
    </WithPagination>
  );
};

export default RecommendedFundListTableWrapper;
