import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
	font-weight: ${({ theme }) => theme.font.weight.extraBold};
	font-size: ${({ theme }) => theme.font.size.title_M};
	line-height: ${({ theme }) => theme.font.lineHeight.title_M};
	@media screen and (min-width: 768px) {
		font-size: ${({ theme }) => theme.font.size.title_L};
		line-height: ${({ theme }) => theme.font.lineHeight.title_L};
	}
`;
const CustomSpan = styled.span`
	display: block;
`;

interface ILandingPageTitle {
	title1: string;
}

const LandingPageTitle: React.FC<ILandingPageTitle> = ({ title1 }) => {
	return (
		<Title>
			<CustomSpan>{title1}</CustomSpan>
		</Title>
	);
};

export default LandingPageTitle;
