import React from 'react';
import styled, { css } from 'styled-components';
import ListLegendItemWrapper from '../ListLegendItemWrapper/ListLegendItemWrapper';
import { t } from '../../translations/en';
import { Link } from 'react-router-dom';
import Theme from '../../themes/MainTheme';
import Button from '../Button/Button';
import Flex from '../Flex/Flex';
import BackButton from '../BackButton/BackButton';
import { routes } from '../../routes/routes';
import { useSetModal } from '../../hooks/useSetModal';
import Tooltip from '../../components/Tooltip/Tooltip';
import Caption from '../Caption/Caption';

interface IListLegendWrapper {
	content?: string;
	destination?: string;
	isPotential?: boolean;
	isPeergroupDetails?: boolean;
	peergroup?: string;
}

const StyledSpan = styled.span`
	:not(:last-of-type) {
		margin-right: 1rem;
	}
`;

const LinkedButton = styled(Button)`
	width: 100%;
	border-color: ${({ theme }) => theme.color.main};
`;

const PeergroupTitle = styled.span`
	font-size: ${({ theme }) => theme.font.size.text_XL};
	font-weight: ${({ theme }) => theme.font.weight.bold};
`;

const PeergroupText = styled.span`
	font-size: ${({ theme }) => theme.font.size.table_header};
`;

const PeergroupWrapper = styled.div<{ isPeergroupDetails?: boolean }>`
	${({ isPeergroupDetails }) =>
		isPeergroupDetails &&
		css`
			width: 100%;
			display: flex;
			justify-content: space-between;
			margin-top: 2.5rem;
		`}
`;

const ListLegendWrapper: React.FC<IListLegendWrapper> = ({
	content,
	isPeergroupDetails,
	destination,
	isPotential = false,
	peergroup,
}) => {
	const highText = isPotential
			? t.potential.highPotential
			: t.coverage.highCoverage,
		mediumText = isPotential
			? t.potential.mediumPotential
			: t.coverage.mediumCoverage,
		lowText = isPotential ? t.potential.lowPotential : t.coverage.lowCoverage;
	const highTooltip = isPotential
			? t.potential.highTooltip
			: t.coverage.highTooltip,
		mediumTooltip = isPotential
			? t.potential.mediumTooltip
			: t.coverage.mediumTooltip,
		lowTooltip = isPotential ? t.potential.lowTooltip : t.coverage.lowTooltip;

	const setModal = useSetModal();

	const handleOpenGapAnalysisModal = () => {
		setModal({
			visible: true,
			title: t.recommendedFundsPage.buttonContent,
			component: 'GapAnalysisModal',
			doOverflow: false,
		});
	};

	return (
		<>
			<Flex justify='space-between'>
				{content ? (
					<Flex justify='flex-start'>
						{destination ? (
							<Flex marginTop='0.5rem'>
								<StyledSpan>
									<Tooltip
										tooltip={t.searchByPeergroupButtonTooltip}
										align={'left'}
										textAlign='left'
										headerButtons={true}
									>
										<LinkedButton
											colorTheme='dark'
											size={'small'}
											content={content}
											onClick={handleOpenGapAnalysisModal}
										/>
									</Tooltip>
								</StyledSpan>
								<StyledSpan>
									<Link to={`${routes.newFunds}/1`}>
										<LinkedButton
											colorTheme='dark'
											size={'small'}
											content={t.recommendedFundsPage.buttonNewFunds}
										/>
									</Link>
								</StyledSpan>
								<StyledSpan>
									<Link to={routes.recommendationChart}>
										<LinkedButton
											colorTheme='dark'
											size={'small'}
											content={t.recommendedFundsPage.buttonMostRecommendations}
										/>
									</Link>
								</StyledSpan>
								{/* <StyledSpan>
                  <Link to={routes.table1}>
                    <LinkedButton
                      colorTheme="dark"
                      size={"small"}
                      content={t.recommendedFundsPage.buttonOverview}
                    />
                  </Link>
                </StyledSpan> */}
							</Flex>
						) : null}
					</Flex>
				) : isPeergroupDetails ? null : (
					<BackButton marginRight='1rem' />
				)}
			</Flex>
			<PeergroupWrapper isPeergroupDetails={isPeergroupDetails}>
				{isPeergroupDetails ? (
					<div>
						<PeergroupTitle>{t.peergrupDetails.peergroup}</PeergroupTitle>{' '}
						<PeergroupText>{peergroup && peergroup}</PeergroupText>
					</div>
				) : null}
				<Flex
					justify={isPeergroupDetails ? 'center' : 'flex-end'}
					marginTop={isPeergroupDetails ? '0' : '3.5rem'}
				>
					{isPotential ? (
						<Caption type='performance' />
					) : (
						<>
							<ListLegendItemWrapper
								bgColor={Theme.color.main}
								content={highText}
								tooltipContent={highTooltip}
							/>
							<ListLegendItemWrapper
								bgColor={Theme.color.lightBlue}
								content={mediumText}
								tooltipContent={mediumTooltip}
							/>
							<ListLegendItemWrapper
								bgColor={Theme.color.yellow}
								content={lowText}
								tooltipContent={lowTooltip}
								align='right'
							/>
						</>
					)}
				</Flex>
			</PeergroupWrapper>
		</>
	);
};

export default ListLegendWrapper;
