import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import { RouteComponentProps } from "react-router-dom";
import ListLegendWrapper from "../../components/ListLegendWrapper/ListLegendWrapper";
import FundListTableWrapper from "../../components/FundListTableWrapper/FundListTableWrapper";
import Footer from "../../components/Footer/Footer";
import { FunderService } from "../../services/FunderService";
import { IGetGapAnalysis } from "../../services/FunderService";
import { handleGetCurrentMonthAndYear } from "../../utils/Dates";
import { useSetHeader } from "../../hooks/useSetHeader";
import { useStoreActions } from "../../easy-peasy/hooks/hooks";
import { t } from "../../translations/en";
const FundFooter = styled(Footer)`
  margin-top: 4rem;
`;

interface IProps {
  id: string;
}

interface IFundListPage extends RouteComponentProps<IProps> {
  // needs to be empty
}

const FundListPage: React.FC<IFundListPage> = (props: IFundListPage) => {
  const currentMonthAndYear = handleGetCurrentMonthAndYear();
  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );

  useSetHeader({
    title: `${t.fundPageList.title}`,
  });
  const [gapData, setGapData] = useState<Array<IGetGapAnalysis> | undefined>(
    undefined
  );
  const [sellerName, setSellerName] = useState<string>("");

  useEffect(() => {
    const id = props.match.params.id;
    //push to store
    FunderService.getGapAnalysisById(id)
      .then((response) => {
        const data = JSON.parse(response);
        setGapData(data.peergroups);
        headerAction({
          title: `${t.fundPageList.title}`,
          titleName: `${data.seller.name}`,
        });
        setSellerName(data.seller.name);
      })
      .catch((error) => console.log(error));
  }, []);
  return (
    <MainContainer>
      <ListLegendWrapper />
      <FundListTableWrapper
        id={props.match.params.id}
        sellerName={sellerName}
        data={gapData}
      />
      <FundFooter type="logoOnly" />
    </MainContainer>
  );
};

export default FundListPage;
