export const routes = {
	homepage: '/',
	signIn: '/signin',
	signup: '/signup',
	passwordRemind: '/forgot-password',
	passwordChange: '/change-password',
	contact: '/contact',
	landingPage: '/landing-page',
	landingView: '/landing-view',
	fundList: '/fund-list',
	recommendedFunds: '/recommended-funds',
	table1: '/fund-seller-potential',
	globalInvestors: '/asset-manager-scorecard',
	assetManagerView: '/asset-manager-view',
	newFunds: '/new-funds',
	fund: '/fund',
	logout: '/logout',
	addRecommendations: '/add-recommendations',
	requestCompetitorAnalysis: '/request-competitor-analysis',
	peergroupDetails: '/peergroup-details',
	news: '/news',
	aboutUs: '/about-us',
	legalInformation: '/legal-information',
	exception: '/exception',
	recommendationChart: '/recommendation-chart',
	peergroupPage: '/peergroup-page',
	previewPage: '/preview-page',
	gapPeergroupPage: '/gap-peergroup-page',
};
