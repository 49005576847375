import React from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import Caption from "../../components/Caption/Caption";
import FundItem from "../../components/FundItem/FundItem";
import TabContainer from "../../components/TabContainer/TabContainer";
import Title from "../../components/Title/Title";
import { t } from "../../translations/en";
import HorizontalGrid from "../../components/HorizontalGrid/HorizontalGrid";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import TableRow from "../../components/TableRow/TableRow";
import TableItem from "../../components/TableItem/TableItem";
import { useGetPerformance } from "../../hooks/useGetPerformance";
import Footer from "../../components/Footer/Footer";
import CustomButton from "../../components/Button/Button";
import { useGetAverageColor } from "../../hooks/useGetAverageColor";
import { useHistory } from "react-router";
import { routes } from "../../routes/routes";
import { replaceWhitespacesWithNbsp } from "../../utils/Strings";
import InformationMessage from "../../components/InformationMessage/InformationMessage";
import { useSetHeader } from "../../hooks/useSetHeader";
import { useStoreActions } from "../../easy-peasy/hooks/hooks";
import { FunderService } from "../../services/FunderService";
import { useCheckDate } from "../../hooks/useCheckDate";
import Tooltip from "../../components/Tooltip/Tooltip";
import BackButton from "../../components/BackButton/BackButton";
import Flex from "../../components/Flex/Flex";

type DataType = {
  shareClass: string;
  peergroup: string;
  fundSeller: string;
  startDate: string;
  netFlows: number;
  shortTermPerformance: number;
  longTermPerformance: number;
  rating: number;
};

const Wrapper = styled.div`
  margin-top: 5.5rem;
`;

const HighlightedItem = styled.div`
  background: ${({ theme }) => theme.color.highlight};
`;

const SmallTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.text_XS};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeight.text_S};
`;

const StyledTitle = styled(Title)<{ center?: boolean }>`
  text-align: ${({ center }) => (center ? "center" : "left")};
  padding: 0 3rem;
`;

const HighlightedTitle = styled(HighlightedItem)`
  min-height: 7.3rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
  :before {
    content: "";
    position: absolute;
    height: calc(100% + 1.4rem);
    width: 100%;
    left: 0;
    top: -0.7rem;
    background: ${({ theme }) => theme.color.highlight};
    z-index: -1;
  }
`;

const StyledCustomButton = styled(CustomButton)`
  margin-top: 3rem;
`;

const GlobalInvestors: React.FC = () => {
  const [currentRecommendations, setCurrentRcomemndations] = React.useState<
    Array<any>
  >([]);
  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );
  useSetHeader({
    title: `${t.assetManagerScorecard.title}`,
  });

  // fetch
  React.useEffect(() => {
    FunderService.getAssetManagerScorecard()
      .then((response) => {
        const data = JSON.parse(response);
        const dataArray = data.results.map((item: any) => {
          return {
            shareClass: item.fund.share_class.name,
            peergroup: item.fund.peergroup.name,
            fundSeller: item.seller.name,
            startDate: item.start_date,
            netFlows: item.fund.million_net_flow_for_last_3_months,
            shortTermPerformance: item.fund.short_term_performance,
            longTermPerformance: item.fund.long_term_performance,
            rating: item.fund.morningstar_rating_overall,
          };
        });
        headerAction({
          title: `${t.assetManagerScorecard.title}`,
          titleName: `${data.asset_manager.name}`,
        });
        setCurrentRcomemndations(dataArray);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const history = useHistory();
  return (
    <>
      <MainContainer>
        <Flex justify="space-between">
          <BackButton />
          <Caption type="average" />
        </Flex>
        <Wrapper>
          <FundItem title={t.assetManagerScorecard.tableTitle}>
            <>
              <TabContainer>
                <HorizontalGrid
                  columnPattern={
                    "repeat(5, 2fr) repeat(3, minmax(auto, 13rem))"
                  }
                  gap={"0rem"}
                  align="center"
                >
                  <StyledTitle size="big" colorTheme="dark">
                    {t.shareClass}
                  </StyledTitle>
                  <StyledTitle size="big" colorTheme="dark">
                    {t.peergroup}
                  </StyledTitle>
                  <HighlightedTitle>
                    <StyledTitle size="big" colorTheme="dark">
                      {t.fundSeller}
                    </StyledTitle>
                  </HighlightedTitle>
                  <StyledTitle size="big" colorTheme="dark">
                    {t.startDate}
                  </StyledTitle>
                  <StyledTitle size="big" colorTheme="dark">
                    {t.netFlow1}
                    <SmallTitle>
                      {" "}
                      {replaceWhitespacesWithNbsp(t.netFlow2)}
                    </SmallTitle>
                  </StyledTitle>
                  <StyledTitle center size="big" colorTheme="dark">
                    <Tooltip
                      tooltip={t.shortTermPerformanceTooltip}
                      textAlign="left"
                    >
                      {t.shortTermPerformance}
                    </Tooltip>
                  </StyledTitle>
                  <StyledTitle center size="big" colorTheme="dark">
                    <Tooltip
                      tooltip={t.longTermPerformanceTooltip}
                      textAlign="left"
                    >
                      {t.longTermPerformance}
                    </Tooltip>
                  </StyledTitle>
                  <StyledTitle center size="big" colorTheme="dark">
                    <Tooltip
                      tooltip={t.ratingTooltip}
                      align="right"
                      textAlign="left"
                    >
                      {t.rating}
                    </Tooltip>
                  </StyledTitle>
                </HorizontalGrid>
              </TabContainer>
              <VerticalGrid gap="0.7rem">
                {currentRecommendations.length ? (
                  currentRecommendations.map(
                    (
                      {
                        shareClass,
                        peergroup,
                        fundSeller,
                        startDate,
                        netFlows,
                        shortTermPerformance,
                        longTermPerformance,
                        rating,
                      }: DataType,
                      index
                    ) => (
                      <TableRow
                        key={index}
                        columnPattern={
                          "repeat(5, 2fr) repeat(3, minmax(auto, 13rem))"
                        }
                        gap={"3rem"}
                      >
                        <TableItem>{useGetPerformance(shareClass)}</TableItem>
                        <TableItem>{useGetPerformance(peergroup)}</TableItem>
                        <HighlightedItem>
                          <TableItem>{useGetPerformance(fundSeller)}</TableItem>
                        </HighlightedItem>
                        <TableItem>{useCheckDate(startDate)}</TableItem>
                        <TableItem>
                          {useGetPerformance(
                            parseFloat(`${netFlows}`).toFixed(2)
                          )}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(shortTermPerformance)}
                          center
                        >
                          {useGetPerformance(shortTermPerformance)}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(longTermPerformance)}
                          center
                        >
                          {useGetPerformance(longTermPerformance)}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(rating, "positive")}
                          center
                        >
                          {useGetPerformance(rating)}
                        </TableItem>
                      </TableRow>
                    )
                  )
                ) : (
                  <InformationMessage />
                )}
              </VerticalGrid>
            </>
          </FundItem>
          <StyledCustomButton
            content={t.addRecommendations}
            size="small"
            colorTheme="dark"
            onClick={() => history.push(routes.addRecommendations)}
          />
        </Wrapper>
      </MainContainer>
      <Footer type="logoOnly" />
    </>
  );
};

export default GlobalInvestors;
