import React from 'react';
import styled from 'styled-components';
import MainContainer from '../../components/MainContainer/MainContainer';
import Text from '../../components/Text/Text';
import { t } from '../../translations/en';
import InformationList from '../../components/InformationList/InformationList';
import VerticalGrid from '../../components/VerticalGrid/VerticalGrid';
import Footer from '../../components/Footer/Footer';
import CompanyAddress from '../../components/CompanyAddress/CompanyAddress';
import { useSetHeader } from '../../hooks/useSetHeader';
import Title from '../../components/Title/Title';

const GridLayout = styled.div`
	display: grid;
	grid-row-gap: 3rem;
	@media screen and (min-width: 1024px) {
		grid-template-columns: 1fr 445px;
		grid-column-gap: 10rem;
	}
`;

const AboutUs: React.FC = () => {
	useSetHeader({
		title: t.aboutUsPage.title,
		subtitle: t.aboutUsPage.subtitle,
		isStaticPage: true,
	});
	return (
		<>
			<MainContainer marginTop='4.4rem'>
				<div></div>
			</MainContainer>
			<MainContainer marginTop='4.4rem'>
				<GridLayout>
					<VerticalGrid gap='3rem'>
						<VerticalGrid gap='2rem'>
							<Title size='big'>{t.aboutUsPage.header1}</Title>
							<Text>{t.aboutUsPage.textNew1}</Text>
							<Text>{t.aboutUsPage.textNew2}</Text>
							<Text>{t.aboutUsPage.textNew3}</Text>
						</VerticalGrid>
						<VerticalGrid gap='2rem'>
							<Title size='big'>{t.aboutUsPage.header2}</Title>
							<InformationList
								isAboutUs={true}
								gap='1rem'
								colorTheme='dark'
								list={t.aboutUsPage.list3}
							></InformationList>
						</VerticalGrid>
					</VerticalGrid>
					<div>
						<CompanyAddress />
					</div>
				</GridLayout>
			</MainContainer>
			<Footer type='logoOnly' />
		</>
	);
};

export default AboutUs;
