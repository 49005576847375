import todosModel, { TodosModel } from "./todos";
import auditModel, { AuditModel } from "./audit";
import headerData, { HeaderDataModel } from "./headerDataModel";
import peergroupModel, { PeergroupModel } from "./peergroupModel";
import peergroupFilterModel, {
  PeergroupFilterModel,
} from "./peergroupFilterModel";
import modalModel, { ModalModel } from "./modalModel";
import verifiedModel, { VerifiedModel } from "./vierifiedModel";
import sellerModel, { SellerModel } from "./sellerModel";
import gapModel, { GapModel } from "./gapModel";
import fundSellerPotentialModel, {
  FundSellerPotentialModel,
} from "./fundSellerPotentialModel";

export interface StoreModel {
  todos: TodosModel;
  audit: AuditModel;
  headerData: HeaderDataModel;
  peergroupModel: PeergroupModel;
  peergroupFilterModel: PeergroupFilterModel;
  modalModel: ModalModel;
  verifiedModel: VerifiedModel;
  sellerModel: SellerModel;
  gapModel: GapModel;
  fundSellerPotentialModel: FundSellerPotentialModel;
}

const storeModel: StoreModel = {
  todos: todosModel,
  audit: auditModel,
  headerData: headerData,
  peergroupModel: peergroupModel,
  peergroupFilterModel: peergroupFilterModel,
  modalModel: modalModel,
  verifiedModel: verifiedModel,
  sellerModel: sellerModel,
  gapModel: gapModel,
  fundSellerPotentialModel: fundSellerPotentialModel,
};

export default storeModel;
