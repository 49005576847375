import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import Footer from "../../components/Footer/Footer";
import Theme from "../../themes/MainTheme";
import { useHistory, useParams } from "react-router";
import { FunderService } from "../../services/FunderService";
import { useGetEnv } from "../../hooks/useGetEnv";
import { routes } from "../../routes/routes";
import { t } from "../../translations/en";
import Pagination from "../../components/Pagination/Pagination";
import WithPagination from "../../components/WithPagination/WithPagination";
import TabContainer from "../../components/TabContainer/TabContainer";
import InformationMessage from "../../components/InformationMessage/InformationMessage";
import { useSetHeader } from "../../hooks/useSetHeader";
import { handleGetCurrentMonthAndYear } from "../../utils/Dates";
import { useGetPerformance } from "../../hooks/useGetPerformance";
import Tooltip from "../../components/Tooltip/Tooltip";
import SortableItem from "../../components/SortableItem/SortableItem";
import { useHandleSort } from "../../hooks/useHandleSort";
import Flex from "../../components/Flex/Flex";

type HandleClickType = (href: string) => void;
type handlePageChangeType = (page: number) => void;
interface resultObjectInterface {
  id: number;
  fund_id: number;
  fund_name: string;
  peergroup: string;
  asset_manager: string;
  rating: string;
  fund_seller_id: number;
  fund_seller_name: string;
}

const Wrapper = styled.section`
  display: grid;
  grid-row-gap: 1rem;
`;

const Grid = styled.div`
  display: grid;
  grid-column-gap: 2rem;
  grid-template-columns: repeat(4, 3fr) 1fr;
`;

const TableHead = styled(TabContainer)`
  /* padding: 2rem 0; */
`;

const RefactoredSortableItem = styled(SortableItem)`
  height: auto;
`;

const StyledSortableItem = styled(RefactoredSortableItem)`
  padding: 0 1.5rem;
`;

const TableItem = styled.div<{
  background?: string | undefined;
  color?: string | undefined;
  bold?: boolean;
  center?: boolean;
}>`
  padding: 0.7rem 1.5rem;
  font-size: ${({ theme }) => theme.font.size.table_item};
  line-height: ${({ theme }) => theme.font.lineHeight.table_item};
  font-weight: ${({ theme, bold }) =>
    bold ? theme.font.weight.bold : theme.font.weight.medium};
  color: ${({ theme, color }) => (color ? color : theme.color.black)};
  background: ${({ background }) => (background ? background : "transparent")};
  justify-content: ${({ center }) => (center ? "center" : "flex-start")};
  display: flex;
  align-items: center;
  :first-of-type {
    padding: 0.7rem 1.5rem 0.7rem 1.5rem;
  }
  :last-of-type {
    padding: 0.7rem 0;
  }
`;

const Row = styled.button`
  border: none;
  background: transparent;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
`;

const NewFunds: React.FC = () => {
  const currentMonthAndYear = handleGetCurrentMonthAndYear();
  useSetHeader({
    title: t.newFunds.title,
  });
  const history = useHistory();
  const params = useParams<{ page: string }>();
  const [currentPage, setCurrentPage] = useState<number>(parseInt(params.page));
  const [pageCount, setPageCount] = useState<number>(0);
  const [orderBy, setOrderBy] = useState<string>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [results, setResults] = useState<Array<resultObjectInterface> | null>(
    null
  );
  const resultsPerPage = 50;

  const handlePageChange: handlePageChangeType = (page) => {
    setLoading(true);
    history.push(`${routes.newFunds}/${page}`);
    FunderService.getNewFundsThisMonth(page, orderBy)
      .then((response) => {
        const data = JSON.parse(response);
        setResults(data.results);
        setCurrentPage(page);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        setLoading(false);
        history.push(`${routes.newFunds}/1`);
      });
  };

  useEffect(() => {
    setLoading(true);
    FunderService.getNewFundsThisMonth(params.page, orderBy)
      .then((response) => {
        const data = JSON.parse(response);
        setPageCount(data.count / resultsPerPage);
        setCurrentPage(parseInt(params.page));
        setResults(data.results);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        history.push(`${routes.newFunds}/1`);
      });
  }, [params, orderBy]);

  const handleClick: HandleClickType = (href) => {
    history.push(`${routes.fund}/${href}`);
  };

  const handleSort = (sortBy: string) => {
    if (currentPage !== 1) {
      history.push(`${routes.newFunds}/1`);
    }
    setOrderBy(sortBy);
  };

  const handleSaveCurrentSortBy = useHandleSort(handleSort);

  return (
    <MainContainer marginTop="5.4rem">
      <WithPagination isLoading={isLoading}>
        <>
          <Wrapper>
            <TableHead>
              <Grid>
                <RefactoredSortableItem
                  headername={`fund__share_class__name`}
                  sortstatus="null"
                  onClick={handleSaveCurrentSortBy}
                  padding="0 0 0 1.5rem"
                >
                  {t.newFunds.fund}
                </RefactoredSortableItem>
                <StyledSortableItem
                  headername={`fund__peergroup__name`}
                  sortstatus="null"
                  onClick={handleSaveCurrentSortBy}
                >
                  {t.newFunds.peergroup}
                </StyledSortableItem>
                <StyledSortableItem
                  headername={`fund__asset_manager__name`}
                  sortstatus="null"
                  onClick={handleSaveCurrentSortBy}
                >
                  {t.newFunds.assetManager}
                </StyledSortableItem>
                <RefactoredSortableItem
                  headername={`seller__name`}
                  sortstatus="null"
                  onClick={handleSaveCurrentSortBy}
                >
                  {t.newFunds.fundSeller}
                </RefactoredSortableItem>
                <Tooltip tooltip={t.ratingTooltip} align="right">
                  <RefactoredSortableItem
                    headername={`fund__morningstar_rating_overall`}
                    sortstatus="null"
                    onClick={handleSaveCurrentSortBy}
                    center
                  >
                    {t.newFunds.rating}
                  </RefactoredSortableItem>
                </Tooltip>
              </Grid>
            </TableHead>
            <>
              {results && results.length ? (
                results.map(
                  ({
                    id,
                    fund_id,
                    fund_name,
                    peergroup,
                    asset_manager,
                    rating,
                    fund_seller_id,
                    fund_seller_name,
                  }: resultObjectInterface) => (
                    <Row
                      key={id}
                      onClick={() => handleClick(fund_id.toString())}
                    >
                      <Grid>
                        <TableItem
                          bold
                          background={Theme.color.main}
                          color={Theme.color.white}
                        >
                          {useGetPerformance(fund_name)}
                        </TableItem>
                        <TableItem>{useGetPerformance(peergroup)}</TableItem>
                        <TableItem>
                          {useGetPerformance(asset_manager)}
                        </TableItem>
                        <TableItem background={Theme.color.mainLight}>
                          {useGetPerformance(fund_seller_name)}
                        </TableItem>
                        <TableItem center>
                          {useGetPerformance(rating)}
                        </TableItem>
                      </Grid>
                    </Row>
                  )
                )
              ) : (
                <InformationMessage />
              )}
            </>
          </Wrapper>
          <Flex justify="flex-end">
            <Pagination
              pagesAmount={pageCount}
              handleChange={(page) => handlePageChange(page)}
              currentPage={currentPage}
            />
          </Flex>
          <Footer type="logoOnly" />
        </>
      </WithPagination>
    </MainContainer>
  );
};

export default NewFunds;
