import { Action } from 'easy-peasy';
import { action } from 'easy-peasy';

export interface GapModel {
	items: {
		isGap?: boolean;
		id?: number;
		label?: string;
	};
	addIsGap: Action<GapModel, { isGap?: boolean; id?: number; label?: string }>;
}

const gapModel: GapModel = {
	items: {
		isGap: false,
		id: 0,
		label: '',
	},
	addIsGap: action((state, payload) => {
		state.items.isGap = payload.isGap;
		state.items.id = payload.id;
		state.items.label = payload.label;
	}),
};

export default gapModel;
