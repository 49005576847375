import React, { useState } from "react";
import styled from "styled-components";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import DatePicker from "../DatePicker/DatePicker";
import { t } from "../../translations/en";
import Search from "../../components/Search/Search";
import { FunderService } from "../../services/FunderService";
import { useGetEnv } from "../../hooks/useGetEnv";
import Select from "../Select/Select";

const CustomSearch = styled(Search)`
  background: ${({ theme }) => theme.color.mainLight};
  height: 4.3rem;
`;

export interface Seller {
  value: number;
  label: string;
}

interface Fund {
  id: number;
  name_with_isin: string;
}

interface AddRecommendationsRecordInterface {
  formik: any;
  index: number;
  sellers: Seller[];
}

const AddRecommendationsRecord: React.FC<AddRecommendationsRecordInterface> = ({
  formik,
  index,
  sellers,
}) => {
  const API_URL = useGetEnv("API_URL");

  const [autocompleteList, setAutocompleteList] = useState<Array<object>>([{}]);
  const [isFundsFormValid, setFundsFormValid] = useState<boolean>(false);
  const [isListLoading, setListLoading] = useState<boolean>(false);
  const pageSize = 20;

  const filterSearchList = (value: string, page: number) => {
    if (value !== null) {
      setListLoading(true);
      FunderService.getFundList(value, page, pageSize)
        .then((response) => {
          const formattedData = JSON.parse(response).results.map((f: Fund) => ({
            value: f.id,
            name: f.name_with_isin,
          }));
          if (page === 1) {
            setAutocompleteList(formattedData);
          } else {
            setAutocompleteList([...autocompleteList, ...formattedData]);
          }
          setListLoading(false);
        })
        .catch((error) => {
          setListLoading(false);
        });
    }
  };

  return (
    <HorizontalGrid columnPattern={"repeat(4, 1fr)"} gap="1.6rem">
      <CustomSearch
        fontTheme={"black"}
        colorTheme="dark"
        name={`add_recommendation_requests[${index}].fund`}
        list={autocompleteList}
        label={t.landingPage.label2}
        handleChange={(value, page) => {
          filterSearchList(value, page);
          setFundsFormValid(false);
          formik.setFieldValue(
            `add_recommendation_requests[${index}].fund`,
            null
          );
        }}
        handleSelect={(value, id) => {
          formik.setFieldValue(
            `add_recommendation_requests[${index}].fund`,
            id
          );
          setFundsFormValid(value);
        }}
        isListLoading={isListLoading}
      />
      <Select
        colorTheme="dark"
        options={sellers}
        label={t.addRecommendationsPage.namePlaceholder}
        handleChange={({ value }) =>
          formik.setFieldValue(
            `add_recommendation_requests[${index}].seller`,
            value
          )
        }
      />
      <DatePicker
        colorTheme="dark"
        name={`add_recommendation_requests[${index}].start_date`}
        formik={formik}
        value={formik.values.add_recommendation_requests[index].start_date}
      />
      <DatePicker
        colorTheme="dark"
        name={`add_recommendation_requests[${index}].end_date`}
        formik={formik}
        value={formik.values.add_recommendation_requests[index].end_date}
      />
    </HorizontalGrid>
  );
};

export default AddRecommendationsRecord;
