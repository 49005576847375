import React, { useEffect, useState } from 'react';
import { RouteComponentProps, useParams, useLocation } from 'react-router-dom';
import MainContainer from '../../components/MainContainer/MainContainer';
import { t } from '../../translations/en';
import RecommendedFundListTableWrapper from '../../components/RecommendFundListTableWrapper/RecommendedFundListTableWrapper';
import {
	FunderService,
	ISingleRecommendation,
} from '../../services/FunderService';
import Footer from '../../components/Footer/Footer';
import { useSetHeader } from '../../hooks/useSetHeader';
import { useStoreActions } from '../../easy-peasy/hooks/hooks';
import GapPeergroupFilters from '../../components/GapPeergroupFilters/GapPeergroupFilters';
import Flex from '../../components/Flex/Flex';
import Caption from '../../components/Caption/Caption';
import styled from 'styled-components';
import WithPagination from '../../components/WithPagination/WithPagination';
import { useQuery } from './../../hooks/useQuery';

type handlePageChangeType = ({ page: number, type: string }) => void;
type FilterType = {
	id: string;
	name: string;
	url?: string;
	asset_class?: string;
};

interface IProps {
	id: string;
	page: string;
}

interface IRecommendedFunds extends RouteComponentProps<IProps> {
	// needs to be empty
}
export interface ObjectIndexer<T> {
	[id: string]: T;
}

interface IIsElementSorted extends ObjectIndexer<boolean> {
	fund: boolean;
	category: boolean;
	asset_manager: boolean;
	strategy: boolean;
	short_in_term_performance: boolean;
	long_in_term_performance: boolean;
	rating: boolean;
}

const TableTitle = styled.h3`
	color: #9c9c9c;
	font-size: 2rem;
	line-height: 3rem;
	font-weight: 700;
	text-align: left;
	margin-top: 3rem;
	margin-bottom: -3rem;
`;

const GapPeergroupPage: React.FC<IRecommendedFunds> = (
	props: IRecommendedFunds
) => {
	const headerAction = useStoreActions(
		actions => actions.headerData.addHeaderData
	);
	useSetHeader({
		title: `${t.peergroupHeader}`,
		searchComponent: 'GapPeergroupPageSearch',
	});
	const query = useQuery();
	const peergroupId = query.get('peergroupId');
	const sellerId = query.get('sellerId');
	const sellerName = query.get('sellerName');
	const strategyId = query.get('strategy');
	const [results, setResults] = useState<Array<ISingleRecommendation> | null>(
			null
		),
		[alternativeResults, setAlternativeResults] = useState<Array<
			ISingleRecommendation
		> | null>(null),
		[currentPageCount, setCurrentPageCount] = useState<number>(0),
		[alternativePageCount, setAlternativePageCount] = useState<number>(0),
		[orderBy, setOrderBy] = useState<string>(''),
		[gapOrderBy, setGapOrderBy] = useState<string>(''),
		[currentPage, setCurrentPage] = useState<number>(1),
		[alternativePage, setAlternativePage] = useState<number>(1),
		[isLoading, setLoading] = useState<boolean>(false),
		[isAlternativeLoading, setAlternativeLoading] = useState<boolean>(false),
		[strategiesFilter, setStrategiesFilter] = useState<Array<FilterType>>(),
		[peergroupFilter, setPeergroupFilter] = useState<Array<FilterType>>(),
		[strategyValue, setStrategyValue] = useState<string>(
			strategyId ? strategyId : ''
		),
		[internalExternal, setInternalExternal] = useState<string>(''),
		[fundSellerFilter, setfundSellerFilter] = useState<Array<FilterType>>(),
		[sellersOptions, setSellersOptions] = useState<any>(),
		[fundSellerId, setFundSellerId] = useState<string | number>(
			sellerId ? sellerId : ''
		),
		[firstLoading, setFirstLoading] = useState<boolean>(true),
		[paramLoading, setParamLoading] = useState<boolean>(false);

	const currentRecommendationsScope = 'current-recommendations-scope';
	const alternativeRecommendationsScope = 'alternative-recommendations-scope';
	const pageSize = 20;
	const location = useLocation();

	// ***** FETCH FUNCTIONS
	const fetchAlternativesWithPageCalc = (
		sellersExcluded,
		peergroupId,
		page,
		strategy,
		orderBy
	) => {
		if (fundSellerId !== '' && !paramLoading) {
			setAlternativeLoading(true);
			FunderService.getGapPeergroupAlternatives(
				sellersExcluded,
				peergroupId,
				page,
				strategy,
				gapOrderBy,
				pageSize
			)
				.then(response => {
					const data = JSON.parse(response);
					setAlternativeResults(data.results);
					setAlternativePageCount(Math.ceil(data.count / pageSize));
					setAlternativeLoading(false);
				})
				.catch(error => {
					setAlternativeLoading(false);
					setAlternativeResults([]);
					console.log(error);
				});
		} else {
			setGapOrderBy('');
		}
	};

	const fetchAlternatives = (
		sellersExcluded,
		peergroupId,
		page,
		strategy,
		orderBy
	) => {
		if (fundSellerId !== '') {
			setAlternativeLoading(true);
			FunderService.getGapPeergroupAlternatives(
				sellersExcluded,
				peergroupId,
				page,
				strategy,
				gapOrderBy,
				pageSize
			)
				.then(response => {
					const data = JSON.parse(response);
					setAlternativeResults(data.results);
					setAlternativeLoading(false);
					setLoading(false);
				})
				.catch(error => {
					setAlternativeLoading(false);
					setAlternativeResults([]);
					console.log(error);
				});
		} else {
			setGapOrderBy('');
		}
	};

	const fetchCurrent = (
		currentPage,
		fundSellerId,
		strategyValue,
		peergroupId,
		orderBy
	) => {
		setLoading(true);
		if (!firstLoading) {
			FunderService.getGapPeergroupCurrent(
				currentPage,
				fundSellerId,
				strategyValue,
				peergroupId,
				orderBy,
				pageSize
			)
				.then(response => {
					const data = JSON.parse(response);
					setResults(data.results);
					setLoading(false);
				})
				.catch(error => {
					setResults([]);
					console.log(error);
					setLoading(false);
				});
		}
	};
	// *****

	// **** UTILS FUNTIONS
	const handlePageChangeResponse = (response: any) => {
		const data = JSON.parse(response);
		setResults(data.results);
		setCurrentPageCount(Math.ceil(data.count / pageSize));
		setLoading(false);
		window.scrollTo(0, 0);
	};

	const handlePageChange: handlePageChangeType = ({ page, type }) => {
		if (!firstLoading) {
			if (type === 'alternatives') {
				setAlternativePage(page);
			}
			if (type === 'current') {
				setCurrentPage(page);
			}
		}
	};

	const handleSort = (sortBy: string, scope: string) => {
		if (scope === currentRecommendationsScope) {
			setOrderBy(sortBy);
		}
		if (scope === alternativeRecommendationsScope) {
			setGapOrderBy(sortBy);
		}
	};
	// *****

	useEffect(() => {
		if (firstLoading) {
			setLoading(true);
			setParamLoading(true);
			FunderService.getFundSellers()
				.then(response => {
					const data = JSON.parse(response);
					setfundSellerFilter(data);
					setFirstLoading(false);
				})
				.catch(error => console.log(error));

			FunderService.getStrategiesFilter()
				.then(response => {
					const data = JSON.parse(response);
					setStrategiesFilter(data);
				})
				.catch(error => console.log(error));
		}
	}, []);

	useEffect(() => {
		FunderService.getGapPeergroupCurrent(
			1,
			fundSellerId,
			strategyValue,
			peergroupId,
			orderBy
		)
			.then(response => {
				handlePageChangeResponse(response);
				const data = JSON.parse(response);
				headerAction({
					title: `${t.peergroupHeader}`,
					titleName: `${data.peergroup.name}`,
					searchComponent: 'GapPeergroupPageSearch',
				});
				setLoading(false);
				setParamLoading(false);
			})
			.catch(e => {
				setResults([]);
				setLoading(false);
				setParamLoading(false);
			});
		fetchAlternativesWithPageCalc(
			fundSellerId,
			peergroupId,
			1,
			strategyValue,
			gapOrderBy
		);
	}, [strategyValue, fundSellerId, peergroupId]);

	useEffect(() => {
		if (!firstLoading) {
			if (currentPage === 1) {
				fetchCurrent(1, fundSellerId, strategyValue, peergroupId, orderBy);
			} else {
				handlePageChange({ page: 1, type: 'current' });
			}
		}
	}, [orderBy]);

	useEffect(() => {
		if (!firstLoading) {
			if (alternativePage === 1) {
				fetchAlternatives(
					fundSellerId,
					peergroupId,
					1,
					strategyValue,
					gapOrderBy
				);
			} else {
				handlePageChange({ page: 1, type: 'alternatives' });
			}
		}
	}, [gapOrderBy]);

	useEffect(() => {
		if (!firstLoading) {
			fetchCurrent(
				currentPage,
				fundSellerId,
				strategyValue,
				peergroupId,
				orderBy
			);
		}
	}, [currentPage]);

	useEffect(() => {
		if (!firstLoading) {
			fetchAlternatives(
				fundSellerId,
				peergroupId,
				alternativePage,
				strategyValue,
				gapOrderBy
			);
		}
	}, [alternativePage]);

	return (
		<WithPagination isLoading={isLoading || isAlternativeLoading}>
			<MainContainer>
				<Flex justify='flex-end'>
					<Caption type='performance' />
				</Flex>
				<GapPeergroupFilters
					strategies={strategiesFilter}
					peergroups={peergroupFilter}
					setStrategyValue={setStrategyValue}
					setInternalExternal={setInternalExternal}
					isClearable
					fundSellers={fundSellerFilter}
					sellers={sellersOptions}
					setFundSellerId={setFundSellerId}
					sellerId={sellerId}
					sellerName={sellerName}
					strategyId={strategyId}
					peergroupId={peergroupId}
					isPeergroupPage={true}
				/>
				{!isLoading && (
					<TableTitle>{t.peergrupDetails.currentRecommendations}</TableTitle>
				)}
				<RecommendedFundListTableWrapper
					ifRequestLink={false}
					id={peergroupId}
					pagesAmount={currentPageCount}
					currentPage={currentPage}
					handlePageChange={value =>
						handlePageChange({ page: value, type: 'current' })
					}
					handleSort={handleSort}
					data={results}
					isLoading={isLoading}
					doRedirect={true}
					isPeergroupPage={true}
					scope={currentRecommendationsScope}
					addRecommendationButton={false}
					currentRecommendations={true}
				/>

				{fundSellerId !== 0 &&
				fundSellerId !== '' &&
				alternativeResults &&
				alternativeResults.length > 0 ? (
					<>
						{!isAlternativeLoading && (
							<TableTitle>{t.peergrupDetails.alternatives}</TableTitle>
						)}
						<RecommendedFundListTableWrapper
							ifRequestLink={false}
							id={peergroupId}
							pagesAmount={alternativePageCount}
							currentPage={alternativePage}
							handlePageChange={value =>
								handlePageChange({ page: value, type: 'alternatives' })
							}
							handleSort={handleSort}
							data={alternativeResults}
							isLoading={isAlternativeLoading}
							doRedirect={true}
							isAlternativesPage={true}
							scope={alternativeRecommendationsScope}
							addRecommendationButton={false}
						/>
					</>
				) : null}

				<Footer type={'logoOnly'} />
			</MainContainer>
		</WithPagination>
	);
};

export default GapPeergroupPage;
