import React from 'react';
import { ThemeProvider } from 'styled-components';
import { ToastProvider } from 'react-toast-notifications';
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from 'react-router-dom';

// hocs
import ScrollToTop from './hocs/ScrollToTop/ScrollToTop';

// themes
import GlobalStyles from './themes/GlobalStyles';
import Theme from './themes/MainTheme';

// routes
import { routes } from './routes/routes';
import Modal from './components/Modal/Modal';

// pages
import Header from './components/Header/Header';
import Exception from './views/Exception/Exception';
import AssetManagerView from './views/AssetManagerView/AssetManagerView';
import RecommendationChart from './views/RecommendationChart/RecommendationChart';
import PasswordRemind from './views/PasswordRemind/PasswordRemind';
import Contact from './views/Contact/Contact';
import LandingPage from './views/LandingPage/LandingPage';
import LandingView from './views/LandingView/LandingView';
import RecommendedFundsPage from './views/RecommendedFunds/RecommendedFundsPage';
import RestrictedRoute from './components/RestrictedRoute/RestrictedRoute';
import PublicRoute from './components/PublicRoute/PublicRoute';
import LoginRoute from './components/LoginRoute/LoginRoute';
import PasswordChange from './views/PasswordChange/PasswordChange';
import NewsDetail from './views/NewsDetail/NewsDetail';
import RequestCompetitorAnalysis from './views/RequestCompetitorAnalysis/RequestCompetitorAnalysis';
import FundListPage from './views/FundListPage/FundListPage';
import Table1 from './views/Table1/Table1';
import Fund from './views/Fund/Fund';
import NewFunds from './views/NewFunds/NewFunds';
import PeergroupDetails from './views/PeergroupDetails/PeergroupDetails';
import AboutUs from './views/AboutUs/AboutUs';
import News from './views/News/News';
import Logout from './views/Logout/Logout';
import LegalInformation from './views/LegalInformation/LegalInformation';
import AddRecommendations from './views/AddRecommendations/AddRecommendations';
import GlobalInvestors from './views/GlobalInvestors/GlobalInvestors';
import PeergroupPage from './views/PeergroupPage/PeergroupPage';
import PreviewPage from './views/PreviewPage/PreviewPage';
import GapPeergroupPage from './views/GapPeergroupPage/GapPeergroupPage';
import CookiesBanner from './components/CookiesBanner/CookiesBanner';

const App: React.FC = () => {
	return (
		<>
			<GlobalStyles />
			<ThemeProvider theme={Theme}>
				<ToastProvider>
					<Router>
						<ScrollToTop>
							<Header />
							<Switch>
								<LoginRoute path={routes.signIn} component={LandingView} />
								<PublicRoute
									path={routes.passwordRemind}
									component={PasswordRemind}
								/>
								<PublicRoute
									path={routes.passwordChange}
									component={PasswordChange}
								/>
								<RestrictedRoute
									path={routes.homepage}
									component={() => <Redirect to={`${routes.signIn}`} />}
									exact
								/>
								<RestrictedRoute
									path={routes.landingPage}
									component={LandingPage}
									exact
								/>
								<RestrictedRoute
									path={`${routes.fundList}/:id`}
									component={props => <FundListPage {...props} />}
								/>
								<RestrictedRoute
									path={`${routes.recommendedFunds}/:page`}
									component={props => <RecommendedFundsPage {...props} />}
									exact
								/>
								<RestrictedRoute path={routes.table1} component={Table1} />
								<RestrictedRoute
									path={`${routes.newFunds}/:page`}
									component={NewFunds}
								/>
								<RestrictedRoute path={`${routes.fund}/:id`} component={Fund} />
								<RestrictedRoute
									path={`${routes.peergroupDetails}/:id/:peergroup`}
									component={PeergroupDetails}
								/>
								<RestrictedRoute path={routes.logout} component={Logout} />
								<RestrictedRoute
									path={routes.globalInvestors}
									component={GlobalInvestors}
								/>
								<RestrictedRoute
									path={`${routes.assetManagerView}/:id/:page`}
									component={AssetManagerView}
								/>
								<RestrictedRoute
									path={routes.addRecommendations}
									component={AddRecommendations}
								/>
								<RestrictedRoute
									path={routes.requestCompetitorAnalysis}
									component={RequestCompetitorAnalysis}
								/>
								<RestrictedRoute
									path={`${routes.peergroupPage}/:id/:page`}
									component={props => <PeergroupPage {...props} />}
								/>
								<RestrictedRoute
									path={`${routes.gapPeergroupPage}`}
									component={props => <GapPeergroupPage {...props} />}
								/>
								<PublicRoute path={routes.aboutUs} component={AboutUs} />
								<PublicRoute
									path={routes.legalInformation}
									component={LegalInformation}
								/>
								<PublicRoute path={routes.contact} component={Contact} />
								<PublicRoute path={routes.news} component={News} exact />
								<PublicRoute
									path={`${routes.news}/:id`}
									component={NewsDetail}
								/>
								<RestrictedRoute
									path={routes.recommendationChart}
									component={RecommendationChart}
								/>
								<PublicRoute
									path={routes.previewPage}
									component={PreviewPage}
								/>
								<Route component={Exception} />
							</Switch>
						</ScrollToTop>
						<Modal />
						<CookiesBanner />
					</Router>
				</ToastProvider>
			</ThemeProvider>
		</>
	);
};

export default App;
