import React from "react";
import styled, { css } from "styled-components";

interface SortableItemInterface {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  headername: string;
  sortstatus: string;
  children: any;
  center?: boolean;
  className?: string;
  padding?: string;
  right?: string;
}

const SortableHeader = styled.div<{ right?: string }>`
  position: relative;
  pointer-events: none;
  user-select: none;
  :after {
    content: "\\e800";
    position: absolute;
    top: 50%;
    right: ${({ right }) => (right ? right : "inherit")};
    padding: 0.5rem;
    transform: translateY(-50%) rotateZ(-90deg);
    font-family: ${({ theme }) => theme.font.icon};
    font-size: 0.5rem;
  }
`;

const Col = styled.div<{ center: boolean; padding: string }>`
  height: 7.3rem;
  padding: ${({ padding }) => padding};
  font-size: ${({ theme }) => theme.font.size.table_header};
  line-height: ${({ theme }) => theme.font.lineHeight.table_header};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  &[data-sortstatus="null"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(-90deg);
    }
  }
  &[data-sortstatus="asc"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(-180deg);
    }
  }
  &[data-sortstatus="desc"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(0);
    }
  }

  ${({ center }) =>
    center &&
    css`
      justify-content: center;
      text-align: center;
    `}
`;

const SortableItem: React.FC<SortableItemInterface> = ({
  onClick,
  headername,
  sortstatus,
  children,
  center = false,
  className,
  right,
  padding = "0",
}) => (
  <Col
    data-headername={headername}
    data-sortstatus={sortstatus}
    onClick={onClick}
    center={center}
    className={className}
    padding={padding}
  >
    <SortableHeader right={right}>{children}</SortableHeader>
  </Col>
);

export default SortableItem;
