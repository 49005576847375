import styled from "styled-components";

const Text = styled("p")<{ colorTheme?: string }>`
  color: ${({ theme, colorTheme }) =>
    colorTheme === "light" ? theme.color.white : theme.color.black};
  font-size: ${({ theme }) => theme.font.size.text_M};
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
`;

export default Text;
