import React from "react";
import styled from "styled-components";
import MainContainer from "../../components/MainContainer/MainContainer";
import Caption from "../../components/Caption/Caption";
import FundItem from "../../components/FundItem/FundItem";
import TabContainer from "../../components/TabContainer/TabContainer";
import { t } from "../../translations/en";
import HorizontalGrid from "../../components/HorizontalGrid/HorizontalGrid";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import TableRow from "../../components/TableRow/TableRow";
import TableItem from "../../components/TableItem/TableItem";
import { useGetPerformance } from "../../hooks/useGetPerformance";
import Footer from "../../components/Footer/Footer";
import { useGetAverageColor } from "../../hooks/useGetAverageColor";
import { replaceWhitespacesWithNbsp } from "../../utils/Strings";
import InformationMessage from "../../components/InformationMessage/InformationMessage";
import { useSetHeader } from "../../hooks/useSetHeader";
import { useStoreActions } from "../../easy-peasy/hooks/hooks";
import { FunderService } from "../../services/FunderService";
import Tooltip from "../../components/Tooltip/Tooltip";
import Flex from "../../components/Flex/Flex";
import GlobalInvestorsFilters from "../../components/GlobalInvestorsFilters/GlobalInvestorsFilters";
import { useParams, useHistory } from "react-router";
import WithPagination from "../../components/WithPagination/WithPagination";
import SortableItem from "../../components/SortableItem/SortableItem";
import { useHandleSort } from "../../hooks/useHandleSort";
import { routes } from "../../routes/routes";
import Pagination from "../../components/Pagination/Pagination";

type DataType = {
  id: number;
  shareClass: string;
  peergroup: string;
  fundSeller: string;
  startDate: string;
  netFlows: number;
  shortTermPerformance: number;
  longTermPerformance: number;
  rating: number;
};

type optionType = {
  label: string;
  value: number;
};

const Wrapper = styled.div`
  margin-top: 3.5rem;
`;

const StyledTableRow = styled(TableRow)`
  :hover {
    cursor: pointer;
  }
`;

const LegendTitle = styled.h3`
  width: 1172.31px;
  margin-top: -2.2rem;
  margin-bottom: 2rem;
`;

const SmallTitle = styled.span`
  font-size: ${({ theme }) => theme.font.size.text_XS};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeight.text_S};
`;

const SmallParagraph = styled.p`
  font-size: ${({ theme }) => theme.font.size.text_XS};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  line-height: ${({ theme }) => theme.font.lineHeight.text_S};
`;

const StyledTitle = styled(SortableItem)`
  padding: 0 1.5rem;
`;

const AssetManagerView: React.FC = () => {
  const { id, page } = useParams<{ id: string; page: string }>();
  const [orderBy, setOrderBy] = React.useState<string>("");
  const [pagesAmount, setPagesAmount] = React.useState<number>(1);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [isFirstLoading, setFirstLoading] = React.useState<boolean>(true);
  const [assetClass, setAssetClass] = React.useState<string>("");
  const [categoryId, setCategoryId] = React.useState<string>("");
  const [assetClassOptions, setAssetClassOptions] = React.useState<
    Array<optionType>
  >([]);
  const [categoryOptions, setCategoryOptions] = React.useState<
    Array<optionType>
  >([]);
  const [isLoading, setLoading] = React.useState<boolean>(false);
  const [currentRecommendations, setCurrentRcomemndations] = React.useState<
    Array<any>
  >([]);
  const [afterFirstLoad, setAfterFirstLoad] = React.useState<boolean>(false);
  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );
  const history = useHistory();
  const pageSize = 50;
  const handleSort = (sortBy: string) => {
    setOrderBy(sortBy);
  };
  const handleSaveCurrentSortBy = useHandleSort(handleSort);
  useSetHeader({
    title: `${t.assetManagerView.title}`,
    searchComponent: "AssetManagerViewSearch",
  });

  const fetchData = () => {
    setLoading(true);
    FunderService.getAssetManagerView(
      id,
      categoryId,
      orderBy,
      assetClass,
      page,
      pageSize
    )
      .then((response) => {
        const data = JSON.parse(response);
        const dataArray = data.results.map((item: any) => {
          return {
            id: item.id,
            shareClass: item.share_class.name,
            peergroup: item.peergroup.name,
            netFlows: item.million_net_flow_for_last_3_months,
            shortTermPerformance: item.short_term_performance,
            longTermPerformance: item.long_term_performance,
            rating: item.morningstar_rating_overall,
          };
        });
        setCurrentRcomemndations(dataArray);
        setPagesAmount(Math.ceil(data.count / pageSize));
        headerAction({
          title: `${t.assetManagerView.title}`,
          titleName: `${data.asset_manager.name}`,
          searchComponent: "AssetManagerViewSearch",
        });
        if (isFirstLoading) {
          setFirstLoading(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  // fetch filters list
  React.useEffect(() => {
    FunderService.getAssetClasses()
      .then((response) => {
        const data = JSON.parse(response).map((item) => ({
          value: item.asset_class.split(" ").join("+"),
          label: item.asset_class,
        }));
        setAssetClassOptions(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });

    FunderService.getPeergroupsFilter()
      .then((response) => {
        const data = JSON.parse(response).map((item) => ({
          label: item.name,
          value: item.id,
        }));
        setCategoryOptions(data);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  }, []);

  // fetch table data
  React.useEffect(() => {
    if (afterFirstLoad) {
      fetchData();
      setAfterFirstLoad(false);
    }
  }, [page]);

  React.useEffect(() => {
    setAfterFirstLoad(true);
    if (parseInt(page) !== 1) {
      handlePageChange(1);
    } else {
      fetchData();
    }
  }, [id, orderBy, assetClass, categoryId]);

  const handlePageChange = (page) => {
    if (!isFirstLoading) {
      history.push(`${routes.assetManagerView}/${id}/${page}`);
    }
  };

  return (
    <WithPagination isLoading={isLoading}>
      <MainContainer>
        <Flex justify="flex-end">
          <LegendTitle>{t.performanceScoring}</LegendTitle>
        </Flex>
        <Flex justify="flex-end">
          <Caption type="average" />
        </Flex>
        <Wrapper>
          <GlobalInvestorsFilters
            setAssetClass={setAssetClass}
            setCategoryId={setCategoryId}
            assetClassOptions={assetClassOptions}
            categoryOptions={categoryOptions}
          />
          <FundItem title={t.assetManagerView.tableTitle}>
            <>
              <TabContainer>
                <HorizontalGrid
                  columnPattern={
                    "repeat(3, 2fr) repeat(3, minmax(auto, 16rem))"
                  }
                  gap={"0rem"}
                  align="center"
                >
                  <StyledTitle
                    headername={`share_class__name`}
                    sortstatus="null"
                    onClick={handleSaveCurrentSortBy}
                  >
                    {t.shareClass}
                  </StyledTitle>
                  <StyledTitle
                    headername={`peergroup__name`}
                    sortstatus="null"
                    onClick={handleSaveCurrentSortBy}
                  >
                    {t.peergroup}
                  </StyledTitle>
                  <StyledTitle
                    headername={`million_net_flow_for_last_3_months`}
                    sortstatus="null"
                    onClick={handleSaveCurrentSortBy}
                    right="-2rem"
                  >
                    <>
                      <div>{t.netFlow1}</div>
                      <SmallTitle>
                        {replaceWhitespacesWithNbsp(t.netFlow2)}
                      </SmallTitle>
                      <SmallParagraph>{t.netFlow3}</SmallParagraph>
                    </>
                  </StyledTitle>
                  <Tooltip
                    tooltip={t.shortTermPerformanceTooltip}
                    textAlign="left"
                  >
                    <StyledTitle
                      center
                      headername={`short_term_performance`}
                      sortstatus="null"
                      onClick={handleSaveCurrentSortBy}
                    >
                      {t.shortTermPerformance}
                    </StyledTitle>
                  </Tooltip>
                  <Tooltip
                    tooltip={t.longTermPerformanceTooltip}
                    textAlign="left"
                  >
                    <StyledTitle
                      center
                      headername={`long_term_performance`}
                      sortstatus="null"
                      onClick={handleSaveCurrentSortBy}
                    >
                      {t.longTermPerformance}
                    </StyledTitle>
                  </Tooltip>
                  <Tooltip
                    tooltip={t.ratingTooltip}
                    align="right"
                    textAlign="left"
                  >
                    <StyledTitle
                      center
                      headername={`morningstar_rating_overall`}
                      sortstatus="null"
                      onClick={handleSaveCurrentSortBy}
                    >
                      {t.rating}
                    </StyledTitle>
                  </Tooltip>
                </HorizontalGrid>
              </TabContainer>
              <VerticalGrid gap="0.7rem">
                {currentRecommendations.length ? (
                  currentRecommendations.map(
                    (
                      {
                        id,
                        shareClass,
                        peergroup,
                        netFlows,
                        shortTermPerformance,
                        longTermPerformance,
                        rating,
                      }: DataType,
                      index
                    ) => (
                      <StyledTableRow
                        key={index}
                        columnPattern={
                          "repeat(3, 2fr) repeat(3, minmax(auto, 16rem))"
                        }
                        gap={"3rem"}
                        onClick={() => history.push(`${routes.fund}/${id}`)}
                      >
                        <TableItem>{useGetPerformance(shareClass)}</TableItem>
                        <TableItem>{useGetPerformance(peergroup)}</TableItem>
                        <TableItem>
                          {useGetPerformance(
                            parseFloat(`${netFlows}`).toFixed(2)
                          )}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(shortTermPerformance)}
                          center
                        >
                          {useGetPerformance(shortTermPerformance)}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(longTermPerformance)}
                          center
                        >
                          {useGetPerformance(longTermPerformance)}
                        </TableItem>
                        <TableItem
                          background={useGetAverageColor(rating, "positive")}
                          center
                        >
                          {useGetPerformance(rating)}
                        </TableItem>
                      </StyledTableRow>
                    )
                  )
                ) : (
                  <InformationMessage />
                )}
              </VerticalGrid>
            </>
          </FundItem>
        </Wrapper>
        <Flex justify="flex-end">
          <Pagination
            pagesAmount={pagesAmount}
            currentPage={page}
            handleChange={(page) => handlePageChange(page)}
          />
        </Flex>
      </MainContainer>
      <Footer type="logoOnly" />
    </WithPagination>
  );
};

export default AssetManagerView;
