import React from 'react';
import styled from 'styled-components';

const StyledInvalidFormInfo = styled.span`
	color: ${({ theme }) => theme.color.white};
	background: ${({ theme }) => theme.color.error};
	font-size: ${({ theme }) => theme.font.size.text_S};
	padding: 0.5rem 0.9rem;
`;

const Wrapper = styled.div`
	margin-top: 0.5rem;
`;

interface InvalidFormInfoConfig {
	children: React.ReactNode;
}

const InvalidFormInfo: React.FC<InvalidFormInfoConfig> = ({ children }) => (
	<Wrapper>
		<StyledInvalidFormInfo>{children}</StyledInvalidFormInfo>
	</Wrapper>
);

export default InvalidFormInfo;
