import React from 'react';
import styled from 'styled-components';
import { useSetHeader } from '../../hooks/useSetHeader';
import MainContainer from '../../components/MainContainer/MainContainer';
import backgroundImage from '../../images/fund_list_blurred.png';
import { useSetModal } from '../../hooks/useSetModal';
import { t } from '../../translations/en';
import { useSetPageScroll } from '../../hooks/useSetPageScroll';
import { Redirect } from 'react-router';
import { routes } from '../../routes/routes';

const Wrapper = styled.div`
	/* save header height to easy-peasy and then change this wrapper's height */
	height: 100vh;
	width: 100%;
	position: relative;
	left: 0;
	top: 0;
`;

const Blur = styled.div`
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	pointer-events: none;
	user-select: none;
`;

const PhotoPlaceholder = styled.div<{ backgroundImage: string }>`
	background: ${({ backgroundImage }) => `url(${backgroundImage})`};
	background-size: 100% 100%;
	height: 100vh;
	width: 100%;
	margin: 0 auto;
`;

const PreviewPage: React.FC = () => {
	const token = localStorage.getItem('token');
	useSetHeader({ hasHeader: false });
	const setModal = useSetModal();
	const setPageScroll = useSetPageScroll();

	React.useEffect(() => {
		setPageScroll(true);
		setModal({
			visible: true,
			component: 'LoginModal',
			title: t.login,
			canBeClosed: false,
		});
		return () => setPageScroll(false);
	}, []);
	return (
		<>
			{token ? (
				<Redirect to={`${routes.recommendedFunds}/1`} />
			) : (
				<Wrapper>
					<MainContainer>
						<PhotoPlaceholder backgroundImage={backgroundImage} />
					</MainContainer>
					<Blur />
				</Wrapper>
			)}
		</>
	);
};

export default PreviewPage;
