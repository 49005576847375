import React from "react";
import styled from "styled-components";

interface HeadingConfig {
  className?: string;
  children: string;
  align?: "left" | "center" | "right";
}

const StyledHeading = styled.h2`
  color: ${({ theme }) => theme.color.white};
  font-weight: ${({ theme }) => theme.font.weight.medium};
  font-size: ${({ theme }) => theme.font.size.title_M};
  line-height: ${({ theme }) => theme.font.lineHeight.title_L};
`;

const Heading: React.FC<HeadingConfig> = ({ className, children, align }) => (
  <StyledHeading className={className} style={{ textAlign: align }}>
    {children}
  </StyledHeading>
);

export default Heading;
