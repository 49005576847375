import React from "react";
import styled, { css } from "styled-components";

type ColorThemeType = "light" | "dark";

interface CheckboxConfig {
  label?: string;
  name: string;
  value?: boolean;
  backgroundColor?: string;
  height?: string;
  width?: string;
  paddingLeft?: string;
  handleChange: (value: boolean) => void;
  className?: string;
  colorTheme?: ColorThemeType;
  dataset?: string;
}

type localHandleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => void;

const Wrapper = styled.div`
  position: relative;
`;

const CustomInput = styled.div<{
  backgroundColor?: string;
  height?: string;
  width?: string;
  colorTheme?: ColorThemeType;
}>`
  height: ${({ height }) => (height ? height : "2rem")};
  width: ${({ width }) => (width ? width : "2rem")};
  border: 0.1rem ${({ theme }) => theme.color.white} solid;
  position: absolute;
  left: 0;
  top: 0.2rem;
  background: transparent;
  pointer-events: none;
  &:before {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    height: 70%;
    width: 70%;
    background: ${({ theme, backgroundColor }) =>
      backgroundColor ? backgroundColor : theme.color.white};
    transform: translate(-50%, -50%) scale(0);
    transition: transform 0.2s ease;
  }

  ${({ colorTheme }) =>
    colorTheme === "dark" &&
    css`
      background: ${({ theme }) => theme.color.mainLight};
      margin-bottom: 0.6rem;
    `}
`;

const HiddenInput = styled.input`
  position: absolute;
  pointer-events: none;
  visibility: hidden;
  :checked ~ ${CustomInput} {
    :before {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const Label = styled.label<{
  height?: string;
  paddingLeft?: string;
  colorTheme?: ColorThemeType;
}>`
  font-size: ${({ theme }) => theme.font.size.text_M};
  line-height: ${({ theme }) => theme.font.lineHeight.text_M};
  color: ${({ theme, colorTheme }) =>
    colorTheme === "dark" ? theme.color.black : theme.color.white};
  height: ${({ height }) => (height ? height : "auto")};
  padding-left: ${({ paddingLeft }) => (paddingLeft ? paddingLeft : "3.6rem")};
  user-select: none;
  :hover {
    cursor: pointer;
  }
  ${({ colorTheme }) =>
    colorTheme === "dark" &&
    css`
      font-size: ${({ theme }) => theme.font.size.text_XS};
      font-weight: ${({ theme }) => theme.font.weight.bold};
      display: flex;
      align-items: center;
    `}
`;

const Checkbox: React.FC<CheckboxConfig> = ({
  label,
  handleChange,
  name,
  value,
  backgroundColor,
  height,
  width,
  paddingLeft,
  colorTheme,
  dataset,
  className,
}) => {
  const localHandleChange: localHandleChangeType = (e) => {
    const val = e.target.checked;
    handleChange(val);
  };
  return (
    <Wrapper>
      <HiddenInput
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        data-checkboxvalue={dataset}
        onChange={(e) => localHandleChange(e)}
      />
      <CustomInput
        className={className}
        height={height}
        width={width}
        backgroundColor={backgroundColor}
        colorTheme={colorTheme}
      />
      <Label
        htmlFor={name}
        paddingLeft={paddingLeft}
        height={height}
        colorTheme={colorTheme}
      >
        {label}
      </Label>
    </Wrapper>
  );
};

export default Checkbox;
