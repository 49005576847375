import { useQuery } from './useQuery';
import { useLocation, useHistory } from 'react-router-dom';
export const useUpdateQuery = () => {
	const query = useQuery();
	const location = useLocation();
	const history = useHistory();
	return (queryString: string) => {
		// const newurl = `${location.pathname}?${queryString}`;
		// window.history.pushState({ path: newurl }, '', newurl);
		history.push({
			pathname: location.pathname,
			search: `?${queryString}`,
		});
	};
};
