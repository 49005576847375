import React from "react";
import styled from "styled-components";
import Tooltip from "../Tooltip/Tooltip";

type alignType = "left" | "center" | "right";

interface CaptionItemInterface {
  tooltip: string;
  name: string;
  color: colorType;
  size?: "small" | "big";
  align?: alignType;
  textAlign?: alignType;
  smallMargin?: boolean;
}

type colorType =
  | "yellow"
  | "mainLight"
  | "main"
  | "yellowLight"
  | "lighterGray";

const CaptionColor = styled.div<{ color: colorType; smallMargin?: boolean }>`
  height: 3rem;
  width: 3rem;
  background: ${({ theme, color }) => theme.color[color]};
  :not(:first-of-type) {
    margin-left: ${({ smallMargin }) => (smallMargin ? "2.5rem" : "4.5rem")};
  }
`;

const CaptionName = styled.div`
  font-size: ${({ theme }) => theme.font.size.text_M};
  padding-left: 1.5rem;
  position: relative;
`;

const CaptionItem: React.FC<CaptionItemInterface> = ({
  tooltip,
  name,
  color,
  size = "big",
  align = "center",
  textAlign = "center",
  smallMargin,
}) => (
  <>
    <CaptionColor color={color} smallMargin={smallMargin} />
    <Tooltip tooltip={tooltip} size={size} align={align} textAlign={textAlign}>
      <CaptionName>{name}</CaptionName>
    </Tooltip>
  </>
);

export default CaptionItem;
