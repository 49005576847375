import React from "react";
import styled, { css } from "styled-components";

type alignType = "left" | "center" | "right";
type sizeType = "small" | "big";
type sidebarType = true | false;

interface TooltipContentType {
  align?: alignType;
  textAlign: alignType;
  size: sizeType;
  sideBar?: sidebarType;
  headerButtons?: sidebarType;
}

interface TooltipInterface {
  children: string | React.ReactElement;
  tooltip: string;
  className?: string;
  align?: alignType;
  textAlign?: alignType;
  size?: sizeType;
  sideBar?: sidebarType;
  headerButtons?: sidebarType;
}

const Wrapper = styled.div`
  display: inline;
  position: relative;
`;

const TooltipContent = styled.div<TooltipContentType>`
	position: relative;
	z-index: 9;
	display: none;
	text-align: ${({ textAlign }) => textAlign};
	min-width: ${({ size }) => (size === "small" ? "fit-content" : "25rem")};
	max-width: 30rem;
	position: absolute;
	left: 50%;
	top: ${({ sideBar, headerButtons }) =>
    sideBar === true
      ? "calc(100% - 1.3rem)"
      : headerButtons
      ? "calc(100% + 1.8rem)"
      : "calc(100% + 1rem)"};
	transform: translateX(-50%);
	background: ${({ theme }) => theme.color.main};
	color: ${({ theme }) => theme.color.white};
	padding: 0.5rem 1rem;
	font-size: ${({ theme }) => theme.font.size.text_M};
	line-height: ${({ theme }) => theme.font.lineHeight.text_S};
	font-weight: ${({ theme }) => theme.font.weight.regular};

	:after {
		content: '\\e800';
		z-index: 9;
		font-family: ${({ theme }) => theme.font.icon};
		position: absolute;
		color: ${({ theme }) => theme.color.main};
		font-size: 0.7rem;
		left: 50%;
		top: -1.2rem;
		transform: translateX(-50%) rotate(180deg);
	}

	${({ align }) =>
    align === "left" &&
    css`
      transform: none;
      right: inherit;
      left: 0;
      :after {
        right: inherit;
        left: 1rem;
        transform: rotate(180deg);
      }
    `};};

	${({ align }) =>
    align === "right" &&
    css`
      transform: none;
      left: inherit;
      right: 0;
      :after {
        left: inherit;
        right: 1rem;
        transform: rotate(180deg);
      }
    `};
`;

const TooltipTrigger = styled.span`
  :hover {
    cursor: default;
    & ~ ${TooltipContent} {
      display: inline-block;
    }
  }
`;

const Tooltip: React.FC<TooltipInterface> = ({
  children,
  tooltip,
  className,
  align,
  textAlign = "center",
  size = "big",
  sideBar,
  headerButtons,
}) => {
  const tooltipRef = React.useRef<HTMLDivElement | null>(null);
  React.useLayoutEffect(() => {
    tooltipRef.current.innerHTML = tooltip;
  }, []);
  return (
    <Wrapper className={className}>
      <TooltipTrigger>{children}</TooltipTrigger>
      <TooltipContent
        sideBar={sideBar}
        align={align}
        ref={tooltipRef}
        textAlign={textAlign}
        headerButtons={headerButtons}
        size={size}
      ></TooltipContent>
    </Wrapper>
  );
};

export default Tooltip;
