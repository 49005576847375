import React from "react";
import { useStoreActions } from "../easy-peasy/hooks/hooks";

type HeaderConfigType = {
  title?: string;
  subtitle?: string;
  hasSubtitle?: boolean;
  hasHeader?: boolean;
  isStaticPage?: boolean;
  additionalSubtitle?: string;
  searchComponent?:
    | "AssetManagerViewSearch"
    | "FundDetailViewSearch"
    | "PeergroupHeaderSearch"
    | "GapPeergroupPageSearch"
    | null;
};

type UseSetHeaderType = (headerConfig: HeaderConfigType) => void;

export const useSetHeader: UseSetHeaderType = ({
  title,
  subtitle,
  hasSubtitle,
  hasHeader,
  isStaticPage,
  searchComponent = null,
  additionalSubtitle,
}) => {
  const headerAction = useStoreActions(
    (actions) => actions.headerData.addHeaderData
  );
  React.useLayoutEffect(() => {
    headerAction({
      title,
      subtitle,
      hasSubtitle,
      hasHeader,
      isStaticPage,
      searchComponent,
      additionalSubtitle,
    });
    return () => {
      headerAction({});
    };
  }, []);
};
