import React from 'react';
import { t } from '../../translations/en';
import Flex from '../Flex/Flex';
import CaptionItem from '../CaptionItem/CaptionItem';

interface CaptionInterface {
	type: 'average' | 'potential' | 'fund' | 'performance';
}

const Caption: React.FC<CaptionInterface> = ({ type }) => (
	<Flex justify='flex-end'>
		{type === 'fund' && (
			<>
				<CaptionItem
					tooltip={t.fundDetail.highPotentialTooltip}
					name={t.fundDetail.highPotential}
					color='main'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.fundDetail.mediumPotentialTooltip}
					name={t.fundDetail.mediumPotential}
					color='mainLight'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.fundDetail.lowPotentialTooltip}
					name={t.fundDetail.lowPotential}
					color='yellow'
					align='right'
					smallMargin
				/>
			</>
		)}
		{type === 'performance' && (
			<>
				<CaptionItem
					tooltip={t.potential.highTooltip}
					name={t.potential.highPotential}
					color='main'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.potential.mediumTooltip}
					name={t.potential.mediumPotential}
					color='mainLight'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.potential.lowTooltip}
					name={t.potential.lowPotential}
					color='yellow'
					align='right'
					smallMargin
				/>
			</>
		)}
		{type === 'potential' && (
			<>
				<CaptionItem
					tooltip={t.highPotentialTooltip}
					name={t.highPotential}
					color='main'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.mediumPotentialTooltip}
					name={t.mediumPotential}
					color='mainLight'
					smallMargin
				/>
				<CaptionItem
					tooltip={t.lowPotentialTooltip}
					name={t.lowPotential}
					color='yellow'
					align='right'
					smallMargin
				/>
			</>
		)}
		{type === 'average' && (
			<>
				<CaptionItem
					tooltip={t.stronglyAboveAverageTooltip}
					name={t.stronglyAboveAverage}
					color='main'
				/>
				<CaptionItem
					tooltip={t.slightlyAboveAverageTooltip}
					name={t.slightlyAboveAverage}
					color='mainLight'
				/>
				<CaptionItem
					tooltip={t.averageTooltip}
					name={t.average}
					color='lighterGray'
				/>
				<CaptionItem
					tooltip={t.slightlyBelowAverageTooltip}
					name={t.slightlyBelowAverage}
					color='yellowLight'
				/>
				<CaptionItem
					tooltip={t.stronglyBelowAverageTooltip}
					name={t.stronglyBelowAverage}
					color='yellow'
					align='right'
				/>
			</>
		)}
	</Flex>
);

export default Caption;
