import React, { useState } from 'react';
import styled from 'styled-components';
import { routes } from '../routes/routes';
import Form from '../components/Form/Form';
import { t } from '../translations/en';
import Flex from '../components/Flex/Flex';
import CustomButton from '../components/Button/Button';
import Search from '../components/Search/Search';
import { useSetModal } from '../hooks/useSetModal';
import { FunderService } from '../services/FunderService';
import { useHistory } from 'react-router';

const StyledButton = styled(CustomButton)`
	margin-right: 1rem;
`;

type handleSendType = (
	e: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>
) => void;

const GapAnalysisModal = () => {
	const [formValue, setFormValue] = useState<number | null>(null);
	const [validForm, setValidForm] = useState<boolean | undefined>(undefined);
	const [isListLoading, setListLoading] = useState<boolean>(false);
	const [isLoading, setLoading] = useState<boolean>(false);
	const [autocompleteList, setAutocompleteList] = useState<
		Array<{
			value: number;
			name: string;
		}>
	>([]);
	const history = useHistory();
	const setModal = useSetModal();
	const pageSize = 20;

	const filterSearchList = (value: string, page: number) => {
		if (value !== null) {
			setListLoading(true);
			FunderService.getFundList(value, page, pageSize)
				.then(response => {
					const data = JSON.parse(response).results.map(item => ({
						value: item.id,
						name: item.name_with_isin,
					}));
					if (page === 1) {
						setAutocompleteList(data);
					} else {
						setAutocompleteList([...autocompleteList, ...data]);
					}
					setListLoading(false);
				})
				.catch(error => {
					console.log(error);
					setListLoading(false);
				});
		}
	};

	const handleSend: handleSendType = e => {
		e.preventDefault();
		if (validForm) {
			setLoading(true);
			history.push(`${routes.fund}/${formValue}`);
		}
	};

	const handleCloseModal = () => {
		setModal({});
	};

	return (
		<Form onSubmit={handleSend}>
			<Search
				name={'fund'}
				list={autocompleteList}
				autofocus
				label={t.nameIsin}
				handleChange={(value, page) => {
					filterSearchList(value, page);
					setValidForm(false);
					setFormValue(null);
				}}
				handleSelect={(value, id) => {
					setFormValue(id);
					setValidForm(value);
				}}
				isListLoading={isListLoading}
			/>
			<Flex marginTop={'3rem'} justify='flex-end'>
				<StyledButton
					content={t.cancel}
					onClick={handleCloseModal}
					size='small'
					borderWidth={'1px'}
					isLoading={false}
					backButton
				/>
				<CustomButton
					disabled={!validForm}
					content={t.confirm}
					onClick={handleSend}
					size='small'
					borderWidth={'1px'}
					isLoading={isLoading}
				/>
			</Flex>
		</Form>
	);
};

export default GapAnalysisModal;
