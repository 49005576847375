import React, { useState, useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import { t } from "../../translations/en";
import { Container, Col as BCol, Row as BRow } from "react-bootstrap";
import Tooltip from "../Tooltip/Tooltip";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import { useHandleSort } from "../../hooks/useHandleSort";
import SortableItem from "../SortableItem/SortableItem";

const Wrapper = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : "3.5rem")};
  width: 100%;
  padding-right: 2rem;
  background: ${({ theme }) => theme.color.lighterGray};
`;

const SortableHeader = styled.span`
  position: relative;
  pointer-events: none;
  user-select: none;
  :after {
    content: "\\e800";
    position: absolute;
    top: 50%;
    right: -1.5rem;
    transform: translateY(-50%) rotateZ(-90deg);
    font-family: ${({ theme }) => theme.font.icon};
    font-size: 0.5rem;
  }
`;

const Col = styled(BCol)`
  user-select: none;
  height: 7.3rem;
  padding: 0;
  font-size: ${({ theme }) => theme.font.size.table_header};
  line-height: ${({ theme }) => theme.font.lineHeight.table_header};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  align-items: center;
  display: flex;
  &:hover {
    cursor: pointer;
  }
  &[data-sortstatus="null"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(-90deg);
    }
  }
  &[data-sortstatus="asc"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(-180deg);
    }
  }
  &[data-sortstatus="desc"] {
    ${SortableHeader}:after {
      transform: translateY(-50%) rotateZ(0);
    }
  }
`;

const CenterCol = styled(Col)`
  justify-content: center;
  text-align: center;
`;

const StrategySortableItem = styled(SortableItem)`
  justify-content: center;
  text-align: center;
`;

const CustomSortableItem = styled(SortableItem)`
  justify-content: center;
  text-align: center;
`;

const LastCol = styled(Col)`
  background-color: ${({ theme }) => theme.color.white};
`;

interface IRecommendedFundListtableHeader {
  handleSort: (sortBy: string, scope: string) => void;
  marginTop?: string;
  ifLastHeaderCell?: boolean;
  peergroupPage?: boolean;
  scope?: string;
  isPeergroupPage?: boolean;
  isAlternativesPage?: boolean;
  currentRecommendations?: boolean;
}

const RecommendedFundListTableHeader: React.FC<IRecommendedFundListtableHeader> = ({
  handleSort,
  marginTop,
  ifLastHeaderCell = false,
  isPeergroupPage,
  isAlternativesPage,
  scope,
  currentRecommendations,
}) => {
  const handleSaveCurrentSortBy = useHandleSort(handleSort, scope);

  return (
    <Wrapper data-id={scope} marginTop={marginTop}>
      {isPeergroupPage ? (
        <HorizontalGrid
          columnPattern={"2fr 1.5fr repeat(4, 1fr) repeat(3, 0.5fr)"}
          gap="1.5rem"
          align="center"
        >
          <SortableItem
            headername={`fund__share_class__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
            padding="0 0 0 3rem"
          >
            {t.recommendedFundsPage.tableHeaders.shareClass}
          </SortableItem>
          <SortableItem
            headername={`fund__peergroup__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.peergroup}
          </SortableItem>
          <SortableItem
            headername={`fund__kategorie`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.category}
          </SortableItem>
          <SortableItem
            headername={`fund__asset_manager__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
            padding="0 1rem 0 0"
          >
            {t.recommendedFundsPage.tableHeaders.assetManager}
          </SortableItem>
          <StrategySortableItem
            headername={`fund__strategy`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.strategy}
          </StrategySortableItem>
          {currentRecommendations ? (
            <SortableItem
              headername={`seller__name`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
            >
              {t.recommendedFundsPage.tableHeaders.fundSeller}
            </SortableItem>
          ) : (
            <StrategySortableItem
              headername={`fund__number_of_recommendations`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
            >
              Anzahl Empfehlungen
            </StrategySortableItem>
          )}
          <Tooltip tooltip={t.shortTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`fund__short_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.shortTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.longTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`fund__long_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.longTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.ratingTooltip} align="right" textAlign="left">
            <SortableItem
              headername={`fund__morningstar_rating_overall`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.rating}
            </SortableItem>
          </Tooltip>
          {ifLastHeaderCell ? <LastCol md={1} /> : null}
        </HorizontalGrid>
      ) : isAlternativesPage ? (
        <HorizontalGrid
          columnPattern={"2fr 1.5fr repeat(4, 1fr) repeat(3, 0.5fr)"}
          gap="1.5rem"
          align="center"
        >
          <SortableItem
            headername={`share_class__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
            padding="0 0 0 3rem"
          >
            {t.recommendedFundsPage.tableHeaders.shareClass}
          </SortableItem>
          <SortableItem
            headername={`peergroup__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.peergroup}
          </SortableItem>
          <SortableItem
            headername={`kategorie`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.category}
          </SortableItem>
          <SortableItem
            headername={`asset_manager__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
            padding="0 1rem 0 0"
          >
            {t.recommendedFundsPage.tableHeaders.assetManager}
          </SortableItem>
          <StrategySortableItem
            headername={`strategy`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.strategy}
          </StrategySortableItem>
          {currentRecommendations ? (
            <SortableItem
              headername={`seller__name`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
            >
              {t.recommendedFundsPage.tableHeaders.fundSeller}
            </SortableItem>
          ) : (
            <StrategySortableItem
              headername={`number_of_recommendations`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
            >
              Anzahl Empfehlungen
            </StrategySortableItem>
          )}
          <Tooltip tooltip={t.shortTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`short_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.shortTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.longTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`long_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.longTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.ratingTooltip} align="right" textAlign="left">
            <SortableItem
              headername={`morningstar_rating_overall`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.rating}
            </SortableItem>
          </Tooltip>
          {ifLastHeaderCell ? <LastCol md={1} /> : null}
        </HorizontalGrid>
      ) : (
        // RECOMMENDED-FUNDS PAGE
        <HorizontalGrid
          columnPattern={"2.6fr repeat(4, 2fr) repeat(2, 1fr) repeat(3, 0.5fr)"}
          gap="2rem"
          align="center"
        >
          <SortableItem
            headername={`seller__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
            padding="0 0 0 2rem"
          >
            {t.recommendedFundsPage.tableHeaders.fundSeller}
          </SortableItem>
          <SortableItem
            headername={`fund__share_class__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.shareClass}
          </SortableItem>
          <SortableItem
            headername={`fund__peergroup__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.peergroup}
          </SortableItem>
          <SortableItem
            headername={`fund__kategorie`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.category}
          </SortableItem>
          <SortableItem
            headername={`fund__asset_manager__name`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.assetManager}
          </SortableItem>
          <SortableItem
            headername={`fund__strategy`}
            sortstatus="null"
            onClick={handleSaveCurrentSortBy}
          >
            {t.recommendedFundsPage.tableHeaders.strategy}
          </SortableItem>
          <Tooltip tooltip={t.externalInternalTooltip} textAlign="left">
            <CustomSortableItem
              headername={`external_internal`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
            >
              {t.recommendedFundsPage.tableHeaders.internalExternal}
            </CustomSortableItem>
          </Tooltip>
          <Tooltip tooltip={t.shortTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`fund__short_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.shortTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.longTermPerformanceTooltip} textAlign="left">
            <SortableItem
              headername={`fund__long_term_performance`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.longTermPerformance}
            </SortableItem>
          </Tooltip>
          <Tooltip tooltip={t.ratingTooltip} align="right" textAlign="left">
            <SortableItem
              headername={`fund__morningstar_rating_overall`}
              sortstatus="null"
              onClick={handleSaveCurrentSortBy}
              center
            >
              {t.recommendedFundsPage.tableHeaders.rating}
            </SortableItem>
          </Tooltip>

          {ifLastHeaderCell ? <LastCol md={1} /> : null}
        </HorizontalGrid>
      )}
    </Wrapper>
  );
};

export default RecommendedFundListTableHeader;
