import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
	RouteComponentProps,
	useHistory,
	useParams,
	useLocation,
} from 'react-router-dom';
import MainContainer from '../../components/MainContainer/MainContainer';
import ListLegendWrapper from '../../components/ListLegendWrapper/ListLegendWrapper';
import { t } from '../../translations/en';
import RecommendedFundListTableWrapper from '../../components/RecommendFundListTableWrapper/RecommendedFundListTableWrapper';
import {
	FunderService,
	ISingleRecommendation,
} from '../../services/FunderService';
import Footer from '../../components/Footer/Footer';
import { routes } from '../../routes/routes';
import { useSetHeader } from '../../hooks/useSetHeader';
import { handleGetCurrentMonthAndYear } from '../../utils/Dates';
import { useStoreActions, useStoreState } from '../../easy-peasy/hooks/hooks';
import PeergroupFilters from '../../components/PeergroupFilters/PeergroupFilters';

type handlePageChangeType = (page: number, id?: string) => void;
type FilterType = {
	id: string;
	name: string;
	url?: string;
	asset_class?: string;
};

interface IProps {
	id: string;
	page: string;
}

interface IRecommendedFunds extends RouteComponentProps<IProps> {
	// needs to be empty
}
export interface ObjectIndexer<T> {
	[id: string]: T;
}

const PeergroupPage: React.FC<IRecommendedFunds> = (
	props: IRecommendedFunds
) => {
	const currentMonthAndYear = handleGetCurrentMonthAndYear();

	const history = useHistory();
	const params = useParams<{ page: string; id: string }>();
	const [previous, setPrevious] = useState<null | string>(null),
		[results, setResults] = useState<Array<ISingleRecommendation> | null>(null),
		[pageCount, setPageCount] = useState<number>(0),
		[orderBy, setOrderBy] = useState<string>(''),
		[currentPage, setCurrentPage] = useState<number>(1),
		[isLoading, setLoading] = useState<boolean>(false),
		[strategiesFilter, setStrategiesFilter] = useState<Array<FilterType>>(),
		[peergroupFilter, setPeergroupFilter] = useState<Array<FilterType>>(),
		[peergroupId, setPeergroupId] = useState<string>(''),
		[strategyValue, setStrategyValue] = useState<string>(''),
		[internalExternal, setInternalExternal] = useState<string>(''),
		[fundSellerFilter, setfundSellerFilter] = useState<Array<FilterType>>(),
		[sellersOptions, setSellersOptions] = useState<any>(),
		[fundSellerId, setFundSellerId] = useState<string | number>(''),
		[firstLoading, setFirstLoading] = useState<boolean>(true);

	const pageSize = 50;

	const headerAction = useStoreActions(
		actions => actions.headerData.addHeaderData
	);
	useSetHeader({
		title: `${t.peergroupHeader}`,
	});

	const gapAction = useStoreActions(actions => actions.gapModel.addIsGap);
	const gapData = useStoreState(state => state.gapModel.items.isGap);

	useEffect(() => {
		if (firstLoading) {
			setPeergroupId('');
			setStrategyValue('');
			setFundSellerId('');
		}
	}, []);

	useLayoutEffect(() => {
		if (firstLoading) {
			FunderService.getFundSellers()
				.then(response => {
					const data = JSON.parse(response);
					setfundSellerFilter(data);
					setFirstLoading(false);
				})
				.catch(error => console.log(error));

			// Get stratiegies filter options
			FunderService.getStrategiesFilter()
				.then(response => {
					const data = JSON.parse(response);

					setStrategiesFilter(data);
				})
				.catch(error => console.log(error));

			FunderService.getRecommendationsForPeergroup(props.match.params.id, 1)
				.then(response => {
					const data = JSON.parse(response);
					headerAction({
						title: `${t.peergroupHeader}`,
						titleName: `${data.peergroup.name}`,
						searchComponent: 'PeergroupHeaderSearch',
					});
					handlePageChangeResponse(response);
					if (!gapData) {
						handlePageChange(1);
					}
					setLoading(false);
				})
				.catch(error => {
					console.log(error);
					setLoading(false);
				});
		}
		gapAction({ isGap: false });
	}, []);

	const handlePageChangeResponse = (response: any) => {
		const data = JSON.parse(response);
		setResults(data.results);
		setPageCount(Math.ceil(data.count / pageSize));
		setPrevious(data.previous);
		setLoading(false);
		window.scrollTo(0, 0);
	};

	useEffect(() => {
		if (!firstLoading) {
			setLoading(true);
			FunderService.getFilteredPeergroupData(
				props.match.params.page,
				fundSellerId,
				strategyValue,
				props.match.params.id,
				orderBy
			)
				.then(response => {
					handlePageChangeResponse(response);
					const data = JSON.parse(response);
					headerAction({
						title: `${t.peergroupHeader}`,
						titleName: `${data.peergroup.name}`,
						searchComponent: 'PeergroupHeaderSearch',
					});
					setLoading(false);
				})
				.catch(e => {
					setLoading(false);
				});
		}
	}, [params.page]);

	useEffect(() => {
		if (!firstLoading) {
			setLoading(true);
			FunderService.getFilteredPeergroupData(
				1,
				fundSellerId,
				strategyValue,
				props.match.params.id,
				orderBy
			)
				.then(response => {
					handlePageChangeResponse(response);
					const data = JSON.parse(response);
					headerAction({
						title: `${t.peergroupHeader}`,
						titleName: `${data.peergroup.name}`,
						searchComponent: 'PeergroupHeaderSearch',
					});
					setLoading(false);
				})
				.catch(e => {
					setLoading(false);
				});
		}
	}, [params.id]);

	useEffect(() => {
		if (!firstLoading) {
			handlePageChange(1);
			handleFilterResults(
				1,
				fundSellerId,
				strategyValue,
				props.match.params.id,
				orderBy
			);
		}
	}, [fundSellerId, strategyValue, peergroupId, orderBy]);

	// Filter recommendations

	const handleFilterResults = (
		currentPage,
		fundSellerId,
		strategyValue,
		peergroupId,
		orderBy
	) => {
		setLoading(true);
		if (!firstLoading) {
			FunderService.getFilteredPeergroupData(
				props.match.params.page,
				fundSellerId,
				strategyValue,
				peergroupId,
				orderBy
			)
				.then(response => {
					const data = JSON.parse(response);
					setPageCount(Math.ceil(data.count / pageSize));
					setPrevious(data.previous);
					setResults(data.results);
					setLoading(false);
					headerAction({
						title: `${t.peergroupHeader}`,
						titleName: `${data.peergroup.name}`,
						searchComponent: 'PeergroupHeaderSearch',
					});
				})
				.catch(error => {
					console.log(error);
					setLoading(false);
				});
		}
	};

	const handlePageChange: handlePageChangeType = currentPage => {
		setLoading(true);
		history.push(
			`${routes.peergroupPage}/${props.match.params.id}/${currentPage}/`
		);
		setCurrentPage(Number(currentPage));
	};

	const handleSort = (sortBy: string) => {
		if (currentPage !== 1) {
			handlePageChange(1);
		}
		setOrderBy(sortBy);
	};

	return (
		<MainContainer>
			<ListLegendWrapper
				isPotential={true}
				content={t.recommendedFundsPage.buttonContent}
			/>
			<PeergroupFilters
				strategies={strategiesFilter}
				peergroups={peergroupFilter}
				setPeergroupId={setPeergroupId}
				setStrategyValue={setStrategyValue}
				setInternalExternal={setInternalExternal}
				isClearable
				fundSellers={fundSellerFilter}
				sellers={sellersOptions}
				setFundSellerId={setFundSellerId}
				isPeergroupPage={true}
			/>
			<RecommendedFundListTableWrapper
				ifRequestLink={false}
				id={props.match.params.id}
				pagesAmount={pageCount}
				currentPage={parseInt(props.match.params.page)}
				handlePageChange={handlePageChange}
				handleSort={handleSort}
				data={results}
				isLoading={isLoading}
				doRedirect={true}
				isPeergroupPage={true}
			/>
			<Footer type={'logoOnly'} />
		</MainContainer>
	);
};

export default PeergroupPage;
