import React, { useLayoutEffect } from 'react';
import { useLogout } from '../../hooks/useLogout';

const Logout: React.FC = () => {
	const logout = useLogout();
	useLayoutEffect(() => {
		logout();
	}, []);
	return <div></div>;
};

export default Logout;
