import React from "react";
import styled from "styled-components";
import Select from "react-select";
import Flex from "../Flex/Flex";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import Title from "../Title/Title";
import { t } from "../../translations/en";
import selectTheme from "../../utils/SelectTheme";

interface GlobalInvestorsFiltersInterface {
  assetClassOptions: Array<{ label: string; value: number }>;
  categoryOptions: Array<{ label: string; value: number }>;
  setAssetClass: (value: string) => void;
  setCategoryId: (value: string) => void;
}

const Wrapper = styled.div`
  margin-bottom: 2rem;
`;

const StyledTitle = styled(Title)`
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
  margin-right: 2rem;
`;

const customStyles = {
  valueContainer: (provided) => ({
    ...provided,
    minHeight: "2rem",
    fontSize: "1.2rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
  singleValue: (provided) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
};

const GlobalInvestorsFilters: React.FC<GlobalInvestorsFiltersInterface> = ({
  assetClassOptions,
  categoryOptions,
  setAssetClass,
  setCategoryId,
}) => {
  return (
    <Wrapper>
      <Flex justify="flex-start">
        <StyledTitle>{t.filters}</StyledTitle>
        <HorizontalGrid columnPattern={"repeat(2,25rem)"} gap="2rem">
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            placeholder={t.assetManagerView.assetClass}
            options={assetClassOptions}
            theme={selectTheme}
            onChange={(value) => {
              const retunedValue = value ? value.value : "";
              setAssetClass(String(retunedValue));
            }}
          />
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            placeholder={t.assetManagerView.peergroup}
            theme={selectTheme}
            options={categoryOptions}
            onChange={(value) => {
              const retunedValue = value ? value.value : "";
              setCategoryId(String(retunedValue));
            }}
          />
        </HorizontalGrid>
      </Flex>
    </Wrapper>
  );
};

export default GlobalInvestorsFilters;
