import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { routes } from '../../routes/routes';
import BackButton from '../BackButton/BackButton';
import MainContainer from '../MainContainer/MainContainer';
import PreviewPage from '../../views/PreviewPage/PreviewPage';
import { verifyToken } from '../../utils/verifyToken';
import Loader from '../Loader/Loader';
import { useStoreActions } from '../../easy-peasy/hooks/hooks';

interface RestrictedRouteInterface {
	component: React.FC<{
		history: any;
		location: any;
		match: any;
		staticContext?: any;
	}>;
	path: string;
	exact?: boolean;
}

const RestrictedRoute: React.FC<RestrictedRouteInterface> = ({
	component: Component,
	path,
	exact,
}) => {
	const unavaibleBackButtonPaths = [
		routes.landingPage,
		routes.landingView,
		routes.globalInvestors,
		`${routes.recommendedFunds}/:page`,
		`${routes.fundList}/:id`,
		`${routes.fund}/:id`,
	];
	const verifyAction = useStoreActions(
		actions => actions.verifiedModel.addVerified
	);
	const isBackButtonHidden = unavaibleBackButtonPaths.includes(path);
	const [verifiedToken, setVerifiedToken] = useState<boolean | null>(null);
	const [isLoading, setLoading] = useState<boolean>(true);
	const location = useLocation();
	useLayoutEffect(() => {
		setTimeout(() => {
			if (!sessionStorage.getItem('errorMessage')) {
				if (localStorage.getItem('token')) {
					setVerifiedToken(true);
				} else {
					setVerifiedToken(true);
				}
			} else {
				verifyToken(setVerifiedToken, setLoading);
			}
		}, 100);
	}, [location.pathname]);
	useEffect(() => {
		verifyAction({ verified: verifiedToken });
		if (verifiedToken) {
			sessionStorage.removeItem('errorMessage');
		}
	}, [verifiedToken]);
	return (
		<Route
			path={path}
			exact={exact}
			render={props =>
				verifiedToken === null ? (
					<Loader />
				) : verifiedToken ? (
					<>
						{!isBackButtonHidden && (
							<MainContainer>
								<BackButton />
							</MainContainer>
						)}
						<Component {...props} />
					</>
				) : (
					<>
						{path === routes.homepage ? (
							<Redirect to={routes.signIn} />
						) : (
							<PreviewPage />
						)}
					</>
				)
			}
		/>
	);
};

export default RestrictedRoute;
