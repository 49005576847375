import React from 'react'
import styled from 'styled-components'

interface ITableHeader {
    title: string
}

const Header = styled.div`
    display: flex;
    height: 7.3rem;
    width: 100%;
    background: ${({ theme }) => theme.color.lighterGray};
    padding: 2rem 3rem;
    align-items: center;
    font-size: ${({ theme }) => theme.font.size.text_L};
    font-weight: ${({ theme }) => theme.font.weight.bold};
`

const TableHeader: React.FC<ITableHeader> = ({ title }) => (
    <Header>{title}</Header>
)

export default TableHeader