import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import styled from "styled-components";
import { useFormik } from "formik";
import MainContainer from "../../components/MainContainer/MainContainer";
import HorizontalGrid from "../../components/HorizontalGrid/HorizontalGrid";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import FundItem from "../../components/FundItem/FundItem";
import Input from "../../components/Input/Input";
import { t } from "../../translations/en";
import AddRecommendationsRecord, {
  Seller,
} from "../../components/AddRecommendationsRecord/AddRecommendationsRecord";
import CustomButton from "../../components/Button/Button";
import Footer from "../../components/Footer/Footer";
import { useSetHeader } from "../../hooks/useSetHeader";
import { useFormatDate } from "../../hooks/useFormatDate";
import { FunderService } from "../../services/FunderService";
import { useHistory } from "react-router";
import WithPagination from "../../components/WithPagination/WithPagination";
import { routes } from "../../routes/routes";

const StyledTitle = styled(Title)`
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
`;

const ContentWrapper = styled.form`
  margin-top: 4.4rem;
`;

const FormWrapper = styled.div`
  margin-top: 1.6rem;
`;

const RecordsWrapper = styled.div`
  display: grid;
  grid-row-gap: 0.6rem;
  margin-bottom: 4.6rem;
`;

const StyledInput = styled(Input)`
  padding: 1.6rem 2rem;
  height: 4.3rem;
`;

const StyledButton = styled(CustomButton)`
  position: flex;
  align-items: center;
`;

const initialAddRecommendationRequest = {
  fund: null,
  seller: null,
  start_date: useFormatDate(new Date()),
  end_date: useFormatDate(new Date()),
};

const initialValues = {
  name: "",
  company: "",
  email: "",
  phone: "",
  add_recommendation_requests: [
    initialAddRecommendationRequest,
    initialAddRecommendationRequest,
    initialAddRecommendationRequest,
    initialAddRecommendationRequest,
    initialAddRecommendationRequest,
  ],
};

const AddRecommendations: React.FC = () => {
  useSetHeader({ title: t.addRecommendationsPage.title, hasSubtitle: false });
  const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const [isFormValid, setFormValidity] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const history = useHistory();
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      const add_recommendation_requests = values.add_recommendation_requests.filter(
        (r) => isAddRecommendationRequestValid(r) && r
      );
      const data = { ...values, add_recommendation_requests };
      FunderService.postRecommendation(data)
        .then((response) => {
          const data = JSON.parse(response);
          addToast(t.addRecommendationsPage.alertSuccess, {
            appearance: "success",
            autoDismiss: true,
          });
          resetForm();
          history.push(`${routes.recommendedFunds}/1`);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 400) {
            addToast(error.response.data.email[0], {
              appearance: "error",
              autoDismiss: true,
            });
          } else {
            addToast(t.addRecommendationsPage.alertError, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    },
  });

  const isAddRecommendationRequestValid = (addRecommendationRequest) => {
    return (
      addRecommendationRequest.fund !== null &&
      addRecommendationRequest.seller !== null
    );
  };

  const checkFormValidity = () => {
    const {
      name,
      company,
      email,
      phone,
      add_recommendation_requests,
    } = formik.values;
    const isCompanyInformationValid =
      name !== "" && company !== "" && phone !== "" && emailPattern.test(email);
    const isAtLeastOneAddRecommendationRequestValid = add_recommendation_requests.some(
      (r) => isAddRecommendationRequestValid(r)
    );
    const isFormValid =
      isCompanyInformationValid && isAtLeastOneAddRecommendationRequestValid;
    return isFormValid;
  };

  useEffect(() => setFormValidity(checkFormValidity()), [formik.values]);

  const [sellers, setSellers] = useState<Array<Seller>>([]);
  useEffect(() => {
    FunderService.getFundSellers()
      .then((response) => {
        const data = JSON.parse(response);
        setSellers(data.map((s) => ({ value: s.id, label: s.name })));
      })
      .catch((error) => {
        console.log(
          `%cCaught fetch error:\n ${error}`,
          "color: #f50; background: yellow"
        );
        history.push(routes.signIn);
      });
  }, []);

  const addRecommendationRequests = formik.values.add_recommendation_requests.map(
    (_, index) => {
      return (
        <AddRecommendationsRecord
          key={index}
          formik={formik}
          index={index}
          sellers={sellers}
        />
      );
    }
  );

  return (
    <WithPagination isLoading={isLoading}>
      <MainContainer marginTop="4.4rem">
        <HorizontalGrid columnPattern={"repeat(2, 1fr)"}>
          <div>
            <Text>{t.addRecommendationsPage.text}</Text>
            <StyledTitle>{t.addRecommendationsPage.email}</StyledTitle>
            <StyledTitle>{t.addRecommendationsPage.phone}</StyledTitle>
          </div>
        </HorizontalGrid>
        <ContentWrapper onSubmit={formik.handleSubmit}>
          <FundItem title={"Company information"}>
            <HorizontalGrid columnPattern={"repeat(4, 1fr)"} gap="1.6rem">
              <StyledInput
                placeholder={t.addRecommendationsPage.namePlaceholder}
                colorTheme="dark"
                id="name"
                name="name"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <StyledInput
                placeholder={t.addRecommendationsPage.companyPlaceholder}
                colorTheme="dark"
                id="company"
                name="company"
                onChange={formik.handleChange}
                value={formik.values.company}
              />
              <StyledInput
                placeholder={t.addRecommendationsPage.emailPlaceholder}
                colorTheme="dark"
                type="email"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              <StyledInput
                placeholder={t.addRecommendationsPage.phonePlaceholder}
                colorTheme="dark"
                id="phone"
                name="phone"
                type="phone"
                onChange={formik.handleChange}
                value={formik.values.phone}
              />
            </HorizontalGrid>
          </FundItem>
          <div>
            <FormWrapper>
              <HorizontalGrid columnPattern={"repeat(4, 1fr)"} gap="1.6rem">
                <FundItem title={t.fundName}>
                  <span></span>
                </FundItem>
                <FundItem title={t.fundSeller}>
                  <span></span>
                </FundItem>
                <FundItem title={t.startDate}>
                  <span></span>
                </FundItem>
                <FundItem title={t.endDate}>
                  <span></span>
                </FundItem>
              </HorizontalGrid>
            </FormWrapper>
            <RecordsWrapper>{addRecommendationRequests}</RecordsWrapper>
          </div>
          <CustomButton
            disabled={!isFormValid}
            content={t.submit}
            type="submit"
            size="small"
            colorTheme="dark"
          />
        </ContentWrapper>
      </MainContainer>
      <Footer type="logoOnly" />
    </WithPagination>
  );
};

export default AddRecommendations;
