import styled from "styled-components";

const MainWrapper = styled.main`
  padding: 5.5rem 0;
  background: ${({ theme }) => theme.color.main};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

export default MainWrapper;
