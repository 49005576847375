export const t = {
	cancel: 'Löschen',
	confirm: 'Bestätigen',
	login: 'Anmelden',
	logIn: 'Anmelden',
	loginModalError:
		'Sie wurden aus Sicherheitsgründen abgemeldet. Nochmal anmelden.',
	password: 'Passwort',
	contact: 'Kontakt',
	email: 'Email',
	name: 'Name',
	send: 'Send',
	fundPage: 'Fund Page',
	searchFundsHeader: 'Einzelfonds',
	searchFunds: 'Fonds suchen',
	peergroups: 'Peergruppen',
	peergroupHeader: 'Peergruppe:',
	performanceScoring: 'Performance Scoring',
	assetManager: 'Asset Manager',
	assetManagerModalHeader: 'Suche Asset Manager',
	choosePeergroups: 'Wähle Peergruppe',
	assetClass: 'Assetklasse',
	company: 'Firma',
	phone: 'Telefon',
	nameIsin: 'Name, ISIN',
	yourProblem: 'Ihr Problem',
	fundName: 'Fund Name',
	forgotPassword: 'Passwort vergessen?',
	rememberMe: 'Save login detalis',
	wrongPassword: 'Deine e-mail oder dein passwort ist falsch',
	back: 'Zurück',
	highPotential: 'Hohes Potential',
	mediumPotential: 'Mittleres Potential',
	lowPotential: 'Niedriges Potential',
	highPotentialTooltip:
		'Fondsselektoren mit hohem Potenzial haben einen hohen Anteil an externen Fonds, einen relativ hohen Marktanteil und eine hohe Änderungsrate',
	mediumPotentialTooltip:
		'Fondsselektor mit mittlerem Potenzial haben mindestens zwei hohe Punktzahlen innerhalb der drei Dimensionen Marktanteil, Änderungsrate und Anteil externer Fonds',
	lowPotentialTooltip:
		'Fondsselektor mit geringem Potenzial haben einen geringen Anteil an neuen Empfehlungen und einen relativ niedrigen oder mittleren Marktanteil - manchmal auch einen geringen Anteil an externen Fonds',
	stronglyAboveAverage: 'Stark über Durchschnitt',
	slightlyAboveAverage: 'Leicht über Durchschnitt',
	average: 'Durchschnitt',
	slightlyBelowAverage: 'Leicht unter Durchschnitt',
	stronglyBelowAverage: 'Stark unter Durchschnitt',
	stronglyAboveAverageTooltip:
		'Stark über Durchschnitt: Performance im obersten Quartil im Vergleich zur Morningstar Peergruppe bzw. Morningstar 5 Sterne Rating',
	slightlyAboveAverageTooltip:
		'Leicht über Durchschnitt: Performance im 2. Quartil im Vergleich zur Peergruppe bzw. Morningstar 4 Sterne Rating',
	averageTooltip: 'Durchschnitt: Morningstar 3 Sterne Rating',
	slightlyBelowAverageTooltip:
		'Leicht unter Durchschnitt: Performance im 3. Quartil im Vergleich zur Peergruppe bzw. Morningstar 2 Sterne Rating',
	stronglyBelowAverageTooltip:
		'Stark unter Durchschnitt: Performance im untersten Quartil im Vergleich zur Peergruppe bzw. 1 Stern Morningstar Rating',
	peergroup: 'Peergruppe',
	rating: 'Morningstar Rating',
	ratingTooltip:
		'Morningstar Sterne-Rating für Fonds, die älter als 3 Jahre sind, basierend auf der risikobereinigten Performance im Vergleich zur Peergruppe. In einigen Peergruppen sind die Fonds nicht vergleichbar, weshalb kein Morningstar-Rating verfügbar ist.',
	externalInternalTooltip:
		'Interne Empfehlungen sind Fonds, die von einem Asset Manager gemanagt werden, der in direkter Beziehung zu dem Fondsselektor steht z.B. eine Tochtergesellschaft ist bzw. zum gleichen Konzern gehört',
	shortTermPerformance: 'Kurzfristige Performance',
	shortTermPerformanceTooltip: `Kurzfristige Performance<br>
		1-Jahres Rendite nach Kosten in Euro im Vergleich zur Morningstar-Peergruppe<br>
		++ 1. Quartil in der Peergruppe<br>
		+ 2. Quartil in der Peergruppe<br>
		- 3. Quartil in der Peergruppe<br>
		-- 4. Quartil in der Peergruppe`,
	longTermPerformance: 'Langfristige Performance',
	longTermPerformanceTooltip: `Langfristige Performance<br>
		5-Jahres Rendite nach Kosten in Euro im Vergleich zur Morningstar-Peergruppe<br>
		++ 1. Quartil in der Peergruppe<br>
		+ 2. Quartil in der Peergruppe<br>
		- 3. Quartil in der Peergruppe<br>
    -- 4. Quartil in der Peergruppe`,
	searchByPeergroupButtonTooltip:
		'Wählen Sie einen Fondsselektor und sehen Sie wie gut einzelne Peergruppen bereits mit Empfehlungen abgedeckt sind',
	startDate: 'Seit wann',
	endDate: 'End Date',
	fundSeller: 'Fondsselektor',
	netFlow1: 'Nettomittelzuflüsse Fonds',
	netFlow2: 'der letzten 3 Monate ',
	netFlow3: 'in EUR Mio.',
	fund: 'Fonds',
	shareClass: 'Anteilsklasse',
	addRecommendations: 'Empfehlungen hinzufügen',
	companyEmail: 'info@lumin-am.com',
	companyPhone: '+49 69 67833342',
	emptyTable: 'Keine Daten zum Anzeigen',
	logout: 'Ausloggen',
	noMatchingData: 'Keine übereinstimmenden Daten',
	scorecardButton: 'Go to Scorecard',
	filters: 'Filter:',
	next: 'Nächster',
	previous: 'Bisherige',
	fundListTooltip:
		'Analysieren Sie Empfehlungslisten von über 50 Banken und Versicherungen in Deutschland, und nutzen Sie verschiedene Filtermöglichkeiten',
	searchFundTooltip:
		'Suchen Sie in Deutschland zum Verkauf zugelassene Fonds und lassen Sie sich anzeigen auf welchen Empfehlungslisten diese aktuell stehen',
	peergroupTooltip:
		'Lassen Sie sich pro Peergruppe die Fonds aus den Empfehlungslisten anzeigen',
	assetmanagerTooltip:
		'Hier erhalten Sie einen schnellen Überblick über die in Deutschland zum Vertrieb zugelassenen Fonds pro Asset Manager',
	header: {
		title: 'Fund Seller Database',
		pageName: 'LuminAM Fund Seller Database',
		informationBarTextContent:
			'Here you have the possibility to check current recommendations of fund sellers in Germany, to analyze new sales opportunities and thus to significantly increase your sales success',
	},
	aboutUs: 'Über Uns',
	legalInformation: 'Rechtliche Hinweise',
	news: 'News',
	contactPage: {
		title: 'Kontakt',
		formTitle: 'Kontaktdaten',
	},
	loginButton: 'Anmelden',
	notRegistered: 'Noch nicht registriert? Registrieren',
	pleaseChoose: 'Please choose',
	submit: 'Absenden',
	landingPage: {
		newTitle: {
			newTitle1: 'LuminAM Asset Management Advisory',
			newTitle2: 'Fund Selection',
		},
		newSubtitle: {
			newSubtitle1: 'Maßgeschneiderte Analysen und Tools für die',
			newSubtitle2: 'Fondsindustrie',
		},
		newDescription:
			'Mit wenigen Klicks Zugang zu einzigartiger Datenbasis mit über 3.000 Fondsempfehlungen von mehr als 50 Banken und Versicherungen sowie den größten Dachfonds in Deutschland – monatlich aktualisiert',
		title1: {
			default: 'Potential Fondsselektoren',
			seller: 'Fund Seller Scorecard',
		},
		title2: 'Funds',
		title3: {
			seller: 'Asset Manager Potential',
			default: 'Asset Manager Scorecard',
		},
		subtitle1: {
			default: 'Which fund sellers offer attractive opportunities',
			seller: 'View Own Recommendations',
		},
		subtitle2: 'Which fund sellers offer attractive opportunities',
		subtitle3: {
			seller: 'Check potential of new funds',
			default:
				'On which recommendation lists are the own funds currently listed',
		},
		subtitle4: 'Or choose a fund seller directly',
		checkbox1: 'List of current recommendations',
		checkbox2: 'Gap Analysis',
		link1: 'New funds on the recommendation lists',
		link2: 'Fonds mit den meisten Empfehlungen',
		label1: 'Choose a fund seller',
		label2: 'Name, ISIN',
		label3: 'Name',
		invalidForm:
			'There must be any value selected from above list and one of these checkboxes choosen',
	},
	fundPageList: {
		title: 'Fondsselektor:',
		information: 'Stand:',
		lowCoverage: 'Keine Abdeckung',
		mediumCoverage: 'Moderate Abdeckung',
		highCoverage: 'Gute Abdeckung',
		equities: 'Aktien',
		fixedIncome: 'Anleihen',
		multiAsset: 'Mischfonds',
		property: 'Immobilien',
		other: 'Sonstige',
	},
	potential: {
		highPotential: 'Top Performance',
		mediumPotential: 'Durchschnittliche Performance',
		lowPotential: 'Niedrige Performance',
		highTooltip:
			'Fonds mit überzeugendem Rating oder einer Performance im 1. Quartil innerhalb der Morningstar-Peergruppe (Median der kurz- und langfristigen Performance)',
		mediumTooltip:
			'Fonds mit durchschnittlichem Rating oder einer Performance im 2. Quartil innerhalb der Morningstar-Peergruppe (Median der kurz- und langfristigen Performance)',
		lowTooltip:
			'Fonds mit schwachem Rating oder einer Performance im 3. oder 4. Quartil innerhalb der Morningstar-Peergruppe (Median der kurz- und langfristigen Performance)',
	},
	coverage: {
		lowCoverage: 'Keine Abdeckung',
		mediumCoverage: 'Moderate Abdeckung',
		highCoverage: 'Gute Abdeckung',
		highTooltip:
			'Mehr als 2 Fonds pro Peergruppe in der aktuellen Empfehlungslisten',
		mediumTooltip:
			'1-2 Fonds pro Peergruppe in der aktuellen Empfehlungslisten',
		lowTooltip:
			'Aktuell kein Fonds pro Peergruppe in der aktuellen Empfehlungsliste',
	},
	recommendedFundsPage: {
		title: 'Empfehlungslisten',
		information: 'Stand:',
		buttonContent: 'Abdeckung nach Peergruppen',
		buttonOverview: 'Übersicht über alle Fondsselektoren',
		buttonNewFunds: 'Neue Fonds auf den Empfehlungslisten',
		buttonMostRecommendations: 'Fonds mit den meisten Empfehlungen',
		tableHeaders: {
			shareClass: 'Fonds',
			fundSeller: 'Fondsselektor',
			category: 'Kategorie',
			peergroup: 'Peergruppe',
			assetManager: 'Asset Manager',
			strategy: 'Strategie',
			internalExternal: 'Ext/Int',
			shortTermPerformance: 'KP',
			longTermPerformance: 'LP',
			rating: 'MR',
		},
	},
	chart: {
		titleLeftTop: 'Geringes Absatzpotential',
		titleLeftBottom: 'Niedriges Potential',
		titleRightTop: 'Hohes Potential',
		titleRightBottom: 'Schwieriger Markteintritt',
		xAxis: 'Marktanteil im Fondsvertrieb',
		yAxis: 'Anteil Externer Fonds',
	},
	newFunds: {
		title: 'Neue Fonds auf den Empfehlungslisten',
		fund: 'Fonds',
		peergroup: 'Peergruppe',
		assetManager: 'Asset Manager',
		fundSeller: 'Fondsselektor',
		rating: 'MR',
	},
	fundSellerPotential: {
		title: 'Potential Fondsselektoren',
	},
	fundDetail: {
		title: 'Fonds:',
		currentRecommendations: 'Aktuelle Empfehlungen',
		shareClass: 'Anteilsklasse',
		isin: 'ISIN',
		fundSeller: 'Fondsselektor',
		startDate: 'Seit wann',
		shareClasses: 'Anteilsklassen',
		fundSellerPotential: 'Potential Fondsselektoren',
		recommendations: 'Anzahl Empfehlungen in Peergroup',
		marketShare: 'Marktanteil im Fondsvertrieb',
		marketShareTooltip:
			'Geschätzter Marktanteil am Fondsvertrieb in Deutschland - Maktanteil der jeweiligen Institution basierend auf Referenzwerte (Bsp. Anteil Deutsche Bank am Gesamteinlagengeschäft * Anteil Banken am Gesamtfondsvertrieb)',
		changeRate: 'Änderungsrate',
		changeRateTooltip:
			'Anzahl der neuen Fonds auf den Empfehlungslisten in den letzten 6 Monaten im Vergleich zur Gesamtzahl der empfohlenen Fonds aktuell',
		shareExternalFunds: 'Anteil externer Fonds',
		peergroup: 'Peergruppe',
		rating: 'MR',
		shortTermPerformance: 'STP',
		longTermPerformance: 'LTP',
		highPotential: 'Hohes Potential',
		highPotentialTooltip:
			'Fondsselektoren mit hohem Potenzial haben einen hohen Anteil an externen Fonds, einen relativ hohen Marktanteil und eine hohe Änderungsrate. Asset Manager haben hier zum einen recht große Chancen auf den Empfehlungslisten zu landen und gleichzeitig ihre Fonds einem großen Kundenstamm anzubiete',
		mediumPotential: 'Mittleres Potential',
		mediumPotentialTooltip:
			'Fondsselektoren mit mittlerem Potenzial haben in der Regel einen hohen Anteil externer Fonds oder einen hohen Marktanteil bzw. nehmen oft neue Fonds auf. Hier ist also das potential noch recht moderat auf den Empfehlungslisten zu landen und gleichzeitig relativ viele Kunden zu erreichen.',
		lowPotential: 'Niedriges Potential',
		lowPotentialTooltip:
			'Fondsselektoren mit geringem Potenzial haben einen geringen Anteil an neuen Empfehlungen und einen relativ niedrigen oder mittleren Marktanteil - manchmal auch einen geringen Anteil an externen Fonds.  Grundsätzlich ist hier also die Chance recht gering auf den Empfehlungslisten zu landen und selbst wenn, ist der Kundenstamm des Fondsselektors recht klein.',
	},
	addRecommendationsPage: {
		title: 'Empfehlungen hinzufügen',
		text:
			'Sie vermissen in unseren Quellen einen Fonds, der auf einer Empfehlungsliste erscheint? Wenn Sie uns darüber informieren möchten, freuen wir uns und sie helfen mit, die einzigartige Datenbank zu verbessern! Schnell und einfach das Formular benutzen oder kontaktieren Sie uns jederzeit per E- Mail oder Telefon. ',
		email: 'request@lumin-am.com',
		phone: '+49 69 123456789',
		namePlaceholder: 'Name',
		companyPlaceholder: 'Company',
		emailPlaceholder: 'E-mail',
		phonePlaceholder: 'Phone',
		alertSuccess:
			'Vielen Dank, dass Sie den Fragebogen ausgefüllt haben. Nachricht wurde gesendet.',
		alertError: 'Es ist ein Fehler aufgetreten. Überprüfen Sie die Felder',
	},
	aboutUsPage: {
		title: 'Über Uns',
		subtitle: 'Mehr Informationen über uns und unsere Methodik',
		text1: 'Entweder 24/7 und mit wenigen Klicks oder im persönlichen Kontakt',
		text2:
			'So oder so bieten wir einen schnellen, kundenorientierten und flexiblen Service',
		textNew1:
			'LuminAM hat sich auf das Sammeln, Aggregieren und Analysieren von Daten zum Fondsmarkt mit Hilfe neuester Methoden und automatisierten Lösungen aus diversen bzw. bisher ungenutzten Quellen spezialisiert. Darüber hinaus haben wir den flexibelsten und kundenorientiertesten Ansatz zur Unterstützung des Fondsvertriebs entwickelt, der von unseren langjährigen Kunden äußerst geschätzt wird.',
		textNew2:
			'Neben unseren innovativen webbasierten Tools erstellen wir für unsere anspruchsvollen Kunden qualitativ hochwertige und maßgeschneiderte Präsentationen und datengetriebene Analysen für die Vertriebsunterstützung und Vertriebssteuerung von Fondsgesellschaften.',
		textNew3:
			'Unsere Mitarbeiter verfügen über umfassende Kenntnisse des Fondsmarkts und in der Recherche und Aufbereitung von Informationen - dank ihrer akademischen Ausbildung und langjährigen Berufserfahrung in renommierten Unternehmensberatungen (McKinsey & Company) und Banken (Deutsche Bank Research).',
		header1: 'Wer sind wir und was zeichnet uns aus?',
		header2: 'Methodik Best Practice Fondsselektiondatenbank',
		header3: 'Das bieten wir:',
		header4: 'Ihre Vorteile:',
		list1: [
			'Umfassende Kenntnisse des Fondsmarktes',
			'Langjährige Erfahrung in den Bereichen Research und Analyse von Fondsprodukten mit Berufserfahrung bei McKinsey & Company und Deutsche Bank Research unter Norbert Walter',
			'Spezialisierung auf die Beratung und Vertriebsunterstützung der Fondsindustrie',
		],
		list2: [
			'Wir sind unabhängig und können somit objektive Informationen über Märkte, Produkte und Wettbewerber liefern',
			'Präzision und Vertrauen, denn das ist die härteste Währung in unserer Branche',
		],
		list3: [
			'Wir sammeln aus öffentlich verfügbaren Dokumenten und Webseiten die Fondsempfehlungen der größten Banken, Versicherungen sowie die Investments der wichtigsten Dachfonds in Deutschland und aktualisieren diese monatlich',
			'Die Fondsempfehlungen werden mit Stammdaten sowie aktuellen Performancekennzahlen und Ratings ergänzt, analysiert und klassifiziert',
			'Auf Basis der historischen Entwicklung der Empfehlungslisten und einem geschätzten Marktanteil beurteilen wir das Potential und die Bedeutung der einzelnen Fondselektoren am deutschen Markt',
			'Die Fondsempfehlungen der Banken beziehen sich zum Großteil auf Privatkunden, wobei meist nur eine Auswahl an Fonds auf den internen Empfehlungslisten öffentlich gemacht wird und damit in unserer Datenbank enthalten ist',
			'Die Fonds der Versicherungen beziehen sich in der Regel auf die Fondsauswahl für fondsgebundenen Rentenversicherungen(nur Neugeschäft inkl.Riester, Rürup und betriebliche Altersvorsorge)',
			'Die Investments der Dachfonds werden meist mit einiger Verspätung berichtet, daher kann es vorkommen, dass die Fondslisten hier einige Monate im Verzug sind.In unserer Datenbank sind aber immer die zuletzt berichteten Holdings enthalten',
			'Bei weiteren Fragen zur Abdeckung, Datensammlung und Methodik wenden Sie sich jederzeit an uns',
		],
		list4: [
			'Umfassende und flexible Lösungen zu Produkten, Märkten und Wettbewerbern',
			'Informationen, mit deren Hilfe sie sich erfolgreich im Wettbewerb durchsetzen können',
		],
	},
	newsPage: {
		title: 'News',
		pressRelease: 'Pressemitteilung',
		research: 'Research',
		list: [
			'LuminAM launches unique distribution tool for Asset Managers in Germany',
			'Only 3 out of 5 funds in the recommendation lists of German banks and insurance companies have a top rating',
			'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
			'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
			'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor',
		],
	},
	peergrupDetails: {
		peergroup: 'Peergruppe:',
		currentRecommendations: 'Aktuelle Empfehlungen',
		alternatives: 'Alternativen',
		requestCompetitorAnalysis: 'Wettbewerbsanalyse anfragen',
	},
	requestCompetitorAnalysis: {
		text1:
			'Gerne erstellen wir Ihnen eine individuelle Wettbewerbsanalyse mit einem Vergleich der Performance, des Risikos und aktueller Holdings des ausgewählten Fonds gegenüber dem Durchschnitt der Peergruppe, der Benchmark und den ausgewählten Wettbewerbern.',
		text2:
			'Einfach das Anfrageformular ausfüllen oder kontaktieren Sie uns jederzeit per Email oder Telefon direkt. ',
		email: 'request@lumin-am.com',
		phoneNumber: '+49 69 123456789',
		peergroup: 'Peergruppe',
		currentRecommendations: 'Current Recommendations',
		alternatives: 'Alternatives',
		requestCompetitorAnalysis: 'Wettbewerbsanalyse anfragen',
		companyInformation: 'Kontaktdaten',
		namePlaceholder: 'Name',
		companyPlaceholder: 'Company',
		emailPlaceholder: 'E-mail',
		phonePlaceholder: 'Phone',
		fund: 'Fonds',
		nameIsinPlaceholder: 'Name, ISIN',
		competitors: 'Wettbewerber',
		checkbox1Content: '5 Größte Fonds aus der selben Kategorie',
		checkbox2Content: 'Ausgewählte Wettbewerber (Bitte Name/ ISIN eingeben)',
		comment: 'Kommentar',
		commentPlaceholder: 'Kommentar',
		alertSuccess:
			'Vielen Dank, dass Sie den Fragebogen ausgefüllt haben. Nachricht wurde gesendet.',
		alertError: 'Es ist ein Fehler aufgetreten. Überprüfen Sie die Felder',
	},
	legalInformationPage: {
		title: 'Rechtliche Hinweise',
		privacyNotice: 'Impressum / Datenschutz / Haftung / Urheberrechte',
		list: [
			'Eingetragen im Registergericht: Amtsgericht Frankfurt am Main',
			'Registernummer im Handelsregister Frankfurt am Main: HRB 104992',
			'Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz: DE306066467',
			'Vertreten durch die Geschäftsführerin Johanna Zoelch',
		],
		heading1: 'Datenschutzunterrichtung',
		heading2: 'Haftungshinweis',
		heading3: 'Urheberrecht',
		text1: `Über die datenschutzrechtlich relevanten Aspekte bei der Nutzung der Seite „www.lumin-am.com“ möchten wir, LuminAM UG (haftungsbeschränkt), Eschborner Landsraße 42-50, Haus B, 60489 Frankfurt am Main, (nachfolgend „LuminAM“), Sie wie folgt informieren:

Wir speichern auf Ihrem Computer Cookies.
Cookies sind kleine Identifizierungszeichen, die unser Webserver an Ihren Browser sendet und die Ihr Rechner bei entsprechender Standard- Einstellung speichert.
Um die von Ihnen gewählte Sprache zu speichern und Ihnen die Webseiteninhalte in der jeweiligen Sprache anzuzeigen, setzten wir ein Cookie mit einer Gültigkeit von 14 Tagen ein.Nach Ablauf der
Gültigkeit löscht Ihr Browser das Cookie.Das Cookie selbst enthält keine identifizierenden Merkmale, sondern lediglich ein Kürzel für die Sprache, z.B.„de“ für Deutsch oder „en“ für Englisch.
Über Sie ist uns – im Internet technisch notwendig – lediglich Ihre IP- Adresse bekannt.Wir haben dabei keine unmittelbare Möglichkeit, die IP-Adresse Ihrer Person zuzuordnen.Trotz Mitteilung der IP - Adresse und der Setzung der Cookies, bleiben Sie für uns beim Besuch unserer Webseiten daher anonym, solange Sie uns nicht bewusst zusätzlich Ihre Identität mitteilen.
Bitte beachten Sie, dass Sie das Setzen eines Cookies durch die entsprechende Einstellung in Ihrem Browser vollständig unterbinden können.

Durch den Besuch unserer Seite können Informationen über den Zugriff(Datum, Uhrzeit, betrachtete Seite, verwendeter Browser) gespeichert werden.Diese Nutzungsdaten werden nur pseudonymisiert verarbeitet und genutzt.Sie werden ausschließlich zu statistischen Zwecken ausgewertet.

Bei Fragen, Hinweisen oder Erklärungen zum Datenschutz wenden Sie sich gerne über folgende E - Mail - Adresse an uns: info@lumin-am.com
`,
		text2: `Alle Angaben dieses Internetangebotes wurden sorgfältig geprüft. Wir bemühen uns, dieses Informationsangebot stetig zu erweitern und zu aktualisieren. Eine Garantie für die Vollständigkeit, Richtigkeit und letzte Aktualität kann jedoch nicht übernommen werden. Die LuminAM UG (haftungsbeschränkt) stellt diese Informationen ohne jegliche Zusicherung oder Gewährleistung jedweder Art, sei sie ausdrücklich oder stillschweigend, zur Verfügung. Die LuminAM UG (haftungsbeschränkt) schließt jegliche Haftung für Schäden, die direkt oder indirekt aus der Benutzung dieser Website entstehen, aus, soweit diese nicht auf Vorsatz oder grober Fahrlässigkeit von der LuminAM UG (haftungsbeschränkt) beruhen. Die LuminAM UG (haftungsbeschränkt) ist als Inhaltsanbieter nach § 7 Abs. 1 TMG für die eigenen Inhalte, die auf dieser Website zur Nutzung bereitgehalten werden, nach den allgemeinen Gesetzen verantwortlich. Von diesen eigenen Inhalten sind Querverweise („Links“) auf die von anderen Anbietern bereitgehaltenen Inhalte zu unterscheiden. Durch den Querverweis hält die LuminAM UG (haftungsbeschränkt) insofern fremde Inhalte zur Nutzung bereit. Diese fremden Inhalte wurden bei der erstmaligen Link-Setzung daraufhin überprüft, ob durch sie eine mögliche zivilrechtliche oder strafrechtliche Verantwortlichkeit ausgelöst wird. Es ist jedoch nicht auszuschließen, dass die Inhalte im Nachhinein von den jeweiligen Anbietern verändert werden. Die LuminAM UG (haftungsbeschränkt) überprüft die Inhalte, auf die sie in ihrem Angebot verweist, nicht ständig auf Veränderungen, die eine Verantwortlichkeit neu begründen könnten. Sollten Sie der Ansicht sein, dass die verlinkten externen Seiten gegen geltendes Recht verstoßen oder sonst unangemessene Inhalte haben, so teilen Sie uns dies bitte mit.
Es gilt das Recht der Bundesrepublik Deutschland unter Ausschluss des Übereinkommens der Vereinten Nationen über Verträge über den internationalen Warenkauf (CISG).
Ist der Kunde Kaufmann oder eine juristische Person des öffentlichen Rechts oder ein öffentlich-rechtliches Sondervermögen oder hat er keinen allgemeinen Gerichtsstand in Deutschland, ist ausschließlicher Gerichtsstand für alle Rechtsstreitigkeiten, die aus oder im Zusammenhang mit der Benutzung der Website resultieren, Frankfurt, Deutschland.
`,
		text3: `Copyright 2020 / LuminAM UG (haftungsbeschränkt). Alle Rechte vorbehalten. Alle Inhalte (Texte, Bilder, Grafiken, Ton-, Video- und Animationsdateien sowie deren Anordnung u. a.) auf dieser Website unterliegen dem Schutz des Marken- und/oder Urheberrechts sowie anderer Schutzgesetze. Der Rechtsschutz gilt auch gegenüber Datenbanken und ähnlichen Einrichtungen. Sofern nicht anderweitig ausdrücklich erlaubt, sind die Inhalte nur für den bestimmungsgemäßen Abruf im Internet frei nutzbar. Die Inhalte dieser Website dürfen außerhalb der Grenzen des Urheberrechts ohne schriftliche Genehmigung nicht in irgendeiner Form vervielfältigt, verbreitet, verändert oder Dritten zugänglich gemacht werden. Soweit nicht anders angegeben, sind alle Markenzeichen auf dieser Website markenrechtlich geschützt.
`,
	},
	exceptionPage: {
		title: 'Ausnahme 404',
		message: 'Es gibt keine Website, die Sie suchen.',
		back: 'Zurück zur Startseite',
	},
	contactUs: {
		text1:
			'Sind Sie an unserer Datenbank interessiert oder benötigen Sie Unterstützung bei der Nutzung der Datenbank? Nutzen Sie unser Kontaktformular und wir werden uns so schnell wie möglich bei Ihnen melden.',
		text2:
			'Sie können uns auch jederzeit direkt per E-Mail oder Telefon kontaktieren:',
		email: 'info@lumin-am.com',
		telephoneNumber: '+49 69 678333 42',
		telephoneNumber2: '+49 176 20976299',
		checkbox1: 'Ich habe Interesse an der Datenbank',
		checkbox2: 'Ich benötige Unterstützung bei der Nutzung',
	},
	recommendationChartPage: {
		title: 'Fonds mit den meisten Empfehlungen',
		fund: 'Fonds',
		position: 'Anzahl Empfehlungen',
		peergroup: 'Peergruppe',
		netFlows: 'Nettomittelzuflüsse der letzten 3 Monate',
		eurMio: 'in EUR Mio.',
		eurBn: 'in EUR Mrd.',
		aum: 'AuM',
	},
	signInPage: {
		list: [
			'Monthly updated recommendation lists from over 40 banks and insurance companies in Germany',
			'Direct comparison of own products with potential analysis',
			'Unique opportunity to prepare appointments with fund sellers and offer them tailor - made funds',
		],
	},
	assetManagerScorecard: {
		title: 'Asset Manager Scorecard:',
		tableTitle: 'Current recommendations',
	},
	assetManagerView: {
		title: 'Asset Manager View:',
		tableTitle: 'In Deutschland zum Verkauf zugelassene Fonds',
		assetClass: 'Assetklasse',
		category: 'Kategorie',
		peergroup: 'Peergruppe',
	},
	footer: {
		list: {
			default: [
				'Monthly updated recommendation lists from over 40 banks and insurance companies in Germany',
				'Direct comparison of own products with potential analysis',
				'Unique opportunity to prepare appointments with fund sellers and offer them tailor-made funds',
			],
			seller: [
				'Monthly updated recommendation lists  from over 40 banks and Insurance companies in Germany',
				'Check recommendation lists from other fund sellers using collective intelligence to improve your own recommendations',
				'Quickly review potential new funds for your recommendation lists',
			],
		},
	},
	passwordRemindPage: {
		title: 'Passwort vergessen?',
		subtitle: {
			step1: 'Bitte geben sie die Ihren Benutzername ein.',
			step2: 'Bitte geben sie die Ihren Benutzername ein.',
		},
		back: 'Zurück zum Login',
		changePassword: 'Passwort ändern',
		newPassword: 'Neues Kennwort',
		repeatPassword: 'Passwort wiederholen',
		alertSuccessRemindPassword:
			'Vielen Dank, dass Sie den Fragebogen ausgefüllt haben. Anweisungen zum Ändern des Passworts wurden an Ihre E-Mail-Adresse gesendet.',
		alertErrorRemindPassword:
			'Es ist ein Fehler aufgetreten. Überprüfen Sie die Felder',
		alertSuccessChangePassword: 'Passwortänderung war erfolgreich.',
		alertErrorChangePassword:
			'Es ist ein Fehler aufgetreten. Überprüfen Sie die Felder',
		alertEmailNotFound: 'nicht in Datenbank gefunden',
	},
	gapAnalysisModal: {
		inputLabel: 'Fondsselektor',
	},
	contactForm: {
		alertError: 'Es ist ein Fehler aufgetreten. Überprüfen Sie die Felder',
		alertSuccess:
			'Vielen Dank, dass Sie den Fragebogen ausgefüllt haben. Nachricht wurde gesendet.',
	},
	noNewsAvailableYet: 'Keine News verfügbar...',
};
