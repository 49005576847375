import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { slide as Menu } from 'react-burger-menu';
import HamburgerIcon from '../../images/hamburger-icon.svg';
import LogoIcon from '../../images/lumin-logo.png';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../images/hamburger-close.svg';
import FooterLink from '../FooterLink/FooterLink';
import { routes } from '../../routes/routes';
import { t } from '../../translations/en';
import { useLogout } from '../../hooks/useLogout';
import Tooltip from '../Tooltip/Tooltip';
import { useSetModal } from '../../hooks/useSetModal';

const styles = {
	bmBurgerButton: {
		position: 'relative',
	},
	bmBurgerBars: {
		background: '#373a47',
	},
	bmBurgerBarsHover: {
		background: '#a90000',
	},
	bmCrossButton: {
		height: '24px',
		width: '24px',
		visibility: 'hidden',
	},
	bmCross: {
		background: '#bdc3c7',
	},
	bmMenuWrap: {
		position: 'fixed',
		right: '0px',
		top: '0',
		zIndex: '1100',
		width: '36.6rem',
		height: '100%',
		transition: 'all 0.5s ease 0s',
	},
	bmMenu: {
		background: '#ffffff',
		padding: '0.5em 1.5em 0',
		fontSize: '1.15em',
	},
	bmMorphShape: {
		fill: '#373a47',
	},
	bmItemList: {
		color: '#b8b7ad',
		padding: '0.8em',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'space-between',
	},
	bmItem: {
		display: 'flex',
	},
	bmOverlay: {
		background: 'rgba(0, 0, 0, 0.3)',
	},
};

const LuminLogo = styled.img`
	width: 71%;
`;

const DividerWrapper = styled.div`
	display: flex !important;
	justify-content: space-between;
	align-items: center;
	margin-top: 2rem;
`;

const CustomDivider = styled.hr`
	width: 22.5rem;
`;

const BottomDivider = styled.hr`
	width: 11rem;
	margin-bottom: 3.7rem;
`;

const CloseButton = styled.img`
	:hover {
		cursor: pointer;
	}
`;

const LinksWrapper = styled.section`
	display: block !important;
	height: auto !important;
`;

const LinksSection = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-top: 7.4rem;
`;

const CustomFooterLink = styled(FooterLink)`
	width: 100%;
	display: block;
	user-select: none;
`;

const CustomLogoutLink = styled(CustomFooterLink)`
	color: ${({ theme }) => theme.color.main};
	margin-top: 2rem;
	:hover {
		color: ${({ theme }) => theme.color.black};
	}
`;

const BottomLinksSectionWrapper = styled.div`
	display: flex;
	align-items: flex-end;
	padding-bottom: 3rem;
`;

const BottomLinksSection = styled(LinksSection)`
	margin: 0;
	display: block !important;
	height: min-content;
`;

interface ISideBar {
	verified: boolean;
}

const SideBar: React.FC<ISideBar> = ({ verified }) => {
	const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
	const setModal = useSetModal();
	const token = Boolean(localStorage.getItem('token'));
	const history = useHistory();
	const handleCloseSidebar = () => {
		setIsOpenMenu(false);
	};

	useEffect(() => {
		document.body.addEventListener('click', handleCloseSidebar);
	}, []);

	const menuListener = e => e.stopPropagation();

	useEffect(() => {
		const outerContainer = document.querySelector('#outer-container');
		const menuWrapper = outerContainer.firstChild.firstChild;
		if (isOpenMenu) {
			menuWrapper.addEventListener('click', e => menuListener(e));
		} else {
			menuWrapper.removeEventListener('click', e => menuListener(e));
		}
	}, [isOpenMenu]);

	const sideBarRef = useRef<HTMLDivElement | null>(null);

	const logout = useLogout();

	// **** funds search modal *****
	const handleOpenFundsSearchModal = () => {
		if (token) {
			handleCloseSidebar();
			setModal({
				visible: true,
				title: t.searchFunds,
				component: 'SearchFundsModal',
				doOverflow: false,
			});
		} else {
			location.pathname !== routes.previewPage
				? history.push(`${routes.previewPage}`)
				: handleCloseSidebar();
		}
	};
	// *****************************

	// **** login modal ************
	const handleOpenLoginModal = () => {
		if (!token) {
			handleCloseSidebar();
			setModal({
				visible: true,
				title: t.login,
				component: 'LoginModal',
				doOverflow: false,
			});
		}
	};
	// *****************************

	// **** funds AM modal *********
	const handleOpenAssetManagerModal = () => {
		if (token) {
			handleCloseSidebar();
			setModal({
				visible: true,
				title: t.assetManager,
				component: 'AssetManagerModal',
				doOverflow: false,
			});
		} else {
			location.pathname !== routes.previewPage
				? history.push(`${routes.previewPage}`)
				: handleCloseSidebar();
		}
	};
	// *****************************
	const handleTriggerModal = () => {
		if (token) {
			handleCloseSidebar();
			setModal({
				visible: true,
				title: t.choosePeergroups,
				component: 'PeergroupsModal',
				doOverflow: false,
			});
		} else {
			location.pathname !== routes.previewPage
				? history.push(`${routes.previewPage}`)
				: handleCloseSidebar();
		}
	};

	return (
		<div id='outer-container'>
			<Menu
				styles={styles}
				right
				noOverlay
				isOpen={isOpenMenu}
				onStateChange={({ isOpen }) => setIsOpenMenu(isOpen)}
				customBurgerIcon={<img src={HamburgerIcon} />}
				ref={sideBarRef}
				onClick={e => e.stopPropagation()}
				outerContainerId={'outer-container'}
			>
				<LinksWrapper>
					<LuminLogo src={LogoIcon} />
					<DividerWrapper>
						<CustomDivider />
						<CloseButton
							src={CloseIcon}
							onMouseDown={() => {
								setIsOpenMenu(false);
							}}
						/>
					</DividerWrapper>
					<LinksSection>
						<Tooltip
							align={'left'}
							sideBar
							tooltip={t.fundListTooltip}
							textAlign='left'
						>
							<CustomFooterLink
								isSidebar
								destination={
									token ? `${routes.recommendedFunds}/1` : routes.previewPage
								}
							>
								{t.recommendedFundsPage.title}
							</CustomFooterLink>
						</Tooltip>
						<Tooltip
							align={'left'}
							sideBar
							tooltip={t.searchFundTooltip}
							textAlign='left'
						>
							<CustomFooterLink
								isSidebar
								onMouseDown={handleOpenFundsSearchModal}
							>
								{t.searchFunds}
							</CustomFooterLink>
						</Tooltip>
						<Tooltip
							align={'left'}
							sideBar
							tooltip={t.peergroupTooltip}
							textAlign='left'
						>
							<CustomFooterLink
								isSidebar
								onMouseDown={handleTriggerModal}
								destination={routes.fundList}
							>
								{t.peergroups}
							</CustomFooterLink>
						</Tooltip>
						<Tooltip
							align={'left'}
							sideBar
							tooltip={t.assetmanagerTooltip}
							textAlign='left'
						>
							<CustomFooterLink
								isSidebar
								onMouseDown={handleOpenAssetManagerModal}
							>
								{t.assetManager}
							</CustomFooterLink>
						</Tooltip>
					</LinksSection>
				</LinksWrapper>
				<BottomLinksSectionWrapper>
					<BottomLinksSection>
						<BottomDivider />
						<CustomFooterLink destination={routes.aboutUs}>
							{t.aboutUs}
						</CustomFooterLink>
						<CustomFooterLink destination={routes.contact}>
							{t.contact}
						</CustomFooterLink>
						<CustomFooterLink destination={routes.news}>
							{t.news}
						</CustomFooterLink>
						<CustomFooterLink destination={routes.legalInformation}>
							{t.legalInformation}
						</CustomFooterLink>
						{token ? (
							<CustomLogoutLink onMouseDown={logout}>
								{t.logout}
							</CustomLogoutLink>
						) : (
							<CustomLogoutLink onMouseDown={handleOpenLoginModal}>
								{t.login}
							</CustomLogoutLink>
						)}
					</BottomLinksSection>
				</BottomLinksSectionWrapper>
			</Menu>
		</div>
	);
};

export default SideBar;
