import React from "react";
import Title from "../Title/Title";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import { t } from "../../translations/en";
import FundItem from "../FundItem/FundItem";
import TabContainer from "../TabContainer/TabContainer";
import TableRow from "../TableRow/TableRow";
import VerticalGrid from "../VerticalGrid/VerticalGrid";
import TableItem from "../TableItem/TableItem";
import InformationMessage from "../InformationMessage/InformationMessage";
import { useCheckDate } from "../../hooks/useCheckDate";

type DataObject = {
  shareClass: string;
  isin: string;
  fundSeller: string;
  startDate: string;
};

interface CurrentRecommendationsInterface {
  data: Array<DataObject>;
}

const CurrentRecommendations: React.FC<CurrentRecommendationsInterface> = ({
  data,
}) => (
  <>
    <FundItem title={t.fundDetail.currentRecommendations}>
      <>
        <TabContainer>
          <HorizontalGrid
            columnPattern={
              "calc((100% / 12)*4) repeat(2,calc((100% / 12)*3)) calc((100% / 12))"
            }
            align="center"
          >
            <Title size="big" colorTheme="dark">
              {t.fundDetail.shareClass}
            </Title>
            <Title size="big" colorTheme="dark">
              {t.fundDetail.isin}
            </Title>
            <Title size="big" colorTheme="dark">
              {t.fundDetail.fundSeller}
            </Title>
            <Title size="big" colorTheme="dark">
              {t.fundDetail.startDate}
            </Title>
          </HorizontalGrid>
        </TabContainer>
        <VerticalGrid gap="0.7rem">
          {data && data.length ? (
            data.map(({ shareClass, isin, fundSeller, startDate }, index) => (
              <TableRow
                key={index}
                columnPattern={
                  "calc((100% / 12)*4) repeat(2,calc((100% / 12)*3)) calc((100% / 12) * 2)"
                }
                type="recommendations"
              >
                <TableItem colorTheme="main">{shareClass}</TableItem>
                <TableItem>{isin}</TableItem>
                <TableItem>{fundSeller}</TableItem>
                <TableItem>{useCheckDate(startDate)}</TableItem>
              </TableRow>
            ))
          ) : (
            <InformationMessage />
          )}
        </VerticalGrid>
      </>
    </FundItem>
  </>
);

export default CurrentRecommendations;
