import styled from 'styled-components';

const VerticalGrid = styled.div<{
	gap?: string,
	rowPattern?: string
}>`
	display: grid;
	grid-row-gap: ${({ gap }) => (gap ? gap : 0)};
	grid-template-rows: ${({ rowPattern }) => (rowPattern ? rowPattern : 'auto')};
`;

export default VerticalGrid;
