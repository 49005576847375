import React, { useState, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import styled from "styled-components";
import Input from "../Input/Input";
import OptionsList from "../OptionList/OptionList";
import OptionItem from "../OptionItem/OptionItem";
import Text from "../Text/Text";
import Loader from "../Loader/Loader";
import { t } from "../../translations/en";

type fontThemeType = "black" | "white";
type colorThemeType = "dark" | "light";

interface ListItem {
  value: number;
  name: string;
}

interface SearchConfig {
  list: Array<any>;
  label: string;
  handleChange: (value: string, currentPage: number) => void;
  handleSelect: (value: boolean, id: number, name?: string) => void;
  isListLoading: boolean;
  className?: string;
  fontTheme?: fontThemeType;
  colorTheme?: colorThemeType;
  name?: string;
  autofocus?: boolean;
  canBeCleared?: boolean;
}

type handleLocalChangeType = (e: React.FormEvent) => void;
type handleSelectOptionType = (value: string, id: number) => void;
type handleOutsideClickType = (target: EventTarget | null) => void;

const SearchWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const RemoveIcon = styled.div<{ fontTheme?: string }>`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  height: 4rem;
  width: 4rem;
  line-height: 4rem;
  text-align: center;
  vertical-align: middle;
  :after {
    content: "\\e80a";
    font-family: ${({ theme }) => theme.font.icon};
    font-size: 1rem;
    color: ${({ theme, fontTheme }) =>
      fontTheme === "black" ? theme.color.black : theme.color.main};
  }
  :hover {
    cursor: pointer;
  }
`;

const SearchInput = styled(Input)<{ fontTheme?: string }>`
  position: relative;
  width: 100%;
  padding-right: 4rem;
  color: ${({ theme, fontTheme }) =>
    fontTheme === "black" ? theme.color.black : theme.color.main};

  ::placeholder {
    color: ${({ theme, fontTheme }) =>
      fontTheme === "black" ? theme.color.black : theme.color.main};
  }
`;

const StyledOptionsList = styled(OptionsList)<{ isListLoading?: boolean }>`
  display: block;
  text-align: ${({ isListLoading }) => (isListLoading ? "center" : "left")};
  border: 1px solid ${({ theme }) => theme.color.main};
`;

const StyledText = styled(Text)`
  pointer-events: none;
`;

const NoData = styled.span`
  text-align: center;
  display: block;
  padding: 1rem 0;
  font-size: ${({ theme }) => theme.font.size.text_M};
`;

const Observer = styled.span`
  display: block;
  background: transparent;
  padding: 0.2rem 0;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Search: React.FC<SearchConfig> = ({
  list,
  label,
  handleChange,
  handleSelect,
  isListLoading,
  className,
  fontTheme,
  colorTheme,
  name,
  autofocus,
  canBeCleared = false,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [editedInput, setEditedInput] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleLocalChange: handleLocalChangeType = (e) => {
    if (currentPage !== 1) setCurrentPage(1);
    const target = e.target as HTMLTextAreaElement;
    const value = target.value;
    setInputValue(value);
    setEditedInput(true);
    handleChange(value, 1);
    if (listRef.current) listRef.current.scrollTo(0, 0);
  };

  const handleFocus: handleLocalChangeType = (e) => {
    if (!editedInput) {
      const target = e.target as HTMLTextAreaElement;
      const value = target.value;
      setInputValue(value);
      setEditedInput(true);
      handleChange(value, currentPage);
    }
  };

  const handleClearForm = () => {
    setInputValue("");
    setEditedInput(false);
    if (!canBeCleared) {
      handleSelect(false, null, "");
    }
  };

  const handleSelectOption: handleSelectOptionType = (value, id) => {
    setInputValue(value);
    setEditedInput(false);
    handleSelect(true, id, value);
    if (canBeCleared) {
      handleClearForm();
    }
  };

  const handleOutsideClick: handleOutsideClickType = (target) => {
    const htmlTarget = target as HTMLTextAreaElement;
    if (
      target &&
      target !== wrapperRef.current &&
      target !== inputRef.current &&
      target !== listRef.current &&
      htmlTarget.parentNode !== listRef.current
    ) {
      setEditedInput(false);
    }
  };

  useEffect(() => {
    if (editedInput) {
      document.addEventListener("mousedown", (e) =>
        handleOutsideClick(e.target)
      );
    } else {
      document.removeEventListener("mousedown", (e) =>
        handleOutsideClick(e.target)
      );
    }
  }, [editedInput]);

  // ***** autoloading on scroll
  const [observerRef, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      handleChange(inputValue, currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  }, [inView]);
  // *****

  return (
    <SearchWrapper className={className} ref={wrapperRef}>
      {inputValue !== "" && (
        <RemoveIcon fontTheme={fontTheme} onClick={handleClearForm} />
      )}
      <SearchInput
        reference={inputRef}
        autofocus={autofocus}
        autoComplete="off"
        placeholder={label}
        value={inputValue}
        name={name}
        onChange={(e) => handleLocalChange(e)}
        onClick={(e) => handleFocus(e)}
        fontTheme={fontTheme}
        colorTheme={colorTheme}
      />
      {editedInput ? (
        <StyledOptionsList
          ref={listRef}
          // isListLoading={isListLoading}
          data-id="autocomplete-list"
        >
          <>
            {list.length > 0 ? (
              <>
                {list.map((item: ListItem, index: number) => (
                  <OptionItem
                    key={index}
                    onClick={() => handleSelectOption(item.name, item.value)}
                  >
                    <StyledText>{item.name}</StyledText>
                  </OptionItem>
                ))}
                <Observer ref={observerRef}></Observer>
              </>
            ) : isListLoading ? (
              <LoaderWrapper>
                <Loader position={"static"} />
              </LoaderWrapper>
            ) : (
              <NoData>{t.noMatchingData}</NoData>
            )}
          </>
        </StyledOptionsList>
      ) : null}
    </SearchWrapper>
  );
};

export default Search;
