import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import Flex from "../Flex/Flex";
import HorizontalGrid from "../HorizontalGrid/HorizontalGrid";
import Title from "../Title/Title";
import { t } from "../../translations/en";
import selectTheme from "../../utils/SelectTheme";
import { useStoreActions, useStoreState } from "../../easy-peasy/hooks/hooks";

const Wrapper = styled.div`
  margin: 3.5rem 0 -1rem 0;
`;

const StyledTitle = styled(Title)`
  line-height: 3.8rem;
  margin-right: 2rem;
`;

interface IFilter {
  strategies: {
    id: string;
    name: string;
  }[];
}

interface IPeergroupFilter {
  peergroups: {
    id: string;
    name: string;
    asset_class?: string;
    url?: string;
  }[];
}

interface IFundSellerFilter {
  fundSellers?: any;
}

interface IInternalExternalFilter {
  internalExternal?: any;
}

interface IFilterResults {
  handleFilterResults?: (
    fundSellerId: any,
    peergroupId: any,
    strategyValue: any
  ) => void;
  setFundSellerId?: React.Dispatch<React.SetStateAction<string>>;
  setPeergroupId: React.Dispatch<React.SetStateAction<string>>;
  setStrategyValue: React.Dispatch<React.SetStateAction<string>>;
  setInternalExternalValue: React.Dispatch<React.SetStateAction<string>>;
  isClearable?: boolean;
  isPeergroupPage?: boolean;
}

interface IOption {
  label: string;
  value: string | number;
}

const customStyles = {
  valueContainer: (provided, state) => ({
    ...provided,
    minHeight: "2rem",
    fontSize: "1.2rem",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    paddingLeft: "0.5rem",
    color: "#008080",
  }),
};

const RecommendedFundsFilters: React.FC<
  IFilter &
    IPeergroupFilter &
    IFilterResults &
    IFundSellerFilter &
    IInternalExternalFilter
> = ({
  strategies,
  peergroups,
  fundSellers,
  internalExternal,
  setFundSellerId,
  setPeergroupId,
  setStrategyValue,
  setInternalExternalValue,
}) => {
  const [strategyFilter, setStrategyFilter] = useState<Array<IOption>>(null);
  const [peergroupFilter, setPeergroupFilter] = useState<Array<IOption>>(null);
  const [fundSellerFilter, setfundSellerFilter] = useState<Array<IOption>>(
    null
  );
  const [internalExternalFilter, setInternalExternalFilter] = useState<
    Array<IOption>
  >(null);
  const [sellerValue, setSellerValue] = useState<Array<IOption>>([]);

  const fundSellerPotentialData = useStoreState(
    (state) => state.fundSellerPotentialModel.items
  );
  const fundSellerPotentialAction = useStoreActions(
    (actions) => actions.fundSellerPotentialModel.addIsFundSellerPotential
  );

  useEffect(() => {
    if (
      fundSellerPotentialData.id !== "0" &&
      fundSellerPotentialData.label !== ""
    ) {
      setSellerValue([
        {
          label: fundSellerPotentialData.label,
          value: fundSellerPotentialData.id,
        },
      ]);
    }
    return () => {
      fundSellerPotentialAction({
        isFundSellerPotential: false,
        id: "",
        label: "",
      });
    };
  }, []);

  useEffect(() => {
    if (strategies) {
      const strategy = [];
      strategies.map((item, index) => {
        let obj = {
          label: item.id,
          value: item.name,
        };
        strategy.push(obj);
      });
      setStrategyFilter(strategy);
    }
  }, [strategies]);

  useEffect(() => {
    if (peergroups) {
      const peergroup = [];
      peergroups.map((item, index) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        peergroup.push(obj);
      });
      setPeergroupFilter(peergroup);
    }
  }, [peergroups]);

  useEffect(() => {
    if (fundSellers) {
      const fundSeller = [];
      fundSellers.map((item, index) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        fundSeller.push(obj);
      });
      setfundSellerFilter(fundSeller);
    }
  }, [fundSellers]);

  useEffect(() => {
    if (internalExternal) {
      const internalExternalArr = [];
      internalExternal.map((item, index) => {
        let obj = {
          label: item.name,
          value: item.id,
        };
        internalExternalArr.push(obj);
      });
      setInternalExternalFilter(internalExternalArr);
    }
  }, [internalExternal]);

  const parseArrayToString = (
    array: Array<{ value: number; label: string }>
  ) => {
    if (array.length) {
      const arrayWithIds = array.map(({ value }) => value);
      return arrayWithIds.join(",");
    }
    return "";
  };

  return (
    <Wrapper>
      <Flex justify="flex-start" align="flex-start">
        <StyledTitle>{t.filters}</StyledTitle>
        <HorizontalGrid columnPattern={"repeat(4,25rem)"} gap="2rem">
          <Select
            styles={customStyles}
            isSearchable={false}
            isMulti
            isClearable
            theme={selectTheme}
            placeholder={t.recommendedFundsPage.tableHeaders.fundSeller}
            options={fundSellerFilter}
            value={sellerValue}
            onChange={(value) => {
              const retunedValue = value ? value : [];
              setSellerValue(retunedValue);
              setFundSellerId(parseArrayToString(retunedValue));
            }}
          />
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            placeholder={t.recommendedFundsPage.tableHeaders.peergroup}
            theme={selectTheme}
            options={peergroupFilter}
            onChange={(value) => {
              const retunedValue = value ? value.value : "";
              setPeergroupId(retunedValue);
            }}
          />
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            placeholder={t.recommendedFundsPage.tableHeaders.strategy}
            options={strategyFilter}
            theme={selectTheme}
            onChange={(value) => {
              const retunedValue = value ? value.value : "";
              setStrategyValue(retunedValue);
            }}
          />
          <Select
            styles={customStyles}
            isSearchable={false}
            isClearable
            placeholder={t.recommendedFundsPage.tableHeaders.internalExternal}
            options={internalExternalFilter}
            theme={selectTheme}
            onChange={(value) => {
              const retunedValue = value ? value.value : "";
              setInternalExternalValue(retunedValue);
            }}
          />
        </HorizontalGrid>
      </Flex>
    </Wrapper>
  );
};

export default RecommendedFundsFilters;
