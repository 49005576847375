import React from 'react';
import styled from 'styled-components';
import MainContainer from '../MainContainer/MainContainer';

interface NewsWrapeprInterface {
	children: React.ReactNode;
	marginTop?: string;
}

const Wrapper = styled.div`
	@media screen and (min-width: 768px) {
		padding: 0 8rem;
	}
	@media screen and (min-width: 1024px) {
		padding: 0 15rem;
	}
	@media screen and (min-width: 1336px) {
		padding: 0 15rem;
		width: 85%;
	}
`;

const NewsWrapepr: React.FC<NewsWrapeprInterface> = ({
	children,
	marginTop,
}) => (
	<MainContainer marginTop={marginTop}>
		<Wrapper>{children}</Wrapper>
	</MainContainer>
);

export default NewsWrapepr;
