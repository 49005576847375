import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useToasts } from "react-toast-notifications";
import MainContainer from "../../components/MainContainer/MainContainer";
import { useFormik } from "formik";
import {
  Container as BContainer,
  Row as BRow,
  Col as BCol,
} from "react-bootstrap";
import Button from "../../components/Button/Button";
import { t } from "../../translations/en";
import Text from "../../components/Text/Text";
import Title from "../../components/Title/Title";
import Input from "../../components/Input/Input";
import Checkbox from "../../components/Checkbox/Checkbox";
import { routes } from "../../routes/routes";
import { useHistory } from "react-router-dom";
import Search from "../../components/Search/Search";
import { useSetHeader } from "../../hooks/useSetHeader";
import VerticalGrid from "../../components/VerticalGrid/VerticalGrid";
import { useGetUserProfileData } from "../../hooks/useGetUserProfileData";
import { FunderService } from "../../services/FunderService";
import WithPagination from "../../components/WithPagination/WithPagination";

interface IReqeuestCompetitorAnalysis {}

const Container = styled(BContainer)`
  padding: 0;
`;

const Row = styled(BRow)`
  height: 100%;
`;

const Col = styled(BCol)``;

const InformationCol = styled(Col)`
  max-height: 10rem;
`;

const StyledTitle = styled(Title)`
  line-height: ${({ theme }) => theme.font.lineHeight.text_L};
`;

const CustomContainer = styled(Container)`
  margin-top: 4rem;
`;

const TextCol = styled(Col)``;

const CompanyCol = styled(Col)`
  padding: 0;
`;

const CompanyWrapper = styled(Col)`
  display: flex;
  align-items: flex-end;
`;

const CustomTitle = styled(Title)`
  margin-bottom: 1.4rem;
`;

const CompanyInput = styled(Input)`
  width: 100%;
  padding: 1.6rem 2rem;
  height: 4.3rem;
  &:not(:last-child) {
    margin-bottom: 0.6rem;
  }
`;

const RightCol = styled(Col)`
  margin-top: 1.4rem;
`;

const CheckboxCol = styled(Col)`
  padding: 0;
  display: flex;
  align-items: center;
`;

const CustomCheckbox = styled(Checkbox)`
  background: ${({ theme }) => theme.color.mainLight};
`;

const PeergroupCol = styled(Col)`
  padding: 0;
  margin-top: 2.4rem;
`;

const CommentCol = styled(CheckboxCol)``;

const CommentTitle = styled(CustomTitle)`
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
`;

const ButtonCol = styled(Col)`
  margin-top: 3.2rem;
`;

const CustomSearch = styled(Search)`
  background: ${({ theme }) => theme.color.mainLight};
  height: 4.3rem;
  margin-bottom: 0.6rem;
`;

interface optionsDataObject {
  id: number;
  name: string;
}

interface autocompleteDataObject {
  id: number;
  name_with_isin: string;
}

const RequestCompetitorAnalysis: React.FC<IReqeuestCompetitorAnalysis> = () => {
  useSetHeader({
    title: t.requestCompetitorAnalysis.requestCompetitorAnalysis,
    hasSubtitle: false,
  });
  const emailPattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const [optionsList, setOptionsList] = useState<Array<object>>([{}]);
  const [autocompleteList, setAutocompleteList] = useState<Array<object>>([{}]);
  const [isListLoading, setListLoading] = useState<boolean>(false);
  const [checkbox1, setCheckbox1] = useState<boolean>(false);
  const [checkbox2, setCheckbox2] = useState<boolean>(false);
  const [userData, setUserData] = useState<{ email: string; phone: string }>({
    email: "",
    phone: "",
  });

  const getCompetitorArray = (competitors) => {
    const newCompetitorsArray = [];
    Object.keys(competitors).forEach((key) =>
      newCompetitorsArray.push(competitors[key])
    );
    const filteredCompetitorsArray = newCompetitorsArray.filter(
      (competitor) => competitor !== null
    );
    return filteredCompetitorsArray;
  };

  const history = useHistory();
  const { addToast } = useToasts();
  const [isFormValid, setFormValidity] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const initialValues = {
    name: "",
    company: "",
    email: "",
    phone: "",
    comment: "",
    fund: null,
    competitors: {
      competitor1: null,
      competitor2: null,
      competitor3: null,
      competitor4: null,
      competitor5: null,
    },
    analyze_selected_competitors: null,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      if (isFormValid) {
        const { analyze_selected_competitors, competitors } = values;
        const filteredCompetitorsArray = getCompetitorArray(competitors);
        const competitorArray = analyze_selected_competitors
          ? filteredCompetitorsArray
          : [];
        const data = { ...values, competitors: competitorArray };
        setLoading(true);
        FunderService.postRequestCompetitorAnalysis(data)
          .then((response) => {
            const data = JSON.parse(response);
            addToast(t.requestCompetitorAnalysis.alertSuccess, {
              appearance: "success",
              autoDismiss: true,
            });
            history.push(`${routes.recommendedFunds}/1`);
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.status === 400) {
              addToast(error.response.data.email[0], {
                appearance: "error",
                autoDismiss: true,
              });
            } else {
              addToast(t.requestCompetitorAnalysis.alertError, {
                appearance: "error",
                autoDismiss: true,
              });
            }
          });
      }
    },
  });

  const checkFormValidity = (values) => {
    const {
      name,
      company,
      email,
      phone,
      analyze_selected_competitors,
      competitors,
      fund,
    } = values;
    const filteredCompetitorsArray = getCompetitorArray(competitors);
    if (
      name !== "" &&
      company !== "" &&
      phone !== "" &&
      emailPattern.test(email) &&
      analyze_selected_competitors !== null &&
      fund !== null &&
      fund > 0
    ) {
      if (analyze_selected_competitors) {
        if (filteredCompetitorsArray.length > 0) {
          return true;
        }
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    setFormValidity(checkFormValidity(formik.values));
  }, [formik.values]);

  useGetUserProfileData(setUserData);

  useEffect(() => {
    formik.setFieldValue("email", userData.email);
    formik.setFieldValue("phone", userData.phone);
  }, [userData]);

  // fetch select list
  useEffect(() => {
    FunderService.getFundSellers()
      .then((response) => {
        const data = JSON.parse(response);
        const formattedData = data.map((item: optionsDataObject) => {
          return { value: item.id, label: item.name };
        });
        setOptionsList(formattedData);
      })
      .catch((error) => {
        console.log(
          `%cCaught fetch error:\n ${error}`,
          "color: #f50; background: yellow"
        );
        history.push(routes.signIn);
      });
  }, []);

  // fetch autocomplete list
  const pageSize = 20;
  const filterSearchList = (value: string, page: number) => {
    if (value !== null) {
      setListLoading(true);
      FunderService.getFundList(value, page, pageSize)
        .then((response) => {
          const data = JSON.parse(response);
          const formattedData = data.results.map(
            (item: autocompleteDataObject) => {
              return { value: item.id, name: item.name_with_isin };
            }
          );
          if (page === 1) {
            setAutocompleteList(formattedData);
          } else {
            setAutocompleteList([...autocompleteList, ...formattedData]);
          }
          setListLoading(false);
        })
        .catch((error) => {
          setListLoading(false);
        });
    }
  };

  return (
    <WithPagination isLoading={isLoading}>
      <MainContainer marginBottom="4.4rem">
        <CustomContainer fluid={true}>
          <Row>
            <Col md={5}>
              <Row>
                <InformationCol md={11}>
                  <div>
                    <Text>{t.requestCompetitorAnalysis.text1}</Text>
                  </div>
                </InformationCol>
                <TextCol md={12}>
                  <Text>{t.requestCompetitorAnalysis.text2}</Text>
                  <StyledTitle>{t.requestCompetitorAnalysis.email}</StyledTitle>
                  <StyledTitle>
                    {t.requestCompetitorAnalysis.phoneNumber}
                  </StyledTitle>
                </TextCol>
                <CompanyWrapper md={12}>
                  {/* Place for company information */}
                  <CompanyCol md={8}>
                    <CustomTitle colorTheme={"medium"} size={"big"}>
                      {t.requestCompetitorAnalysis.companyInformation}
                    </CustomTitle>
                    <CompanyInput
                      placeholder={t.requestCompetitorAnalysis.namePlaceholder}
                      colorTheme="dark"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    <CompanyInput
                      placeholder={
                        t.requestCompetitorAnalysis.companyPlaceholder
                      }
                      colorTheme="dark"
                      name="company"
                      value={formik.values.company}
                      onChange={formik.handleChange}
                    />
                    <CompanyInput
                      type={"email"}
                      placeholder={t.requestCompetitorAnalysis.emailPlaceholder}
                      colorTheme="dark"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                    />
                    <CompanyInput
                      placeholder={t.requestCompetitorAnalysis.phonePlaceholder}
                      colorTheme="dark"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                    />
                  </CompanyCol>
                </CompanyWrapper>
              </Row>
            </Col>
            <Col md={{ span: 4, offset: 1 }}>
              <Col md={12}>
                <CustomTitle colorTheme={"medium"} size={"big"}>
                  {t.requestCompetitorAnalysis.fund}
                </CustomTitle>
                <CustomSearch
                  fontTheme={"black"}
                  colorTheme="dark"
                  list={autocompleteList}
                  label={t.landingPage.label2}
                  handleChange={(value, page) => {
                    filterSearchList(value, page);
                  }}
                  handleSelect={(_, id) => {
                    formik.setFieldValue("fund", id);
                  }}
                  isListLoading={isListLoading}
                />
              </Col>
              <RightCol md={12}>
                <CustomTitle colorTheme={"medium"} size={"big"}>
                  {t.requestCompetitorAnalysis.competitors}
                </CustomTitle>
                <VerticalGrid gap={"0.2rem"}>
                  <CheckboxCol md={12}>
                    <CustomCheckbox
                      paddingLeft={"5.5rem"}
                      height={"4rem"}
                      width={"4rem"}
                      name="selected peergroups"
                      label={t.requestCompetitorAnalysis.checkbox1Content}
                      colorTheme="dark"
                      value={checkbox1}
                      handleChange={(value) => {
                        setCheckbox2(false);
                        setCheckbox1(value);
                        formik.setFieldValue(
                          "analyze_selected_competitors",
                          value ? false : null
                        );
                      }}
                    />
                  </CheckboxCol>
                  <CheckboxCol md={12}>
                    <CustomCheckbox
                      paddingLeft={"5.5rem"}
                      height={"4rem"}
                      width={"4rem"}
                      name="competitors"
                      label={t.requestCompetitorAnalysis.checkbox2Content}
                      colorTheme="dark"
                      value={checkbox2}
                      handleChange={(value) => {
                        setCheckbox1(false);
                        setCheckbox2(value);
                        formik.setFieldValue(
                          "analyze_selected_competitors",
                          value ? true : null
                        );
                      }}
                    />
                  </CheckboxCol>
                </VerticalGrid>
                <PeergroupCol md={12}>
                  <CustomSearch
                    fontTheme={"black"}
                    colorTheme="dark"
                    list={autocompleteList}
                    label={t.landingPage.label2}
                    handleChange={(value, page) => {
                      filterSearchList(value, page);
                    }}
                    handleSelect={(_, id) => {
                      formik.setFieldValue("competitors.competitor1", id);
                    }}
                    isListLoading={isListLoading}
                  />
                  <CustomSearch
                    fontTheme={"black"}
                    colorTheme="dark"
                    list={autocompleteList}
                    label={t.landingPage.label2}
                    handleChange={(value, page) => {
                      filterSearchList(value, page);
                    }}
                    handleSelect={(_, id) => {
                      formik.setFieldValue("competitors.competitor2", id);
                    }}
                    isListLoading={isListLoading}
                  />
                  <CustomSearch
                    fontTheme={"black"}
                    colorTheme="dark"
                    list={autocompleteList}
                    label={t.landingPage.label2}
                    handleChange={(value, page) => {
                      filterSearchList(value, page);
                    }}
                    handleSelect={(_, id) => {
                      formik.setFieldValue("competitors.competitor3", id);
                    }}
                    isListLoading={isListLoading}
                  />
                  <CustomSearch
                    fontTheme={"black"}
                    colorTheme="dark"
                    list={autocompleteList}
                    label={t.landingPage.label2}
                    handleChange={(value, page) => {
                      filterSearchList(value, page);
                    }}
                    handleSelect={(_, id) => {
                      formik.setFieldValue("competitors.competitor4", id);
                    }}
                    isListLoading={isListLoading}
                  />
                  <CustomSearch
                    fontTheme={"black"}
                    colorTheme="dark"
                    list={autocompleteList}
                    label={t.landingPage.label2}
                    handleChange={(value, page) => {
                      filterSearchList(value, page);
                    }}
                    handleSelect={(_, id) => {
                      formik.setFieldValue("competitors.competitor5", id);
                    }}
                    isListLoading={isListLoading}
                  />
                </PeergroupCol>
                <CommentTitle colorTheme={"medium"} size={"big"}>
                  {t.requestCompetitorAnalysis.comment}
                </CommentTitle>
                <CommentCol md={12}>
                  <CompanyInput
                    placeholder={t.requestCompetitorAnalysis.commentPlaceholder}
                    colorTheme="dark"
                    name="comment"
                    value={formik.values.comment}
                    onChange={formik.handleChange}
                  />
                </CommentCol>
              </RightCol>
            </Col>
          </Row>
          <Row>
            <ButtonCol md={{ span: 4, offset: 6 }}>
              <Col>
                <Button
                  disabled={!isFormValid}
                  size={"small"}
                  colorTheme="dark"
                  content={t.submit}
                  onClick={(e) => formik.handleSubmit()}
                />
              </Col>
            </ButtonCol>
          </Row>
        </CustomContainer>
      </MainContainer>
    </WithPagination>
  );
};

export default RequestCompetitorAnalysis;
