import React from "react";
import styled from "styled-components";
import CustomButton from "../Button/Button";
import { t } from "../../translations/en";
import { useHistory, useLocation } from "react-router";
import { routes } from "../../routes/routes";

interface BackButtonInterface {
  marginRight?: string;
}

const Wrapper = styled.div<{ marginRight?: string }>`
  margin-top: 0.5rem;
  margin-right: ${({ marginRight }) => marginRight};
`;

const BackButton: React.FC<BackButtonInterface> = ({ marginRight = "0" }) => {
  const history = useHistory();
  const location = useLocation();
  return (
    <Wrapper marginRight={marginRight}>
      <CustomButton
        size="small"
        content={t.back}
        colorTheme="dark"
        onClick={() => {
          if (
            location.pathname.includes(routes.newFunds) ||
            location.pathname.includes(routes.peergroupPage)
          ) {
            history.push(`${routes.recommendedFunds}/1`);
          } else {
            history.goBack();
          }
        }}
      />
    </Wrapper>
  );
};

export default BackButton;
