import { Action } from "easy-peasy";
import { action } from "easy-peasy";

export interface VerifiedModel {
  items: {
    verified: boolean;
  };
  addVerified: Action<
    VerifiedModel,
    {
      verified: boolean;
    }
  >;
}

const verifiedModel: VerifiedModel = {
  items: {
    verified: false,
  },
  addVerified: action((state, payload) => {
    state.items.verified = payload.verified;
  }),
};

export default verifiedModel;
