import styled from "styled-components";

const Title = styled("h3")<{
  colorTheme?: "light" | "medium" | "dark";
  size?: "small" | "big";
  align?: "left" | "center" | "right";
}>`
  color: ${({ theme, colorTheme }) =>
    colorTheme === "light"
      ? theme.color.white
      : colorTheme === "medium"
      ? theme.color.textGray
      : theme.color.black};
  font-size: ${({ theme, size }) =>
    size === "big" ? theme.font.size.text_L : theme.font.size.text_M};
  line-height: ${({ theme, size }) =>
    size === "big"
      ? theme.font.lineHeight.text_L
      : theme.font.lineHeight.text_M};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-align: ${({ align }) => (align ? align : "left")};
`;

export default Title;
