import { useState, useLayoutEffect } from 'react';

export const useHandleSort = (
	handleSort: (sortBy: string, scope: string) => void,
	scope?: string | undefined
) => {
	const [filtersList, setFiltersList] = useState<NodeListOf<Element>>(
		document.querySelectorAll('[data-headername]')
	);

	useLayoutEffect(() => {
		const element = scope
			? document.querySelector(`[data-id="${scope}"]`)
			: document;
		setFiltersList(element.querySelectorAll('[data-headername]'));
	}, []);

	const getNextStatus = (currentStatus: string) => {
		const sortStatusList = ['null', 'asc', 'desc'];
		const currentStatusIndex = sortStatusList.indexOf(currentStatus);
		return sortStatusList[currentStatusIndex + 1]
			? sortStatusList[currentStatusIndex + 1]
			: sortStatusList[0];
	};

	const getSortQueryValue = (headerName: string, sortStatus: string) => {
		const queryObject = {
			asc: headerName,
			desc: `-${headerName}`,
			null: '',
		};
		return queryObject[sortStatus];
	};

	const resetFilters = (
		filtersList: NodeListOf<Element>,
		filterType: string,
		value: string
	) => {
		filtersList.forEach(item => {
			if (item.attributes[`data-${filterType}`].value !== value) {
				item.setAttribute(`data-${filterType}`, value);
			}
		});
	};

	const setDataValue = (
		target: HTMLTextAreaElement,
		data: string,
		value: string
	) => {
		target.dataset[data] = value;
	};

	return (e: React.MouseEvent<HTMLDivElement>) => {
		const target = e.target as HTMLTextAreaElement;
		const { headername: headerName, sortstatus: sortStatus } = target.dataset;
		if (headerName && sortStatus) {
			const nextStatus = getNextStatus(sortStatus);
			resetFilters(filtersList, 'sortstatus', 'null');
			setDataValue(target, 'sortstatus', nextStatus);
			handleSort(getSortQueryValue(headerName, nextStatus), scope);
		}
	};
};
