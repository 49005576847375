import React from 'react';
import styled from 'styled-components'
import LuminLogo from '../../images/lumin-logo.svg'

interface FooterLogoConfig {

}

const Logo = styled.img`
`

const FooterLogo: React.FC<FooterLogoConfig> = () => (
    <>
        <Logo src={LuminLogo}/>
    </>
)

export default FooterLogo
