import React from 'react'
import styled from 'styled-components'
import OptionsIcon from '../../images/more-options.svg'

const MoreOptionsIcon = styled.img`
    padding: 0 1.8rem;
    
    &:hover {
        cursor: pointer;
    }
`

const MoreOptionsComponent: React.FC = () => (
    <MoreOptionsIcon src={OptionsIcon}/>
)

export default MoreOptionsComponent