import React from 'react';
import styled, { css } from 'styled-components';

type colorThemeType = 'dark' | 'light';

interface InputConfig {
	type?: string;
	placeholder?: string;
	className?: string;
	onChange?: (e: React.FormEvent) => void;
	onFocus?: (e: React.FormEvent) => void;
	onClick?: (e: React.FormEvent) => void;
	onBlur?: (e: React.FormEvent) => void;
	reference?: React.Ref<HTMLInputElement>;
	value?: string;
	id?: string;
	name?: string;
	colorTheme?: colorThemeType;
	autoComplete?: string;
	autofocus?: boolean;
	maxLength?: number;
	minLength?: number;
}

const StyledInput = styled.input<{ colorTheme?: colorThemeType }>`
	border: 0.1rem solid ${({ theme }) => theme.color.white};
	padding: 1.3rem 3rem;
	font-size: ${({ theme }) => theme.font.size.text_XS};
	background: ${({ theme }) => theme.color.white};
	color: ${({ theme }) => theme.color.main};

	::placeholder {
		color: ${({ theme }) => theme.color.main};
	}

	${({ colorTheme }) =>
		colorTheme === 'dark' &&
		css`
			background: ${({ theme }) => theme.color.mainLight};
			color: ${({ theme }) => theme.color.black};
			::placeholder {
				color: ${({ theme }) => theme.color.black};
				font-size: 1.2rem;
			}
		`}
`;

const Input: React.FC<InputConfig> = ({
	type = 'text',
	placeholder,
	className,
	onChange,
	reference,
	value,
	onBlur,
	colorTheme,
	id,
	name,
	autoComplete,
	autofocus,
	maxLength,
	minLength,
	onFocus,
	onClick,
}) => (
	<StyledInput
		autoFocus={autofocus}
		autoComplete={autoComplete}
		className={className}
		type={type}
		placeholder={placeholder}
		onChange={onChange}
		onFocus={onFocus}
		onBlur={onBlur}
		onClick={onClick}
		ref={reference}
		value={value}
		colorTheme={colorTheme}
		id={id}
		maxLength={maxLength}
		minLength={minLength}
		name={name}
	/>
);

export default Input;
