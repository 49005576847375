import React from 'react';
import styled, { css } from 'styled-components';
import HorizontalGrid from '../HorizontalGrid/HorizontalGrid';

interface ChartInterface {
	data: number;
	biggestValue: number;
	color: string;
	separatedData: string;
}

type ChartIndexType = {
	width: number;
	color: string;
	isNegative: boolean;
	data: string;
};

const ChartIndex = styled.div<ChartIndexType>`
	height: 0.8rem;
	width: ${({ width }) => `calc(${width} * 95%)`};
	background: ${({ theme, color }) => theme.color[color]};
	position: relative;
	:after {
		content: '${({ data }) => data}';
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: calc(100% + 0.5rem);
	}
	${({ isNegative }) =>
		isNegative &&
		css`
			position: absolute;
			right: 0;
			:after {
				left: inherit;
				right: calc(100% + 0.5rem);
			}
		`}
`;

const ChartValue = styled.div<{ isNegative?: boolean }>`
	text-align: ${({ isNegative }) => (isNegative ? 'left' : 'right')};
	font-size: 1.6rem;
	line-height: 0.8rem;
	z-index: 2;
	color: transparent;
`;

const StyledHorizontalGrid = styled(HorizontalGrid)`
	position: relative;
	width: 100%;
`;

const Wrapper = styled.div`
	position: relative;
	width: 100%;
`;

const Chart: React.FC<ChartInterface> = ({
	data,
	biggestValue,
	color,
	separatedData,
}) => {
	const dataWidth = data < 0 ? Math.abs(data) : data;
	const isNegative = data < 0 ? true : false;
	if (biggestValue === 0) {
		return <div></div>;
	}
	return (
		<StyledHorizontalGrid columnPattern='repeat(2, 1fr)'>
			{isNegative ? (
				<StyledHorizontalGrid columnPattern='max-content 1fr'>
					<ChartValue isNegative={isNegative}>{data}</ChartValue>
					<Wrapper>
						<ChartIndex
							width={dataWidth / biggestValue}
							color={color}
							isNegative={isNegative}
							data={separatedData}
						/>
					</Wrapper>
				</StyledHorizontalGrid>
			) : (
				<div></div>
			)}
			{!isNegative ? (
				<StyledHorizontalGrid columnPattern='1fr max-content'>
					<Wrapper>
						<ChartIndex
							width={dataWidth / biggestValue}
							color={color}
							isNegative={isNegative}
							data={separatedData}
						/>
					</Wrapper>
					<ChartValue>{data}</ChartValue>
				</StyledHorizontalGrid>
			) : (
				<div></div>
			)}
		</StyledHorizontalGrid>
	);
};

export default Chart;
