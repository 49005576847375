import axios from 'axios';
import { useGetEnv } from '../hooks/useGetEnv';
import { useErrorLogout } from '../hooks/useErrorLogout';
import { t } from '../translations/en';

axios.defaults.headers.common['Content-Type'] = 'application/json';

interface IResponse {
	response: IGetFunderList;
}

export interface IGetGapAnalysis {
	type: string;
	attributes: {
		title: string;
		coverage: string;
	};
}

interface IGetFunderList {
	id: string;
	name: number;
}

interface IRecommendations {
	count: number;
	next: null | number;
	previous: null | number;
	results: Array<ISingleRecommendation>;
}

export interface ISingleRecommendation {
	id: number;
	fund_name: string;
	peergroup: string;
	asset_manager: string;
	strategy: string;
	short_term_performance: string;
	long_term_performance: string;
	rating: string;
	potential: string;
	fund_seller_id: number;
	url?: string;
}

export class FunderService {
	static maxRefreshTokenRetries = 3;
	static refreshTokenPromise = null;

	static getConfigForProtectedAPI() {
		const token = localStorage.getItem('token');
		return { headers: { Authorization: 'Bearer ' + token } };
	}

	static logout() {
		sessionStorage.setItem('errorMessage', t.loginModalError);
		const logout = useErrorLogout();
		logout();
	}

	static async refreshToken() {
		const data = { refresh: localStorage.getItem('refresh') };
		await axios.post(`${useGetEnv('API_URL')}api/token/refresh/`, data)
			.then(({ data }) => {
				localStorage.setItem('token', data.access);
			})
			.catch(() => {
				this.logout();
			});
	}

	static async get<APIResultType>(endpoint) {
		try {
			const response = await axios.get<APIResultType>(endpoint);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			throw error;
		}
	}

	static async protectedGet<APIResultType>(endpoint, retry = 1) {
		if (this.refreshTokenPromise !== null) {
			await this.refreshTokenPromise;
		}
		const config = this.getConfigForProtectedAPI();
		try {
			const response = await axios.get<APIResultType>(endpoint, config);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			if (error.response.status === 401) {
				if (retry <= this.maxRefreshTokenRetries) {
					try {
						if (this.refreshTokenPromise !== null) {
							await this.refreshTokenPromise;
						} else {
							this.refreshTokenPromise = this.refreshToken();
							await this.refreshTokenPromise;
							this.refreshTokenPromise = null;
						}
						const nextRetry = retry + 1;
						return await this.protectedGet<APIResultType>(endpoint, nextRetry);
					} catch (afterRefreshError) {
						throw afterRefreshError;
					}
				} else {
					this.logout();
				}
			} else {
				throw error;
			}
		}
	}

	static async post<APIResultType>(endpoint, postData) {
		try {
			const response = await axios.post<APIResultType>(endpoint, postData);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			throw error;
		}
	}

	static async protectedPost<APIResultType>(endpoint, postData, retry = 1) {
		if (this.refreshTokenPromise !== null) {
			await this.refreshTokenPromise;
		}
		const config = this.getConfigForProtectedAPI();
		try {
			const response = await axios.post<APIResultType>(
				endpoint,
				postData,
				config
			);
			const { data } = response;
			return JSON.stringify(data);
		} catch (error) {
			if (error.response.status === 401) {
				if (retry <= this.maxRefreshTokenRetries) {
					try {
						if (this.refreshTokenPromise !== null) {
							await this.refreshTokenPromise;
						} else {
							this.refreshTokenPromise = this.refreshToken();
							await this.refreshTokenPromise;
							this.refreshTokenPromise = null;
						}
						const nextRetry = retry + 1;
						return await this.protectedPost<APIResultType>(
							endpoint,
							postData,
							nextRetry
						);
					} catch (afterRefreshError) {
						throw afterRefreshError;
					}
				} else {
					this.logout();
				}
			} else {
				throw error;
			}
		}
	}

	static async getNewsList(pageSize: number = 5, page: number = 1) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/news/?page_size=${pageSize}&page=${page}`;
		return await this.get<IRecommendations>(endpoint);
	}

	static async getNewsItem(id: number | string) {
		const endpoint = `${useGetEnv('API_URL')}api/news/${id}`;
		return await this.get<IRecommendations>(endpoint);
	}

	static async getGapAnalysisById(id: string) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/gap-analysis/?seller=${id}`;
		return await this.protectedGet<IGetGapAnalysis>(endpoint);
	}

	static async getPeergroupRecommendations(
		id: string,
		peergroup: string,
		page?: number
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/current/?seller=${id}&fund__peergroup=${peergroup}&page=${page}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getPeergroupAlternatives(
		id: string,
		peergroup: string,
		page?: number,
		userId?: number | string
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/current/?exclude_seller=${id}&fund__peergroup=${peergroup}&page=${page}&fund__asset_manager=${userId}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFundDataById(id: string) {
		const endpoint = `${useGetEnv('API_URL')}api/funds/${id}/summary`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getAssetManagerScorecard() {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/asset-manager-scorecard/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getAssetManagerView(
		manager: string | number,
		peergroup: string | number,
		orderBy: string,
		assetClass: string,
		page: string,
		pageSize: number
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/funds/?asset_manager=${manager}&peergroup=${peergroup}&peergroup__asset_class=${assetClass}&ordering=${orderBy}&page_size=${pageSize}&page=${page}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getUserAccountInfo() {
		const endpoint = `${useGetEnv('API_URL')}api/users/profile/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getAssetManagerList(
		name: string,
		page?: number,
		pageSize?: number
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/asset-managers/?name=${encodeURIComponent(
			name
		)}&page=${page}&page_size=${pageSize}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getStrategiesFilter() {
		const endpoint = `${useGetEnv('API_URL')}api/funds/strategies/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getPeergroupsFilter() {
		const endpoint = `${useGetEnv('API_URL')}api/peergroups/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFilteredData(
		page: string | number,
		fundSellerId?: string | number,
		peergroupId?: string | number,
		strategy?: string | number,
		ordering?: string,
		externalInternal?: string
	) {
		fundSellerId === undefined ? (fundSellerId = '') : null;
		externalInternal === undefined ? (externalInternal = '') : null;
		let endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/current/?fund__peergroup=${peergroupId}&seller=${fundSellerId}&fund__strategy=${strategy}&page=${page}&external_internal=${externalInternal}&ordering=${ordering}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFilteredPeergroupData(
		page: string | number,
		fundSellerId?: string | number,
		strategy?: string | number,
		peergroupId?: string | number,
		orderBy?: string,
		pageSize?: number
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/for-peergroup/?fund__peergroup=${peergroupId}&seller=${fundSellerId}&fund__strategy=${strategy}&page=${page}&ordering=${orderBy}&page_size=${pageSize}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getGapPeergroupCurrent(
		page: string | number,
		fundSellerId?: string | number,
		strategy?: string | number,
		peergroupId?: string | number,
		orderBy?: string,
		pageSize?: number
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/current-for-gap-peergroup/?fund__peergroup=${peergroupId}&seller=${fundSellerId}&fund__strategy=${strategy}&page=${page}&ordering=${orderBy}&page_size=${pageSize}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getSellerList(name: string, page?: number, pageSize?: number) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/sellers/?name=${encodeURIComponent(
			name
		)}&page=${page}&page_size=${pageSize}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getAssetClasses() {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/peergroups/grouped-by-asset-class/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getPeergroupsById(id: string) {
		const endpoint = `${useGetEnv('API_URL')}api/peergroups/${id}/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getRecommendationsForPeergroup(seller: string, page: number) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/recommendations/for-peergroup/?fund__peergroup=${seller}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFundList(name: string, page?: number, pageSize?: number) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/funds/autocomplete/?name=${encodeURIComponent(
			name
		)}&page=${page}&page_size=${pageSize}`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFundsWithTheMostRecommendations() {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/funds/funds-with-the-most-recommendations/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getLastImportDate() {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/data-imports/last-successful-import-date/`;
		return await this.protectedGet<IRecommendations>(endpoint);
	}

	static async getFundSellers() {
		const endpoint = `${useGetEnv('API_URL')}api/fund-sellers/`;
		return await this.protectedGet(endpoint);
	}

	static async getNewFundsThisMonth(page, ordering) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/new-funds-this-month/?page=${page}&ordering=${ordering}`;
		return await this.protectedGet(endpoint);
	}

	static async getFundSellerPotential() {
		const endpoint = `${useGetEnv('API_URL')}api/fund-seller-potential/`;
		return await this.protectedGet(endpoint);
	}

	static async getGapPeergroupAlternatives(
		sellersExcluded,
		peergroupId,
		page,
		strategy,
		gapOrderBy,
		pageSize
	) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/funds/alternatives-for-gap-peergroup/?exclude_seller=${sellersExcluded}&strategy=${strategy}&ordering=${gapOrderBy}&peergroup=${peergroupId}&page=${page}&page_size=${pageSize}`;
		return await this.protectedGet(endpoint);
	}

	static async postRecommendation(data) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/add-recommendations-requests/`;
		return await this.protectedPost<IRecommendations>(endpoint, data);
	}

	static async postRequestCompetitorAnalysis(data) {
		const endpoint = `${useGetEnv(
			'API_URL'
		)}api/competitor-analysis-request/`;
		return await this.protectedPost<IRecommendations>(endpoint, data);
	}

	static async postPasswordRemind(email) {
		const endpoint = `${useGetEnv('API_URL')}api/users/forgot-password/`;
		return await this.post<IRecommendations>(endpoint, { email });
	}

	static async postPasswordChange(data) {
		const endpoint = `${useGetEnv('API_URL')}api/users/reset-password/`;
		return await this.post<IRecommendations>(endpoint, data);
	}

	static async postContactForm(data) {
		const endpoint = `${useGetEnv('API_URL')}api/contact/`;
		return await this.post<IRecommendations>(endpoint, data);
	}

	static async login(email, password) {
		const endpoint = `${useGetEnv('API_URL')}api/token/`;
		return await this.post(endpoint, { email, password });
	}
}
